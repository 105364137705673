import React from "react";
import { GetVoucherListResponse } from "modules/counter/api/type";
import { PrintBankTransferHistoryPage } from "../pages/print-bank-transfer-history.page";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";
import { Button, useDisclosure } from "@chakra-ui/react";

interface PrintBankTransferHistoryComponentProps {
  bankTransferHistory: GetVoucherListResponse;
  month: string;
  year: string;
}

export const PrintBankTransferHistoryComponent: React.FC<
  PrintBankTransferHistoryComponentProps
> = (props: PrintBankTransferHistoryComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <PrintBankTransferHistoryPage
      bankTransferHistory={props.bankTransferHistory}
      month={props.month}
      year={props.year}
    />
  );

  const bankTransferHistoryReport = `Bank-Transfer-History-Report-${props.month}-${props.year}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={bankTransferHistoryReport}
      />
    </>
  );
};
