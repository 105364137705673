import React, { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Stack,
  Center,
  useToast,
  Box
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useNavigate } from "react-router-dom";
import { useGetAllLocationsQuery } from "../api";
import { Location as LocationType } from "app/api/type";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";
import { api } from "app/api";
import { useTypedDispatch } from "hooks/use-typed-dispatch.hook";
import { DecorationImageComponent } from "./decoration-image.component";

interface SelectLocationComponentProps {}

export const SelectLocationComponent: React.FC<SelectLocationComponentProps> = (
  props: SelectLocationComponentProps
) => {
  const getAllLocationsResult = useGetAllLocationsQuery({});

  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useTypedDispatch();

  const [selectedLocation, setSelectedLocation] = useState<{
    label: string;
    value: string;
    location: LocationType;
  }>();

  const [locationOptions, setLocationOptions] = useState<
    {
      label: string;
      value: string;
      location: LocationType;
    }[]
  >([]);

  useEffect(() => {
    if (getAllLocationsResult.data) {
      setLocationOptions(
        getAllLocationsResult.data.locations.map((location) => {
          return {
            label: location.name,
            value: location.id,
            location: location
          };
        })
      );
    }
  }, [getAllLocationsResult]);

  useEffect(() => {
    if (locationOptions.length === 1) {
      window.localStorage.setItem(
        "locationId",
        locationOptions[0].value.toString()
      );

      window.localStorage.setItem(
        "locationName",
        locationOptions[0].location.name
      );

      window.localStorage.setItem(
        "locationCategory",
        locationOptions[0].location.locationCategoryName
      );

      navigate(
        `/${getDashboardName(
          locationOptions[0].location.locationCategoryName
        )}/dashboard`
      );
    }
  }, [locationOptions, navigate]);

  const handleSelectLocation = () => {
    if (selectedLocation) {
      window.localStorage.setItem("locationId", selectedLocation.value);
      window.localStorage.setItem("locationName", selectedLocation.label);
      window.localStorage.setItem(
        "locationCategory",
        selectedLocation.location.locationCategoryName
      );

      navigate(
        `/${getDashboardName(
          selectedLocation.location.locationCategoryName
        )}/dashboard`
      );
    }
  };

  const logOut = () => {
    window.localStorage.clear();
    toast({
      title: "Success",
      description: "Logged out successfully",
      status: "info",
      position: "top",
      duration: 2000,
      isClosable: true
    });
    dispatch(api.util.resetApiState());
    navigate("/login");
  };

  return (
    <Box
      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
      p={5}
      borderRadius="lg"
      width="50%"
      height="auto"
    >
      <Center>
        <Stack width="90%" gap="2">
          <DecorationImageComponent />
          <Heading fontSize="lg">Select Location</Heading>
          <Select
            value={selectedLocation}
            onChange={(event) => {
              if (event) {
                setSelectedLocation({
                  label: event.label,
                  value: event.value,
                  location: event.location
                });
              }
            }}
            selectedOptionStyle="check"
            options={locationOptions}
          />
          <Button colorScheme="blue" onClick={handleSelectLocation}>
            Set Current Location
          </Button>
          <Button colorScheme="blue" onClick={logOut}>
            Log Out
          </Button>
        </Stack>
      </Center>
    </Box>
  );
};
