import {
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect } from "react";
import { useLazyGetInvestigationQuantityInsightQuery } from "../api/index";

interface InvestigationQuantityInsightComponentProps {}

export const InvestigationQuantityInsightComponent: React.FC<
  InvestigationQuantityInsightComponentProps
> = (props: InvestigationQuantityInsightComponentProps) => {
  const [
    getInvestigationQuantityInsight,
    getInvestigationQuantityInsightResult
  ] = useLazyGetInvestigationQuantityInsightQuery();

  useEffect(() => {
    getInvestigationQuantityInsight({
      from: dayjs().subtract(1, "month").toDate(),
      to: dayjs().toDate(),
      locationId: "all"
    });
  }, []);

  return (
    <Stack spacing={4}>
      <PageCardComponent>
        <TableContainer>
          <Table size="sm" layout="fixed" variant={"striped"}>
            <TableCaption
              placement="top"
              pb="6"
              fontSize={"lg"}
              textAlign="center"
              color="black"
            >
              Voucher Quantity (Ward)
            </TableCaption>

            <Thead>
              <Tr>
                <Th bg="gray.700" color="white" width="100px">
                  Sl. No.
                </Th>
                {getInvestigationQuantityInsightResult.data &&
                getInvestigationQuantityInsightResult.data?.report
                  .wardCategories[0] ? (
                  Object.entries(
                    getInvestigationQuantityInsightResult.data.report
                      .wardCategories[0]
                  ).map(([key, value]) => {
                    return (
                      <Th
                        isNumeric={key === "quantity" || key === "growth"}
                        bg="gray.700"
                        color="white"
                      >
                        {key}
                      </Th>
                    );
                  })
                ) : (
                  <></>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {getInvestigationQuantityInsightResult.data ? (
                Object.entries(
                  getInvestigationQuantityInsightResult.data.report
                    .wardCategories
                ).map(([key, value]) => {
                  return (
                    <Tr key={key}>
                      <Td>{Number(key) + 1}</Td>
                      {Object.entries(value).map(([key1, value1]) => {
                        return (
                          <Td
                            fontWeight={
                              key1 === "growth" &&
                              value &&
                              (value1 > 50 || value1 < -50)
                                ? "bold"
                                : "normal"
                            }
                            color={
                              key1 === "growth"
                                ? value1 < 0
                                  ? "red"
                                  : value1 > 0
                                  ? "green"
                                  : "gray.500"
                                : value1
                                ? "black"
                                : "gray.500"
                            }
                            isNumeric={key1 === "quantity" || key1 === "growth"}
                          >
                            {key1 === "growth"
                              ? value1 != null && typeof value1 === "number"
                                ? Math.round(value1) + "%"
                                : "-"
                              : value1}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </PageCardComponent>

      <PageCardComponent>
        <TableContainer>
          <Table size="sm" layout="fixed" variant={"striped"}>
            <TableCaption
              placement="top"
              pb="6"
              fontSize={"lg"}
              textAlign="center"
              color="black"
            >
              Item Quantity (Payment Voucher)
            </TableCaption>

            <Thead>
              <Tr>
                <Th bg="gray.700" color="white" width="100px">
                  Sl. No.
                </Th>
                {getInvestigationQuantityInsightResult.data &&
                getInvestigationQuantityInsightResult.data.report
                  .investigations[0] ? (
                  Object.entries(
                    getInvestigationQuantityInsightResult.data.report
                      .investigations[0]
                  ).map(([key, value]) => {
                    return (
                      <Th
                        isNumeric={key === "quantity" || key === "growth"}
                        bg="gray.700"
                        color="white"
                      >
                        {key}
                      </Th>
                    );
                  })
                ) : (
                  <></>
                )}
              </Tr>
            </Thead>

            <Tbody>
              {getInvestigationQuantityInsightResult.data ? (
                Object.entries(
                  getInvestigationQuantityInsightResult.data.report
                    .investigations
                ).map(([key, value]) => {
                  return (
                    <Tr key={key}>
                      <Td>{Number(key) + 1}</Td>
                      {Object.entries(value).map(([key1, value1]) => {
                        return (
                          <Td
                            fontWeight={
                              key1 === "growth" &&
                              value &&
                              (value1 > 50 || value1 < -50)
                                ? "bold"
                                : "normal"
                            }
                            color={
                              key1 === "growth"
                                ? value1 < 0
                                  ? "red"
                                  : value1 > 0
                                  ? "green"
                                  : "gray.500"
                                : value1
                                ? "black"
                                : "gray.500"
                            }
                            isNumeric={key1 === "quantity" || key1 === "growth"}
                          >
                            {key1 === "growth"
                              ? value1 != null && typeof value1 === "number"
                                ? Math.round(value1) + "%"
                                : "-"
                              : value1}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </PageCardComponent>
    </Stack>
  );
};
