import { FormLabel } from "@chakra-ui/react";
import { Select, SingleValue } from "chakra-react-select";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect, useState } from "react";
import { useGetInvestigationNamesQuery } from "../api";
import { InvestigationWithOnlyName } from "../api/type";
import { EditInvestigationsComponent } from "../components/edit-investigations.component";

interface InvestigationOption {
  label: string;
  value: InvestigationWithOnlyName;
}

export const EditInvestigationsPage: React.FC = () => {
  const investigationNamesResult = useGetInvestigationNamesQuery({});

  const [investigationOptions, setInvestigationOptions] =
    useState<InvestigationOption[]>();

  const [selectedInvestigationId, setSeletedInvestigationId] =
    useState<string>();

  useEffect(() => {
    if (investigationNamesResult.isSuccess) {
      setInvestigationOptions(
        investigationNamesResult.data.investigations.map((investigation) => {
          return {
            label: investigation.name,
            value: investigation
          };
        })
      );
    }
  }, [investigationNamesResult]);

  const handleSelect = (event: SingleValue<InvestigationOption>) => {
    if (event) {
      setSeletedInvestigationId(event.value.id);
    }
  };

  return (
    <>
      <PageCardComponent>
        <FormLabel margin={0}>Select Investigation </FormLabel>
        <Select options={investigationOptions} onChange={handleSelect} />
      </PageCardComponent>
      {selectedInvestigationId && (
        <EditInvestigationsComponent
          investigationId={selectedInvestigationId}
        />
      )}
    </>
  );
};
