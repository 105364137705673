import { Stack } from "@chakra-ui/react";
import React from "react";
import { MonthlyVoucherListComponent } from "../components/monthly-voucher-list.component";

interface TransferVoucherHistoryPageProps {}

export const TransferVoucherHistoryPage: React.FC<
  TransferVoucherHistoryPageProps
> = (props: TransferVoucherHistoryPageProps) => {
  return (
    <Stack>
      <MonthlyVoucherListComponent type="Bank Transfer" />
    </Stack>
  );
};
