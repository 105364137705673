import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import {
  Expense,
  Investigation,
  Patient,
  Person,
  User,
  Voucher,
  VoucherRecord
} from "app/api/type";
import { env } from "app/config";
import dayjs from "dayjs";
import JsBarcode from "jsbarcode";
import React, { useEffect, useState } from "react";
import { toWords } from "utils/currency-to-words";

interface PrintBankExpenseVoucherComponentProps {
  voucherDetails: {
    patient: Patient | null;
    person: Person | null;
    voucher: Voucher;
    entries: {
      voucherRecord: VoucherRecord;
      investigation: Investigation | null;
      expense: Expense | null;
    }[];
  };
  printerDetails: {
    user: Omit<User, "password">;
    person: Person;
  };
}

Font.register({
  family: "Ubuntu",
  src: "/fonts/ubuntu.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24,
    fontFamily: "Ubuntu"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    textAlign: "center"
  },
  barcode: {
    width: "128px",
    objectFit: "contain",
    alignSelf: "center"
  },

  title: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 4,
    textAlign: "center"
  },
  subTitle: {
    fontSize: 12,
    marginTop: 50,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center"
  },
  header: {
    fontSize: 11,
    top: 20,
    left: 50,
    right: 50,
    display: "flex",
    flexDirection: "row"
  },
  headerLeft: {
    width: "50%",
    left: 0,
    paddingTop: 5,
    paddingBottom: 5
  },
  headerRight: {
    right: 0,
    width: "50%",
    paddingTop: 5,
    paddingBottom: 5
  },
  rightSide: {
    marginLeft: "auto"
  },
  details: {
    fontSize: 9
  },
  table: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#dfdfdf",
    border: "0.5px solid #dfdfdf",
    fontWeight: "bold"
  },
  tableFooter: {
    borderBottom: "1px solid #dfdfdf",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    fontSize: 9
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    border: "0.5px solid #dfdfdf"
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    border: "0.5px solid #efefef"
  },
  serial: {
    width: "5%",
    textAlign: "right",
    padding: "1px"
  },

  type: {
    width: "55%",
    textAlign: "center",
    padding: "1px"
  },
  totalCost: {
    width: "40%",
    textAlign: "right",
    padding: "1px"
  },
  totalCostFooter: {
    width: "40%",
    textAlign: "right",
    padding: "1px",
    fontWeight: "bold"
  }
});

export const PrintBankExpenseVoucherComponent: React.FC<
  PrintBankExpenseVoucherComponentProps
> = (props: PrintBankExpenseVoucherComponentProps) => {
  const [barcode, setBarcode] = useState<string>();

  useEffect(() => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, props.voucherDetails.voucher.id);
    setBarcode(canvas.toDataURL());
  }, []);

  return (
    <Document>
      <Page size="A5" style={styles.body}>
        <View
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <Image style={styles.logo} src={env.governmentLogo} />
              <View style={{ paddingHorizontal: "25px" }}>
                <View>
                  <Text style={{ fontSize: 11, alignSelf: "center" }}>
                    {env.voucherTitle}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 9, alignSelf: "center" }}>
                    {env.hospitalName}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.hospitalLogo} />
            </View>
            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>
                {props.voucherDetails.voucher.voucherTypeName}
              </Text>
              <Image style={styles.barcode} src={barcode} />
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              >
                <View>
                  <Text style={styles.details}>
                    Transaction ID: {props.voucherDetails.voucher.id}
                  </Text>

                  <Text style={styles.details}>
                    Date:{" "}
                    {dayjs(props.voucherDetails.voucher.createdAt).format(
                      "DD MMM YYYY"
                    )}
                  </Text>

                  <Text style={styles.details}>
                    Time:{" "}
                    {dayjs(props.voucherDetails.voucher.createdAt).format(
                      "hh:mm:ss A"
                    )}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.serial}>Sl.</Text>
                <Text style={styles.type}>Type</Text>
                <Text style={styles.totalCost}>Total</Text>
              </View>
              {props.voucherDetails.entries.map((voucherEntry, index) => (
                <View
                  key={voucherEntry.voucherRecord.id}
                  style={styles.itemRow}
                  wrap={false}
                >
                  <Text style={styles.serial}>{index + 1}</Text>
                  <Text style={styles.type}>
                    {voucherEntry.expense?.name || ""}
                  </Text>
                  <Text style={styles.totalCost}>
                    BDT {voucherEntry.voucherRecord.totalPrice.toFixed(2)}
                  </Text>
                </View>
              ))}
            </View>
            <View style={[styles.tableFooter, { borderBottom: "none" }]} fixed>
              <Text style={styles.serial}></Text>
              <Text style={styles.type}>Grand Total</Text>
              <Text style={styles.totalCost}>
                = BDT{" "}
                {props.voucherDetails.entries
                  .reduce(
                    (totalPrice, voucherEntry) =>
                      totalPrice + voucherEntry.voucherRecord.totalPrice,
                    0
                  )
                  .toFixed(2)}
              </Text>
            </View>
            <View style={[styles.tableFooter]} fixed>
              <Text style={styles.serial}></Text>
              <Text style={styles.type}>Paid</Text>
              <Text style={styles.totalCostFooter}>
                = BDT {props.voucherDetails.voucher.paidAmount.toFixed(2)}
              </Text>
            </View>
            <Text style={{ fontSize: 10, paddingVertical: 8 }}>
              In words,{" "}
              {toWords.convert(props.voucherDetails.voucher.paidAmount)}.
            </Text>
          </View>
          <View>
            {props.voucherDetails.voucher.note ? (
              <View style={{ paddingBottom: 8 }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  Notes:
                </Text>
                <Text style={{ fontSize: 10 }}>
                  {props.voucherDetails.voucher.note}
                </Text>
              </View>
            ) : (
              <></>
            )}

            <View
              style={{
                borderTop: "1px solid #000"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Text style={{ fontSize: 8, color: "#666666" }}>
                  Powered By: BinduLogic Limited
                </Text>
                <Text style={{ fontSize: 8, color: "#666666" }}>
                  Printed By: {props.printerDetails.person.name} (
                  {dayjs().format("DD MMM YYYY, hh:mm A")})
                </Text>
              </View>
            </View>
          </View>{" "}
        </View>
      </Page>
    </Document>
  );
};
