import React from "react";
import { CreateDoctorComponent } from "../components/create-doctor.component";

export const CreateDoctorPage: React.FC = () => {
  return (
    <>
      <CreateDoctorComponent />
    </>
  );
};
