import { Center, Stack } from "@chakra-ui/react";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { GetIncomeExpenseReportResponse } from "modules/counter/api/type";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

interface IncomeExpenseBarChartComponentProps {
  getIncomeExpenseReport: GetIncomeExpenseReportResponse;
}

export const IncomeExpenseBarChartComponent: React.FC<
  IncomeExpenseBarChartComponentProps
> = (props: IncomeExpenseBarChartComponentProps) => {
  const { getIncomeExpenseReport } = props;

  const incomeData = getIncomeExpenseReport.report.incomes.map((item) => ({
    date: item.date,
    income: item.income
  }));

  const expenseData = getIncomeExpenseReport.report.expenses.map((item) => ({
    date: item.date,
    expense: item.expense
  }));

  return (
    <Stack width="100%">
      <PageCardComponent>
        <Center width="100%" pt={8}>
          <BarChart
            width={1000}
            height={400}
            data={incomeData}
            margin={{ bottom: 100 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tick={{ fontSize: 12 }}
              interval={2}
              angle={-45}
              textAnchor="end"
            />
            <YAxis tick={{ fontSize: 12 }} />
            <Tooltip />
            <Legend layout="horizontal" verticalAlign="top" align="center" />
            <Bar dataKey="income" name="Income" fill="green" />
          </BarChart>
        </Center>
      </PageCardComponent>

      <PageCardComponent>
        <Center width="100%" pt={8}>
          <BarChart
            width={1000}
            height={400}
            data={expenseData}
            margin={{ bottom: 100 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tick={{ fontSize: 12 }}
              interval={2}
              angle={-45}
              textAnchor="end"
            />
            <YAxis tick={{ fontSize: 12 }} />
            <Tooltip />
            <Legend layout="horizontal" verticalAlign="top" align="center" />
            <Bar dataKey="expense" name="Expense" fill="red" />
          </BarChart>
        </Center>
      </PageCardComponent>
    </Stack>
  );
};
