import { IncomeExpenseReportComponent } from "modules/department/components/income-expense-report.component";
import React from "react";

interface IncomeExpenseReportPageProps {}

export const IncomeExpenseReportPage: React.FC<IncomeExpenseReportPageProps> = (
  props: IncomeExpenseReportPageProps
) => {
  return (
    <>
      <IncomeExpenseReportComponent />
    </>
  );
};
