import { Box, Link, Spacer, Stack, Text } from "@chakra-ui/react";
import { env } from "app/config";
import React from "react";

interface FooterComponentProps {}

export const FooterComponent: React.FC<FooterComponentProps> = (
  props: FooterComponentProps
) => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <Box>
      <Stack direction={{ base: "column", md: "row", xl: "row" }} mt="2">
        <Box>
          <Text fontSize="sm">
            &copy; {year} {env.hospitalName}
          </Text>
        </Box>
        <Spacer />
        <Box>
          <Text fontSize="sm">
            Powered by{" "}
            <Link
              color="blue.400"
              href="https://bindulogic.com/"
              isExternal
              fontSize={{ base: "sm" }}
            >
              <Text as="span" fontSize="sm">
                Bindulogic Limited
              </Text>
            </Link>
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};
