import { Box, Center, Image } from "@chakra-ui/react";
import { env } from "app/config";

export const DecorationImageComponent = () => {
  return (
    <Center>
      <Box boxSize="100px">
        <Image
          src={env.hospitalLogo}
          alt="BinduHealth Lab Billing"
          objectFit="cover"
        />
      </Box>
    </Center>
  );
};
