import {
  Center,
  FormControl,
  FormLabel,
  Spinner,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect, useState } from "react";
import { monthList, yearList } from "utils/date";
import { useLazyGetLedgerBookQuery } from "../api";
import { PrintMonthlyLedgerBookComponent } from "../print/components/print-monthly-ledger-book.component";

interface Props {}

export const MonthlyLedgerBookComponent: React.FC<Props> = (props: Props) => {
  const [getLedgerBook, getLedgerBookResult] = useLazyGetLedgerBookQuery({});

  const [selectedYear, setSelectedYear] = useState<{
    label: string;
    value: number;
  }>({
    label: dayjs().year().toString(),
    value: dayjs().year()
  });

  const [selectedMonth, setSelectedMonth] = useState<{
    label: string;
    value: number;
  }>(monthList[dayjs().month()]);

  useEffect(() => {
    getLedgerBook({
      month: selectedMonth.value,
      year: selectedYear.value,
      locationId: ""
    });
  }, [selectedMonth, selectedYear]);

  return (
    <Stack spacing={6}>
      <PageCardComponent>
        <Stack direction="row">
          <FormControl id="year">
            <FormLabel>Year</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={yearList}
              value={selectedYear}
              onChange={(event) => {
                if (event) {
                  setSelectedYear({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>

          <FormControl id="month">
            <FormLabel>Month</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={monthList}
              value={selectedMonth}
              onChange={(event) => {
                if (event) {
                  setSelectedMonth({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>
      </PageCardComponent>

      <Stack width="100%">
        {getLedgerBookResult.data && (
          <Stack alignSelf={"flex-end"}>
            <PrintMonthlyLedgerBookComponent
              monthlyLedgerReport={getLedgerBookResult.data}
              month={selectedMonth.label}
              year={selectedYear.label}
            />
          </Stack>
        )}
      </Stack>

      {getLedgerBookResult.isSuccess ? (
        <Stack>
          <PageCardComponent>
            <TableContainer>
              <Table variant="striped" size="sm">
                <TableCaption placement="top" pb="6" fontSize={"lg"}>
                  Income & Bank Deposit ({selectedMonth.label},{" "}
                  {selectedYear.label})
                </TableCaption>

                <Thead>
                  <Tr>
                    <Th width="25%">Source</Th>
                    <Th isNumeric width="25%">
                      Cash
                    </Th>
                    <Th isNumeric width="25%">
                      Bank
                    </Th>
                    <Th isNumeric width="25%">
                      Total
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getLedgerBookResult.data.report["Income & Bank Deposit"].map(
                    (row, index) => {
                      return (
                        <Tr key={index}>
                          <Td width="25%">{row["Source"]}</Td>
                          <Td isNumeric width="25%">
                            ৳ {row["Cash"]}
                          </Td>
                          <Td isNumeric width="25%">
                            ৳ {row["Bank"]}
                          </Td>
                          <Td isNumeric width="25%">
                            ৳ {row["Total"]}
                          </Td>
                        </Tr>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </PageCardComponent>

          <PageCardComponent>
            <TableContainer>
              <Table variant="striped" size="sm">
                <TableCaption placement="top" pb="6" fontSize={"lg"}>
                  Bank Deposit & Expenditure ({selectedMonth.label},{" "}
                  {selectedYear.label})
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th width="25%">Expenditure</Th>
                    <Th isNumeric width="25%">
                      Cash
                    </Th>
                    <Th isNumeric width="25%">
                      Bank
                    </Th>
                    <Th isNumeric width="25%">
                      Total
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getLedgerBookResult.data.report[
                    "Bank Deposit & Expenditure"
                  ].map((row, index) => {
                    return (
                      <Tr key={index}>
                        <Td width="25%">{row["Expenditure"]}</Td>
                        <Td isNumeric width="25%">
                          ৳ {row["Cash"]}
                        </Td>
                        <Td isNumeric width="25%">
                          ৳ {row["Bank"]}
                        </Td>
                        <Td isNumeric width="25%">
                          ৳ {row["Total"]}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </PageCardComponent>

          <Stack direction="row">
            <PageCardComponent>
              <TableContainer>
                <Table variant="striped" size="sm">
                  <TableCaption placement="top" pb="6" fontSize={"lg"}>
                    Cash Ledger ({selectedMonth.label}, {selectedYear.label})
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th width="25%">Label</Th>
                      <Th isNumeric width="75%">
                        Amount
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {getLedgerBookResult.data.report["Cash Ledger"].map(
                      (row, index) => {
                        return (
                          <Tr key={index}>
                            <Td width="25%">{row["Source"]}</Td>
                            <Td isNumeric width="75%">
                              ৳ {row["Amount"]}
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </PageCardComponent>

            <PageCardComponent>
              <TableContainer>
                <Table variant="striped" size="sm">
                  <TableCaption placement="top" pb="6" fontSize={"lg"}>
                    Bank Ledger ({selectedMonth.label}, {selectedYear.label})
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th width="25%">Label</Th>
                      <Th isNumeric width="75%">
                        Amount
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {getLedgerBookResult.data.report["Bank Ledger"].map(
                      (row, index) => {
                        return (
                          <Tr key={index}>
                            <Td width="25%">{row["Source"]}</Td>
                            <Td isNumeric width="75%">
                              ৳ {row["Amount"]}
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </PageCardComponent>
          </Stack>
        </Stack>
      ) : getLedgerBookResult.isFetching ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Center>
          <Text>There was an error</Text>
        </Center>
      )}
    </Stack>
  );
};
