import { Stack } from "@chakra-ui/react";
import React from "react";
import { ShiftWiseQuantityComponent } from "../components/shift-wise-quantity.component";

interface ShiftQuantityReportPageProps {}

export const ShiftQuantityReportPage: React.FC<ShiftQuantityReportPageProps> = (
  props: ShiftQuantityReportPageProps
) => {
  return (
    <Stack>
      <ShiftWiseQuantityComponent />
    </Stack>
  );
};
