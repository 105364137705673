import {
  Button,
  Center,
  Spinner,
  Stack,
  Stat,
  StatLabel,
  StatNumber
} from "@chakra-ui/react";
import {
  Patient,
  Person,
  Transaction,
  Voucher,
  VoucherRecord
} from "app/api/type";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { TableGenerator } from "modules/core/components/table-generator.component";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

interface TransferVoucherTableComponentProps {
  vouchersWithDetails: {
    voucher: Voucher;
    person: Person;
    patient: Patient;
    transactions: Transaction[];
    voucherRecords: VoucherRecord[];
  }[];
  month: string;
  year: string;
  pagination: {
    pageSize: number;
    pageIndex: number;
    pageCount: number;
  };
  setPagination: Dispatch<
    SetStateAction<{ pageIndex: number; pageSize: number }>
  >;
  statistics: { [key: string]: number };
}

export const TransferVoucherTableComponent: React.FC<
  TransferVoucherTableComponentProps
> = (props: TransferVoucherTableComponentProps) => {
  const navigate = useNavigate();

  let tableData: {}[] = [];

  if (props.vouchersWithDetails) {
    tableData = props.vouchersWithDetails.map((vouchersWithDetails, index) => {
      return {
        "Sl.":
          props.pagination.pageIndex * props.pagination.pageSize + index + 1,
        Date: dayjs(vouchersWithDetails.voucher.createdAt).format(
          "hh:mm A, MMM DD, YYYY"
        ),
        "Transaction ID": vouchersWithDetails.voucher.id,

        "Transaction Amount": `৳ ${vouchersWithDetails.voucher.paidAmount.toFixed(
          2
        )}`,

        Action: (
          <Button
            size={"sm"}
            colorScheme="blue"
            onClick={() =>
              navigate(
                `/${
                  window.localStorage.getItem("locationCategory") as string
                }/voucher?voucherId=${vouchersWithDetails.voucher.id}`
              )
            }
          >
            Details
          </Button>
        )
      };
    });
  }

  return (
    <Stack spacing={6}>
      {props.vouchersWithDetails ? (
        <Stack spacing={6}>
          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            {Object.entries(props.statistics).map(([key, value]) => (
              <PageCardComponent>
                <Stat>
                  <StatLabel>{key}</StatLabel>
                  <StatNumber>
                    {key.toLowerCase().includes("amount") ? "৳ " : ""}
                    {value}
                  </StatNumber>
                </Stat>
              </PageCardComponent>
            ))}
          </Stack>
          <TableGenerator
            data={tableData}
            pageIndex={props.pagination.pageIndex}
            pageSize={props.pagination.pageSize}
            setPagination={props.setPagination}
            pageCount={props.pagination.pageCount}
          />
        </Stack>
      ) : (
        <Center width="100%" height={"50vh"}>
          <Spinner />
        </Center>
      )}
    </Stack>
  );
};
