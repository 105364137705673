import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { monthList, yearList } from "utils/date";
import {
  useGetCategorizedSublocationsQuery,
  useLazyGetMonthlyQuantityHistoryQuery
} from "../api";

interface MonthlyQuantityReportComponentProps {}

export const MonthlyQuantityReportComponent: React.FC<
  MonthlyQuantityReportComponentProps
> = (props: MonthlyQuantityReportComponentProps) => {
  const [getQuantityReport, getQuantityReportResult] =
    useLazyGetMonthlyQuantityHistoryQuery();

  const getCategorizedSublocationsResult = useGetCategorizedSublocationsQuery({
    locationCategoryName: "Counter"
  });
  const [categorizedSublocations, setCategorizedSublocations] = useState<
    { label: string; value: string }[]
  >([]);
  const [categorizedSublocation, setCategorizedSublocation] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (getCategorizedSublocationsResult.data) {
      const sublocations = [
        { label: "All", value: "all" },
        ...getCategorizedSublocationsResult.data.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
      ];
      setCategorizedSublocations(sublocations);
      setCategorizedSublocation(sublocations[0]);
    }
  }, [getCategorizedSublocationsResult.data]);

  const [selectedYear, setSelectedYear] = useState<{
    label: string;
    value: number;
  }>({
    label: dayjs().year().toString(),
    value: dayjs().year()
  });

  const [selectedMonth, setSelectedMonth] = useState<{
    label: string;
    value: number;
  }>(monthList[dayjs().month()]);

  useEffect(() => {
    if (categorizedSublocation) {
      getQuantityReport({
        month: selectedMonth.value,
        year: selectedYear.value,
        locationId: categorizedSublocation.value
      });
    }
  }, [selectedMonth, selectedYear, categorizedSublocation]);

  let headers = [{ label: "Date", key: "date" }];

  getQuantityReportResult.data &&
    getQuantityReportResult.data.report.daily[1] &&
    Object.entries(getQuantityReportResult.data.report.daily[1]).map(
      ([key, value]) => {
        headers.push({ label: key, key: key.replace(/\s/g, "") });
      }
    );

  let reportData: any = [];
  getQuantityReportResult.data ? (
    Object.entries(getQuantityReportResult.data.report.daily).map(
      ([key, value]) => {
        let reportObj: any = {
          date: `${selectedMonth.label} ${key} ${selectedYear.value}`
        };
        Object.entries(value).map(([key, value]) => {
          let tempKey = key.replace(/\s/g, "");
          reportObj[`${tempKey}`] = value;
        });

        reportData.push(reportObj);
      }
    )
  ) : (
    <></>
  );

  let reportTotalSum: any = {
    date: `${selectedMonth.label} ${selectedYear.value}`
  };
  getQuantityReportResult.data ? (
    Object.entries(getQuantityReportResult.data.report.monthly).map(
      ([key, value]) => {
        let tempKey = key.replace(/\s/g, "");
        reportTotalSum[`${tempKey}`] = value;
      }
    )
  ) : (
    <></>
  );
  reportData.push(reportTotalSum);

  const monthlyQuantityReport = `Monthly-Quantity-Report-${selectedMonth.label}-${selectedYear.label}.csv`;

  return (
    <Stack spacing="6">
      <PageCardComponent>
        <Stack direction="row">
          <FormControl id="year">
            <FormLabel>Year</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={yearList}
              value={selectedYear}
              onChange={(event) => {
                if (event) {
                  setSelectedYear({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>

          <FormControl id="month">
            <FormLabel>Month</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={monthList}
              value={selectedMonth}
              onChange={(event) => {
                if (event) {
                  setSelectedMonth({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>

          <FormControl id="location">
            <FormLabel>Location</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={categorizedSublocations}
              value={categorizedSublocation}
              onChange={(event) => {
                if (event) {
                  setCategorizedSublocation({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>
      </PageCardComponent>

      <Stack width="100%">
        {getQuantityReportResult.data && (
          <Stack alignSelf={"flex-end"}>
            {" "}
            <CSVLink
              data={reportData}
              headers={headers}
              filename={monthlyQuantityReport}
              className="btn btn-primary"
              target="_blank"
            >
              <Button colorScheme="blue">Export CSV</Button>
            </CSVLink>
          </Stack>
        )}
      </Stack>

      <PageCardComponent>
        {getQuantityReportResult.data ? (
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  {getQuantityReportResult.data &&
                  getQuantityReportResult.data.report.daily[1] ? (
                    Object.entries(
                      getQuantityReportResult.data.report.daily[1]
                    ).map(([key, value]) => {
                      return <Th>{key}</Th>;
                    })
                  ) : (
                    <></>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {getQuantityReportResult.data ? (
                  Object.entries(getQuantityReportResult.data.report.daily).map(
                    ([key, value]) => {
                      return (
                        <Tr key={key}>
                          <Td>
                            {selectedMonth.label} {key}, {selectedYear.value}
                          </Td>
                          {Object.entries(value).map(([key, value]) => {
                            return (
                              <Td
                                fontWeight={value ? "bold" : "normal"}
                                color={value ? "black" : "gray.500"}
                              >
                                {value}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
                <Tr bgColor="gray.600" color="white" fontWeight="bold">
                  <Td>
                    {selectedMonth.label}, {selectedYear.value}
                  </Td>

                  {getQuantityReportResult.data ? (
                    Object.entries(
                      getQuantityReportResult.data.report.monthly
                    ).map(([key, value]) => {
                      return <Td key={key}>{value}</Td>;
                    })
                  ) : (
                    <></>
                  )}
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Center height="15vh" width="100%">
            {" "}
            <Spinner />
          </Center>
        )}
      </PageCardComponent>
    </Stack>
  );
};
