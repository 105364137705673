import React from "react";
import { Stack } from "@chakra-ui/react";
import { CreateBankExpenseVoucherComponent } from "../components/create-bank-expense-voucher.component";

interface Props {}

export const CreateBankExpenseVoucherPage: React.FC<Props> = (props: Props) => {
  return (
    <Stack>
      <CreateBankExpenseVoucherComponent />
    </Stack>
  );
};
