import {
  Center,
  FormControl,
  FormLabel,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import {
  useGetCategorizedSublocationsQuery,
  useLazyGetDailyCashCollectionReportQuery
} from "modules/counter/api";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { IncomeSummaryPieChartComponent } from "./income-summary-pie-chart.component";

interface Props {}

export const ShiftWiseIncomeComponent: React.FC<Props> = (props: Props) => {
  const [getCashCollectionReport, getCashCollectionReportResult] =
    useLazyGetDailyCashCollectionReportQuery({});

  const getCategorizedSublocationsResult = useGetCategorizedSublocationsQuery({
    locationCategoryName: "Counter"
  });
  const [categorizedSublocations, setCategorizedSublocations] = useState<
    { label: string; value: string }[]
  >([]);
  const [categorizedSublocation, setCategorizedSublocation] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (getCategorizedSublocationsResult.data) {
      const sublocations = [
        { label: "All", value: "all" },
        ...getCategorizedSublocationsResult.data.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
      ];
      setCategorizedSublocations(sublocations);
      setCategorizedSublocation(sublocations[0]);
    }
  }, [getCategorizedSublocationsResult.data]);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (categorizedSublocation) {
      getCashCollectionReport({
        date,
        locationId: categorizedSublocation.value
      });
    }
  }, [date, categorizedSublocation]);

  return (
    <Stack width="100%">
      <PageCardComponent>
        <Stack direction="row">
          <FormControl width="100%">
            <FormLabel>Date</FormLabel>
            <DatePicker
              selected={date}
              onChange={(date) => date && setDate(date)}
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
            />
          </FormControl>

          <FormControl width="100%">
            <FormLabel>Location</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={categorizedSublocations}
              value={categorizedSublocation}
              onChange={(event) => {
                if (event) {
                  setCategorizedSublocation({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>{" "}
      </PageCardComponent>

      <Center>
        <Text fontWeight="semibold" my="2">
          Daily Shift Wise Income Summary ({dayjs(date).format("DD MMM, YYYY")})
        </Text>
      </Center>

      {getCashCollectionReportResult.data ? (
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
          my="2"
        >
          {getCashCollectionReportResult.data.report.map(
            (reportEntry, index) => {
              return (
                <>
                  <PageCardComponent>
                    <Stat>
                      <StatLabel>
                        Shift {dayjs(reportEntry.shiftStart).format("h:mm A")} -
                        {dayjs(reportEntry.shiftEnd).format("h:mm A")}
                      </StatLabel>
                      <StatHelpText>
                        Quantity - {reportEntry.voucherQuantity}
                      </StatHelpText>
                      <StatNumber>৳ {reportEntry.income}</StatNumber>
                    </Stat>
                  </PageCardComponent>
                </>
              );
            }
          )}
        </Stack>
      ) : (
        <></>
      )}
      {getCashCollectionReportResult.data && (
        <IncomeSummaryPieChartComponent
          getCashCollectionReport={getCashCollectionReportResult.data}
        />
      )}
    </Stack>
  );
};
