import {
  Center,
  FormControl,
  FormLabel,
  Spacer,
  Spinner,
  Stack,
  TableContainer
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { monthList, yearList } from "utils/date";
import {
  useGetCategorizedSublocationsQuery,
  useLazyGetMonthlyVoucherListQuery
} from "../api";
import { PrintBankTransferHistoryComponent } from "../print/components/print-bank-transfer-history.component";
import { PrintExpenseHistoryComponent } from "../print/components/print-expense-history.component";
import { ExpenseVoucherTableComponent } from "./expense-voucher-table.component";
import { TransferVoucherTableComponent } from "./transfer-voucher-table.component";

interface Props {
  type: string;
}

export const MonthlyVoucherListComponent: React.FC<Props> = (props: Props) => {
  const [getVoucherList, getVoucherListResult] =
    useLazyGetMonthlyVoucherListQuery();

  const [getAllVoucherLists, getAllVoucherListsResult] =
    useLazyGetMonthlyVoucherListQuery();

  const getCategorizedSublocationsResult = useGetCategorizedSublocationsQuery({
    locationCategoryName: "Counter"
  });
  const [categorizedSublocations, setCategorizedSublocations] = useState<
    { label: string; value: string }[]
  >([]);
  const [categorizedSublocation, setCategorizedSublocation] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (getCategorizedSublocationsResult.data) {
      const sublocations = [
        { label: "All", value: "all" },
        ...getCategorizedSublocationsResult.data.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
      ];
      setCategorizedSublocations(sublocations);
      setCategorizedSublocation(sublocations[0]);
    }
  }, [getCategorizedSublocationsResult]);

  const [selectedYear, setSelectedYear] = useState<{
    label: string;
    value: number;
  }>({
    label: dayjs().year().toString(),
    value: dayjs().year()
  });

  const [selectedMonth, setSelectedMonth] = useState<{
    label: string;
    value: number;
  }>(monthList[dayjs().month()]);

  const [{ pageIndex, pageSize }, setPaginaiton] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  useEffect(() => {
    if (categorizedSublocation) {
      getVoucherList({
        type: props.type,
        month: selectedMonth.value,
        year: selectedYear.value,
        locationId: categorizedSublocation.value,
        pageSize,
        pageIndex
      });
    }
  }, [
    selectedMonth,
    selectedYear,
    categorizedSublocation,
    getVoucherList,
    props.type,
    pageIndex,
    pageSize
  ]);

  useEffect(() => {
    if (categorizedSublocation) {
      getAllVoucherLists({
        type: props.type,
        month: selectedMonth.value,
        year: selectedYear.value,
        locationId: categorizedSublocation.value
      });
    }
  }, [selectedMonth, selectedYear, categorizedSublocation, props.type]);

  return (
    <Stack spacing="6">
      <PageCardComponent>
        <Stack direction="row">
          <FormControl id="year">
            <FormLabel>Year</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={yearList}
              value={selectedYear}
              onChange={(event) => {
                if (event) {
                  setSelectedYear({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>

          <FormControl id="month">
            <FormLabel>Month</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={monthList}
              value={selectedMonth}
              onChange={(event) => {
                if (event) {
                  setSelectedMonth({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>
      </PageCardComponent>

      <PageCardComponent>
        {getVoucherListResult.data ? (
          <TableContainer>
            <Stack
              direction={{ base: "column", xl: "row" }}
              minWidth="max-content"
            >
              <Spacer />
              {props.type === "Coupon Expense" &&
                getAllVoucherListsResult.data && (
                  <PrintExpenseHistoryComponent
                    expenseHistory={getAllVoucherListsResult.data}
                    month={selectedMonth.label}
                    year={selectedYear.label}
                  />
                )}
              {props.type === "Bank Transfer" &&
                getAllVoucherListsResult.data && (
                  <PrintBankTransferHistoryComponent
                    bankTransferHistory={getAllVoucherListsResult.data}
                    month={selectedMonth.label}
                    year={selectedYear.label}
                  />
                )}
            </Stack>
            {props.type === "Coupon Expense" && (
              <ExpenseVoucherTableComponent
                vouchersWithDetails={
                  getVoucherListResult.data.vouchersWithDetails
                }
                month={selectedMonth.label}
                year={selectedYear.label}
                statistics={getVoucherListResult.data.statistics}
                pagination={getVoucherListResult.data.pagination}
                setPagination={setPaginaiton}
              />
            )}
            {props.type === "Bank Transfer" && (
              <TransferVoucherTableComponent
                vouchersWithDetails={
                  getVoucherListResult.data.vouchersWithDetails
                }
                statistics={getVoucherListResult.data.statistics}
                month={selectedMonth.label}
                year={selectedYear.label}
                pagination={getVoucherListResult.data.pagination}
                setPagination={setPaginaiton}
              />
            )}
          </TableContainer>
        ) : (
          <Center width="100%" height="10vh">
            <Spinner />
          </Center>
        )}
      </PageCardComponent>
    </Stack>
  );
};
