export const getDashboardName = (locationCategoryName: string) => {
  switch (locationCategoryName) {
    case "Admin":
      return "admin";
    case "Hospital":
      return "hospital";
    case "Department":
      return "department";
    case "Counter":
      return "counter";
    case "Doctor's Chamber":
      return "doctor";
    default:
      return "department";
  }
};
