import React, { useEffect, useState } from "react";
import {
  Stack,
  Heading,
  Text,
  Spinner,
  Button,
  useToast,
  Textarea,
  FormLabel,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Center
} from "@chakra-ui/react";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { useCreateTransferVoucherMutation, useGetAccountQuery } from "../api";
import { useNavigate } from "react-router-dom";

interface BankTransferComponentProps {}

export const BankTransferComponent: React.FC<BankTransferComponentProps> = (
  props: BankTransferComponentProps
) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [createTransferVoucher, createTransferVoucherResult] =
    useCreateTransferVoucherMutation();
  const getCashAccountResult = useGetAccountQuery({ name: "Cash" });
  const getBankAccountResult = useGetAccountQuery({ name: "Bank" });

  const [transactionAmount, setTransactionAmount] = useState<number>();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const [note, setNote] = useState<string>();

  const handleTransfer = () => {
    if (transactionAmount) {
      createTransferVoucher({
        data: {
          voucher: {
            wardTypeName: null,
            voucherTypeName: "Bank Transfer",
            note: note || null,
            paidAmount: transactionAmount,
            wardNumber: null,
            bedNumber: null,
            dueAmount: 0,
            sourceAccountName: "Cash",
            destinationAccountName: "Bank"
          },
          voucherRecords: [
            {
              basePrice: transactionAmount,
              totalPrice: transactionAmount,
              quantity: 1,
              details: null,
              taxPercentage: 0,
              expenseId: null,
              discountPercentage: 0,
              investigationId: null
            }
          ]
        }
      });
    }
  };

  useEffect(() => {
    if (createTransferVoucherResult.isSuccess) {
      toast({
        title: "Success",
        description: "Voucher has been created",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });

      const locationCategory = window.localStorage.getItem(
        "locationCategory"
      ) as string;

      navigate(
        `/${locationCategory.toLowerCase()}/voucher?voucherId=${
          createTransferVoucherResult.data.voucher.id
        }`
      );
    }
  }, [createTransferVoucherResult]);

  const ConfirmationModalComponent = () => {
    return (
      <>
        <Button
          colorScheme={"blue"}
          onClick={onOpen}
          isLoading={isOpen}
          isDisabled={
            !getCashAccountResult.data ||
            getCashAccountResult.data.account.balance === 0 ||
            !transactionAmount ||
            (getCashAccountResult.data &&
              !!transactionAmount &&
              getCashAccountResult.data.account.balance < transactionAmount)
          }
        >
          Transfer Cash to Bank
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Bank Transfer</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {getCashAccountResult.data ? (
                <Stack spacing="6">
                  <Text>
                    You are about to transfer{" "}
                    <Text as="span" fontWeight="bold">
                      ৳ {transactionAmount}
                    </Text>{" "}
                    from Cash Account to Bank Account
                    {note ? ` with additional note: "${note}"` : ""}. Are you
                    sure you want to proceed?
                  </Text>
                </Stack>
              ) : (
                <Center width="100%" height="256px">
                  <Spinner />
                </Center>
              )}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={handleTransfer}
                isLoading={createTransferVoucherResult.isLoading}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Stack spacing="4">
      <Stack direction={"row"} spacing="8">
        <PageCardComponent>
          <Stack>
            <Text>Cash</Text>
            {getCashAccountResult.data ? (
              <Heading>৳ {getCashAccountResult.data.account.balance}</Heading>
            ) : (
              <Spinner />
            )}
          </Stack>
        </PageCardComponent>

        <PageCardComponent>
          <Stack>
            <Text>Bank</Text>
            {getBankAccountResult.data ? (
              <Heading>৳ {getBankAccountResult.data.account.balance}</Heading>
            ) : (
              <Spinner />
            )}
          </Stack>
        </PageCardComponent>
      </Stack>

      <PageCardComponent>
        <FormControl id="transactionAmount">
          <FormLabel>Transaction Amount (Cash {`->`} Bank)</FormLabel>
          {getCashAccountResult.data ? (
            <Input
              type="number"
              value={transactionAmount}
              max={getCashAccountResult.data.account.balance}
              min={0}
              onChange={(event) =>
                setTransactionAmount(event.target.valueAsNumber)
              }
            />
          ) : (
            <Spinner />
          )}
        </FormControl>

        <FormControl id="note">
          <FormLabel>Note</FormLabel>
          <Textarea
            value={note}
            onChange={(event) => setNote(event.target.value)}
            placeholder="Bank transfer information"
          />
        </FormControl>
        <ConfirmationModalComponent />
      </PageCardComponent>
    </Stack>
  );
};
