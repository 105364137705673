import {
  Stack,
  SimpleGrid,
  GridItem,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Checkbox
} from "@chakra-ui/react";
import { Designation } from "app/api/type";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { FC, FormEvent, useEffect, useState } from "react";

import {
  useGetDoctorsDesignationsQuery,
  useLazySearchInvestigationsWithCommissionRatesQuery
} from "../api";
import { InvestigationWithCommissionRates } from "../api/type";
import { EditableDesignationRatesCardComponent } from "./editable-designation-rates.card.component";

export const EditCommissionRatesComponent: FC = () => {
  const [query, setQuery] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedInvestigations, setSelectedInvestigations] = useState<
    Set<string>
  >(new Set());

  const getDesignationsResults = useGetDoctorsDesignationsQuery({});
  const [getInvestigationsList, getInvestigationsListResult] =
    useLazySearchInvestigationsWithCommissionRatesQuery();

  const handleSearch = async (event: FormEvent) => {
    event.preventDefault();
    await getInvestigationsList({
      query
    });
  };

  useEffect(() => {
    getInvestigationsList({
      query
    });
  }, [getInvestigationsList, query]);

  const handleRowClick = (investigationId: string) => {
    console.log(investigationId);
    if (selectedInvestigations.has(investigationId)) {
      console.log("Removing");
      setSelectedInvestigations((prevState) => {
        prevState.delete(investigationId);
        return new Set(prevState);
      });
    } else {
      console.log("Adding");
      setSelectedInvestigations(
        (prevState) => new Set(prevState.add(investigationId))
      );
    }
  };

  const handleSelectAll = () => {
    if (
      selectedInvestigations.size ===
      getInvestigationsListResult.data?.investigationsWithCommissionRates.length
    )
      return;

    if (getInvestigationsListResult.isSuccess) {
      getInvestigationsListResult.data.investigationsWithCommissionRates.forEach(
        (investigation) => {
          setSelectedInvestigations(
            (prevState) => new Set(prevState.add(investigation.id))
          );
        }
      );
    }
  };

  const handleClear = () => {
    setSelectedInvestigations(() => new Set());
  };

  return (
    <Stack>
      <PageCardComponent>
        <Stack spacing={4}>
          <form onSubmit={handleSearch}>
            <SimpleGrid columns={10} columnGap={4}>
              <GridItem colSpan={7} marginY={2}>
                <Input
                  type="text"
                  placeholder="Search Investigations"
                  value={query}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setQuery(event.target.value)
                  }
                />
              </GridItem>

              <GridItem marginY={2}>
                <Button
                  colorScheme={
                    selectedInvestigations.size !==
                    getInvestigationsListResult.data
                      ?.investigationsWithCommissionRates.length
                      ? "blue"
                      : "gray"
                  }
                  width="100%"
                  isDisabled={
                    selectedInvestigations.size ===
                    getInvestigationsListResult.data
                      ?.investigationsWithCommissionRates.length
                  }
                  isLoading={getInvestigationsListResult.isLoading}
                  onClick={handleSelectAll}
                >
                  Select all
                </Button>
              </GridItem>
              <GridItem marginY={2}>
                <Button
                  colorScheme={selectedInvestigations.size > 0 ? "red" : "gray"}
                  width="100%"
                  isDisabled={selectedInvestigations.size === 0}
                  isLoading={getInvestigationsListResult.isLoading}
                  onClick={handleClear}
                >
                  Clear All
                </Button>
              </GridItem>
              <GridItem marginY={2}>
                <Button
                  isDisabled={selectedInvestigations.size === 0}
                  width="100%"
                  onClick={onOpen}
                >
                  Edit ({selectedInvestigations.size})
                </Button>
              </GridItem>
            </SimpleGrid>
          </form>

          <TableContainer>
            <Table columnGap="1ex" variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Investigation</Th>
                  {getDesignationsResults.isSuccess &&
                    getDesignationsResults.data?.designations.map(
                      (designation: Designation) => (
                        <Th key={designation.name} textAlign="center">
                          {designation.name + " (%)"}
                        </Th>
                      )
                    )}
                </Tr>
              </Thead>
              <Tbody>
                {getInvestigationsListResult.isSuccess &&
                  getInvestigationsListResult.data?.investigationsWithCommissionRates.map(
                    (investigation: InvestigationWithCommissionRates) => {
                      const commissionRates =
                        investigation.commissionPercentages;
                      return (
                        <Tr
                          key={investigation.id}
                          onClick={() => handleRowClick(investigation.id)}
                          backgroundColor={
                            selectedInvestigations.has(investigation.id)
                              ? "blue.100"
                              : "default"
                          }
                          _hover={{
                            cursor: "pointer",
                            backgroundColor: "blue.100"
                          }}
                          fontWeight={
                            selectedInvestigations.has(investigation.id)
                              ? "bold"
                              : "normal"
                          }
                        >
                          <Td width="5ex" textAlign="center">
                            <Checkbox
                              pointerEvents={"none"}
                              isChecked={selectedInvestigations.has(
                                investigation.id
                              )}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleRowClick(investigation.id);
                              }}
                            />
                          </Td>
                          <Td maxWidth="30ex" whiteSpace="pre-line">
                            {investigation.name}
                          </Td>
                          {commissionRates &&
                            commissionRates.map((commissionRate) => {
                              return (
                                <Td
                                  key={commissionRate.designationName}
                                  textAlign="center"
                                >
                                  {commissionRate.percentage !== 0
                                    ? commissionRate.percentage
                                    : "-"}
                                </Td>
                              );
                            })}
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </PageCardComponent>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <EditableDesignationRatesCardComponent
            selectedInvestigations={Array.from(selectedInvestigations)}
            onClose={onClose}
          />
        </ModalContent>
      </Modal>
    </Stack>
  );
};
