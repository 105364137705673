import { api } from "app/api";
import * as ApiTypes from "./type";

const departmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDoctorsWithCommissions: builder.query<
      ApiTypes.GetDoctorsResponse,
      ApiTypes.GetDoctorsRequest
    >({
      query: () => ({
        url: `/doctor/commissions/list`,
        method: `GET`
      }),
      providesTags: ["Commission"]
    }),

    clearCommissionDues: builder.mutation<
      ApiTypes.ClearCommissionsResponse,
      ApiTypes.ClearCommissionsRequest
    >({
      query: ({ bmdcNumber, amount }) => ({
        url: `/doctor/commissions/clear`,
        method: `POST`,
        body: { bmdcNumber, amount }
      }),
      invalidatesTags: ["Commission"]
    }),

    createDoctorDesignation: builder.mutation<
      ApiTypes.CreateDoctorDesignationResponse,
      ApiTypes.CreateDoctorDesignationRequest
    >({
      query: ({ designation }) => ({
        url: `/designation/new`,
        method: `POST`,
        body: { designation }
      }),
      invalidatesTags: ["Doctor Designation"]
    }),
    getDoctorsDesignations: builder.query<
      ApiTypes.GetDoctorsDesignationResponse,
      ApiTypes.GetDoctorsDesignationRequest
    >({
      query: () => ({
        url: `/designation/doctor/get`,
        method: `GET`
      }),
      providesTags: ["Doctor Designation"]
    }),
    createDoctor: builder.mutation<
      ApiTypes.CreateDoctorResponse,
      ApiTypes.CreateDoctorRequest
    >({
      query: ({ doctor, user, person }) => ({
        url: `/doctor/new`,
        method: `POST`,
        body: { doctor, user, person }
      })
    }),
    searchInvestigationsWithCommissionRates: builder.query<
      ApiTypes.SearchInvestigationsWithCommissionRatesResponse,
      ApiTypes.SearchInvestigationsWithCommissionRatesRequest
    >({
      query: ({ query }) => ({
        url: `/investigation/commission-rates/search/`,
        method: `POST`,
        body: { query }
      }),
      providesTags: ["Doctor Designation"]
    }),
    getExistingDesignationsForCommission: builder.query<
      ApiTypes.GetDesignationsFromInvestigationsResponse,
      ApiTypes.GetDesignationsFromInvestigationsRequest
    >({
      query: ({ investigationId }) => ({
        url: `/investigation/${investigationId}/existing-designations/get`,
        method: `GET`
      }),
      providesTags: ["Commission Rate"]
    }),
    getNonExistingDesignationsForCommission: builder.query<
      ApiTypes.GetDoctorsDesignationResponse,
      ApiTypes.GetDesignationsFromInvestigationsRequest
    >({
      query: ({ investigationId }) => ({
        url: `/investigation/${investigationId}/missing-designations/get`,
        method: `GET`
      }),
      providesTags: ["Commission Rate"]
    }),
    editCommissionRatesForInvestigations: builder.mutation<
      ApiTypes.EditCommissionRatesForInvestigationsResponse,
      ApiTypes.EditCommissionRatesForInvestigationsRequest
    >({
      query: ({ investigationIds, commissionRates }) => ({
        url: `/investigation/commission-rates/edit`,
        method: `PUT`,
        body: { investigationIds, commissionRates }
      }),
      invalidatesTags: ["Commission Rate"]
    }),
    createCommission: builder.mutation<
      ApiTypes.GetCommissionRateForInvestigationResponse,
      ApiTypes.GetCommissionRateForInvestigationRequest
    >({
      query: ({ investigationId, designationName, percentage }) => ({
        url: `/investigation/commission-rate/new`,
        method: `POST`,
        body: { investigationId, designationName, percentage }
      }),
      invalidatesTags: ["Commission Rate"]
    }),

    deleteCommission: builder.mutation<
      ApiTypes.GetCommissionRateForInvestigationResponse,
      ApiTypes.DeleteCommissionRateForInvestigationRequest
    >({
      query: ({ investigationId, designationName }) => ({
        url: `/investigation/commission/delete`,
        method: `PUT`,
        body: { investigationId, designationName }
      }),
      invalidatesTags: ["Commission Rate"]
    }),

    getInvestigationQuantityInsight: builder.query<
      ApiTypes.GetInvestigationQuantityInsightResponse,
      ApiTypes.GetInvestigationQuantityInsightRequest
    >({
      query: ({ from, to, locationId }) => ({
        url: `/voucher/investigation-quantity-insight/get`,
        method: `GET`,
        params: { from, to, locationId }
      }),
      providesTags: ["Investigation"]
    }),
    getInvestigationNames: builder.query<
      ApiTypes.GetInvestigationNamesResponse,
      ApiTypes.GetInvestigationNamesRequest
    >({
      query: () => ({
        url: `/investigation/names/list`,
        method: `GET`
      }),
      providesTags: ["Investigation"]
    }),
    getInvestigationDetails: builder.query<
      ApiTypes.GetInvestigationDetailsResponse,
      ApiTypes.GetInvestigationDetailsRequest
    >({
      query: ({ investigationId }) => ({
        url: `/investigation/${investigationId}/get`,
        method: `GET`
      }),
      providesTags: ["Investigation"]
    }),
    getInvestigationTypes: builder.query<
      ApiTypes.GetInvestigationTypesResponse,
      ApiTypes.GetInvestigationTypesRequest
    >({
      query: () => ({
        url: `/investigation/types/get`,
        method: `GET`
      })
    }),
    updateInvestigation: builder.mutation<
      ApiTypes.UpdateInvestigationResponse,
      ApiTypes.UpdateInvestigationRequest
    >({
      query: ({ body, params }) => ({
        url: `/investigation/${params.investigationId}/edit`,
        method: `PUT`,
        body
      }),
      invalidatesTags: ["Investigation"]
    }),
    editInvestigationPrice: builder.mutation<
      ApiTypes.EditInvestigationPriceResponse,
      ApiTypes.EditInvestigationPriceRequest
    >({
      query: ({ investigationId, wardType, price }) => ({
        url: `/investigation/price/edit`,
        method: `PATCH`,
        body: {
          investigationId,
          wardType,
          price
        }
      }),
      invalidatesTags: ["Investigation"]
    })
  }),
  overrideExisting: false
});

export const {
  useGetDoctorsWithCommissionsQuery,
  useClearCommissionDuesMutation,
  useCreateDoctorDesignationMutation,
  useGetDoctorsDesignationsQuery,
  useCreateDoctorMutation,
  useLazyGetExistingDesignationsForCommissionQuery,
  useLazyGetNonExistingDesignationsForCommissionQuery,
  useEditCommissionRatesForInvestigationsMutation,
  useCreateCommissionMutation,
  useDeleteCommissionMutation,
  useLazySearchInvestigationsWithCommissionRatesQuery,
  useLazyGetInvestigationQuantityInsightQuery,
  useGetInvestigationNamesQuery,
  useGetInvestigationDetailsQuery,
  useGetInvestigationTypesQuery,
  useUpdateInvestigationMutation,
  useEditInvestigationPriceMutation
} = departmentApi;
