import {
  Center,
  FormControl,
  FormLabel,
  Spacer,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useGetCategorizedSublocationsQuery,
  useLazyGetDailyCashCollectionReportQuery
} from "../api";
import "../styles/chakra-react-datepicker.css";
import { PrintDailyCashCollectionReportComponent } from "./print-daily-cash-collection-report.component";

interface DailyCashCollectionReportComponentProps {}

export const DailyCashCollectionReportComponent: React.FC<
  DailyCashCollectionReportComponentProps
> = (props: DailyCashCollectionReportComponentProps) => {
  const [getCashCollectionReport, getCashCollectionReportResult] =
    useLazyGetDailyCashCollectionReportQuery({});

  const getCategorizedSublocationsResult = useGetCategorizedSublocationsQuery({
    locationCategoryName: "Counter"
  });
  const [categorizedSublocations, setCategorizedSublocations] = useState<
    { label: string; value: string }[]
  >([]);
  const [categorizedSublocation, setCategorizedSublocation] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (getCategorizedSublocationsResult.data) {
      const sublocations = [
        { label: "All", value: "all" },
        ...getCategorizedSublocationsResult.data.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
      ];
      setCategorizedSublocations(sublocations);
      setCategorizedSublocation(sublocations[0]);
    }
  }, [getCategorizedSublocationsResult.data]);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (categorizedSublocation) {
      getCashCollectionReport({
        date,
        locationId: categorizedSublocation.value
      });
    }
  }, [date, categorizedSublocation, getCashCollectionReport]);

  return (
    <Stack spacing="12">
      <PageCardComponent>
        <Stack direction="row">
          <FormControl width="100%">
            <FormLabel>Date</FormLabel>
            <DatePicker
              selected={date}
              onChange={(date) => date && setDate(date)}
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
            />
          </FormControl>

          <FormControl id="location" width="100%">
            <FormLabel>Location</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={categorizedSublocations}
              value={categorizedSublocation}
              onChange={(event) => {
                if (event) {
                  setCategorizedSublocation({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>{" "}
      </PageCardComponent>

      <TableContainer>
        <Table variant="simple" size="sm">
          <TableCaption placement="top" pb="6" fontSize={"lg"} textAlign="left">
            <Center width="100%">
              Daily Cash Collection Report ({dayjs(date).format("DD MMM, YYYY")}
              )
            </Center>
            <Stack
              direction={{ base: "column", xl: "row" }}
              minWidth="max-content"
            >
              <Spacer />
              {getCashCollectionReportResult.data && (
                <PrintDailyCashCollectionReportComponent
                  cashCollectionReport={getCashCollectionReportResult.data}
                  date={date}
                />
              )}
            </Stack>
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Sl. No.</Th>
              <Th>Shift Start</Th>
              <Th>Shift End</Th>
              <Th>Vouchers</Th>
              <Th isNumeric>Quantity</Th>
              <Th isNumeric>Income</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getCashCollectionReportResult.data ? (
              getCashCollectionReportResult.data.report.map(
                (reportEntry, index) => {
                  return (
                    <Tr key={`cash-report-table-row-${index}`}>
                      <Td>{index + 1}</Td>
                      <Td>{dayjs(reportEntry.shiftStart).format("h:mm A")}</Td>
                      <Td>{dayjs(reportEntry.shiftEnd).format("h:mm A")}</Td>
                      <Td>
                        {reportEntry.voucherStart} - {reportEntry.voucherEnd}
                      </Td>
                      <Td isNumeric>{reportEntry.voucherQuantity}</Td>
                      <Td isNumeric>৳ {reportEntry.income}</Td>
                    </Tr>
                  );
                }
              )
            ) : (
              <></>
            )}
            {getCashCollectionReportResult.data ? (
              <Tr bgColor="gray.700" color="white" fontWeight={"bold"}>
                <Td></Td>
                <Td>{dayjs(date).format("DD MMM, YYYY")}</Td>
                <Td></Td>
                <Td></Td>
                <Td isNumeric>
                  {getCashCollectionReportResult.data.report.reduce(
                    (totalQuantity, reportEntry) =>
                      totalQuantity + reportEntry.voucherQuantity,
                    0
                  )}
                </Td>
                <Td isNumeric>
                  ৳{" "}
                  {getCashCollectionReportResult.data.report.reduce(
                    (totalPrice, reportEntry) =>
                      totalPrice + reportEntry.income,
                    0
                  )}
                </Td>
              </Tr>
            ) : (
              <></>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
