import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useLazyGetIncomeExpenseReportQuery } from "modules/counter/api";
import React, { useEffect } from "react";
import { IncomeExpenseBarChartComponent } from "./income-expense-bar-chart.component";

interface IncomeExpenseReportComponentProps {}

export const IncomeExpenseReportComponent: React.FC<
  IncomeExpenseReportComponentProps
> = (props: IncomeExpenseReportComponentProps) => {
  const [getIncomeExpenseReport, getIncomeExpenseReportResult] =
    useLazyGetIncomeExpenseReportQuery({});

  useEffect(() => {
    getIncomeExpenseReport({});
  }, []);

  return (
    <>
      {getIncomeExpenseReportResult.isLoading ||
      !getIncomeExpenseReportResult.data ? (
        <Center width="100%" height="10">
          <Spinner />
        </Center>
      ) : (
        <SimpleGrid spacing={6} columns={2}>
          <Card>
            <CardHeader paddingBottom="2">
              <Heading size="md">Income Insight</Heading>
            </CardHeader>
            <CardBody paddingTop="2">
              <SimpleGrid columns={3} flexDirection="column" fontSize="sm">
                <Stat>
                  <StatLabel>Average Daily Income</StatLabel>
                  <StatNumber>
                    ৳{" "}
                    {
                      getIncomeExpenseReportResult.data.report
                        .averageDailyIncome
                    }
                  </StatNumber>
                  <StatHelpText>
                    {getIncomeExpenseReportResult.data.report.incomes[0].date} -{" "}
                    {
                      getIncomeExpenseReportResult.data.report.incomes[
                        getIncomeExpenseReportResult.data.report.incomes
                          .length - 1
                      ].date
                    }
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Highest Daily Income</StatLabel>
                  <StatNumber>
                    ৳ {getIncomeExpenseReportResult.data.report.highestIncome}
                  </StatNumber>
                  <StatHelpText>
                    {dayjs(
                      getIncomeExpenseReportResult.data.report.highestIncomeDay
                    ).format("DD MMM YYYY")}
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Lowest Daily Income</StatLabel>
                  <StatNumber>
                    ৳ {getIncomeExpenseReportResult.data.report.lowestIncome}
                  </StatNumber>
                  <StatHelpText>
                    {dayjs(
                      getIncomeExpenseReportResult.data.report.lowestIncomeDay
                    ).format("DD MMM YYYY")}
                  </StatHelpText>
                </Stat>
              </SimpleGrid>
            </CardBody>
          </Card>
          <Card>
            <CardHeader paddingBottom="2">
              <Heading size="md">Expense Insight</Heading>
            </CardHeader>
            <CardBody paddingTop="2">
              <SimpleGrid columns={3} flexDirection="column" fontSize="sm">
                <Stat>
                  <StatLabel>Average Daily Expense</StatLabel>
                  <StatNumber>
                    ৳{" "}
                    {
                      getIncomeExpenseReportResult.data.report
                        .averageDailyExpense
                    }
                  </StatNumber>
                  <StatHelpText>
                    {getIncomeExpenseReportResult.data.report.incomes[0].date} -{" "}
                    {
                      getIncomeExpenseReportResult.data.report.incomes[
                        getIncomeExpenseReportResult.data.report.incomes
                          .length - 1
                      ].date
                    }
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Highest Daily Expense</StatLabel>
                  <StatNumber>
                    ৳ {getIncomeExpenseReportResult.data.report.highestExpense}
                  </StatNumber>
                  <StatHelpText>
                    {dayjs(
                      getIncomeExpenseReportResult.data.report.highestExpenseDay
                    ).format("DD MMM YYYY")}
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Lowest Daily Expense</StatLabel>
                  <StatNumber>
                    ৳ {getIncomeExpenseReportResult.data.report.lowestExpense}
                  </StatNumber>
                  <StatHelpText>
                    {dayjs(
                      getIncomeExpenseReportResult.data.report.lowestExpenseDay
                    ).format("DD MMM YYYY")}
                  </StatHelpText>
                </Stat>
              </SimpleGrid>
            </CardBody>
          </Card>
        </SimpleGrid>
      )}
      <Stack width="100%">
        {getIncomeExpenseReportResult.data && (
          <IncomeExpenseBarChartComponent
            getIncomeExpenseReport={getIncomeExpenseReportResult.data}
          />
        )}
      </Stack>
    </>
  );
};
