import {
  Button,
  FormControl,
  FormLabel,
  Spacer,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  useGetCategorizedSublocationsQuery,
  useLazyGetShiftQuantityHistoryQuery
} from "../api";

import { Select } from "chakra-react-select";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";

interface Props {}

export const ShiftWiseQuantityComponent: React.FC<Props> = (props: Props) => {
  const [getQuantityReport, getQuantityReportResult] =
    useLazyGetShiftQuantityHistoryQuery();

  const getCategorizedSublocationsResult = useGetCategorizedSublocationsQuery({
    locationCategoryName: "Counter"
  });
  const [categorizedSublocations, setCategorizedSublocations] = useState<
    { label: string; value: string }[]
  >([]);
  const [categorizedSublocation, setCategorizedSublocation] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (getCategorizedSublocationsResult.data) {
      const sublocations = [
        { label: "All", value: "all" },
        ...getCategorizedSublocationsResult.data.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
      ];
      setCategorizedSublocations(sublocations);
      setCategorizedSublocation(sublocations[0]);
    }
  }, [getCategorizedSublocationsResult.data]);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (categorizedSublocation) {
      getQuantityReport({
        date,
        locationId: categorizedSublocation.value
      });
    }
  }, [date, categorizedSublocation]);

  let headers = [
    { label: "Ward & Investigations", key: "details" },
    { label: "8:00 AM - 2:30 PM", key: "8:00 AM - 2:30 PM" },
    { label: "2:30 PM - 10:00 PM", key: "2:30 PM - 10:00 PM" },
    { label: "10:00 PM - 08:00 AM", key: "10:00 PM - 08:00 AM" },
    { label: "Total", key: "total" }
  ];

  let reportData: any = [];
  getQuantityReportResult.data ? (
    Object.entries(getQuantityReportResult.data.report.shift).map(
      ([key, value]) => {
        let reportObj: any = {
          details: key
        };
        let total = 0;
        Object.entries(value).map(([key, value]) => {
          reportObj[key] = value;
          total = total + value;
        });
        reportObj["total"] = total;
        reportData.push(reportObj);
      }
    )
  ) : (
    <></>
  );

  const dailyQuantityReport = `Shift-Quantity-Report-${dayjs(date).format(
    "DD MMM, YYYY"
  )}.csv`;

  return (
    <Stack spacing="12">
      <Stack direction={{ base: "column", xl: "row" }} minWidth="max-content">
        <Spacer />
        {getQuantityReportResult.data ? (
          <CSVLink
            data={reportData}
            headers={headers}
            filename={dailyQuantityReport}
            className="btn btn-primary"
            target="_blank"
          >
            <Button colorScheme="blue">Export CSV</Button>
          </CSVLink>
        ) : undefined}
      </Stack>

      <PageCardComponent>
        <Stack direction="row">
          <FormControl width="100%">
            <FormLabel>Date</FormLabel>
            <DatePicker
              selected={date}
              onChange={(date) => date && setDate(date)}
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
            />
          </FormControl>

          <FormControl id="location" width="100%">
            <FormLabel>Location</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={categorizedSublocations}
              value={categorizedSublocation}
              onChange={(event) => {
                if (event) {
                  setCategorizedSublocation({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>
      </PageCardComponent>
      <TableContainer>
        <Table variant="simple" size="sm">
          <TableCaption placement="top" pb="6" fontSize={"lg"} textAlign="left">
            Shift Quantity Report ({dayjs(date).format("DD MMM, YYYY")})
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Ward & Investigations</Th>
              {getQuantityReportResult.data &&
              getQuantityReportResult.data.report.shift[
                Object.keys(getQuantityReportResult.data.report.shift)[0]
              ] ? (
                Object.entries(
                  getQuantityReportResult.data.report.shift[
                    Object.keys(getQuantityReportResult.data.report.shift)[0]
                  ]
                ).map(([key, value]) => {
                  return <Th>{key}</Th>;
                })
              ) : (
                <></>
              )}
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getQuantityReportResult.data ? (
              Object.entries(getQuantityReportResult.data.report.shift).map(
                ([key, value]) => {
                  let total = 0;
                  return (
                    <Tr key={key}>
                      <Td
                        maxW="50px"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {key}
                      </Td>
                      {Object.entries(value).map(([key, value]) => {
                        total += value;
                        return <Td>{value}</Td>;
                      })}

                      <Td>{total}</Td>
                    </Tr>
                  );
                }
              )
            ) : (
              <></>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
