import React from "react";
import { Stack } from "@chakra-ui/react";
import { MonthlyLedgerBookComponent } from "../components/monthly-ledger-book.component";

interface Props {}

export const MonthlyLedgerBookPage: React.FC<Props> = (props: Props) => {
  return (
    <Stack>
      <MonthlyLedgerBookComponent />
    </Stack>
  );
};
