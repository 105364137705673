import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import React, { Dispatch, SetStateAction, useMemo } from "react";

import {
  ButtonGroup,
  Center,
  HStack,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";

import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight
} from "react-icons/fa";

interface TableGeneratorProps<ArrayType> {
  data: ArrayType[];
  pageSize: number;
  pageIndex: number;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pageCount: number;
}

export const TableGenerator = <ArrayType,>(
  props: TableGeneratorProps<ArrayType>
) => {
  const columnHelper = createColumnHelper<any>();

  const columns = useMemo<ColumnDef<any, any>[]>(
    () =>
      Object.keys(props.data[0] || {}).map((headerKey) => {
        return columnHelper.accessor(headerKey, {
          id: headerKey,
          header: headerKey,
          cell: (info) => info.row.original[headerKey]
        });
      }),
    [props.data]
  );

  const data = props.data;
  const pagination = React.useMemo(
    () => ({
      pageIndex: props.pageIndex,
      pageSize: props.pageSize
    }),
    [props.pageIndex, props.pageSize]
  );

  const table = useReactTable<any>({
    data,
    columns,
    pageCount: props.pageCount + 1,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination
    },
    onPaginationChange: props.setPagination,
    manualPagination: true,
    filterFns: {
      tableFilter: (rows, id, filterValue) => {
        return true;
      }
    }
  });

  return (
    <Stack>
      <TableContainer minHeight={"48vh"} maxWidth="100%">
        {props.data.length ? (
          <Table variant="simple" size="sm">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      isNumeric={header.column.columnDef.header
                        ?.toString()
                        .includes("Amount")}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      isNumeric={cell.column.columnDef.header
                        ?.toString()
                        .includes("Amount")}
                      isTruncated
                    >
                      {cell.getIsPlaceholder()
                        ? null
                        : flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Center width="100%" height="50vh">
            <Text color="gray.300">NO DATA FOUND</Text>
          </Center>
        )}
      </TableContainer>
      <Center>
        <VStack>
          <HStack pt={8} w={"400"}>
            <Text>{`Page ${props.pageIndex + 1} of ${
              props.pageCount || 1
            }`}</Text>
            <Text>|</Text>
            <Text>Showing</Text>
            <Select
              size={"sm"}
              defaultValue={{
                label: String(props.pageSize),
                value: props.pageSize
              }}
              styles={{
                option: (styles, state) => ({
                  ...styles,
                  width: "256px",
                  minWidth: "192px"
                })
              }}
              menuPlacement={"top"}
              variant={"filled"}
              onChange={(e) => {
                table.setPageSize(e?.value || 10);
              }}
              options={[
                { label: "10", value: 10 },
                { label: "25", value: 25 },
                { label: "50", value: 50 },
                { label: "100", value: 100 }
              ]}
            />
            <Text>Results</Text>
          </HStack>
          <ButtonGroup variant={"outline"} colorScheme="blue" isAttached pt={2}>
            <IconButton
              onClick={() => table.setPageIndex(0)}
              isDisabled={props.pageIndex <= 0}
              aria-label="First Page"
              icon={<FaAngleDoubleLeft />}
            />
            <IconButton
              onClick={() => table.previousPage()}
              isDisabled={props.pageIndex <= 0}
              aria-label="Previous Page"
              icon={<FaAngleLeft />}
            />
            <IconButton
              onClick={() => table.nextPage()}
              isDisabled={props.pageIndex >= props.pageCount - 1}
              aria-label="Next Page"
              icon={<FaAngleRight />}
            />
            <IconButton
              onClick={() => table.setPageIndex(props.pageCount - 1)}
              isDisabled={props.pageIndex >= props.pageCount - 1}
              aria-label="Last Page"
              icon={<FaAngleDoubleRight />}
            />
          </ButtonGroup>
        </VStack>
      </Center>
    </Stack>
  );
};
