import { Stack } from "@chakra-ui/react";
import React from "react";
import { MonthlyVoucherListComponent } from "../components/monthly-voucher-list.component";

interface ExpenseVoucherHistoryPageProps {}

export const ExpenseVoucherHistoryPage: React.FC<
  ExpenseVoucherHistoryPageProps
> = (props: ExpenseVoucherHistoryPageProps) => {
  return (
    <Stack>
      <MonthlyVoucherListComponent type="Coupon Expense" />
    </Stack>
  );
};
