import {
  Button,
  Center,
  Spinner,
  Stack,
  Stat,
  StatLabel,
  StatNumber
} from "@chakra-ui/react";
import { PaginationState } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { TableGenerator } from "modules/core/components/table-generator.component";
import { useNavigate } from "react-router-dom";
import { useLazyGetDailyVoucherListQuery } from "../api";

interface PaymentVoucherTableComponentProps {
  location: string;
  date: Date;
}

export const PaymentVoucherTableComponent: React.FC<
  PaymentVoucherTableComponentProps
> = (props: PaymentVoucherTableComponentProps) => {
  const navigate = useNavigate();

  const [getVoucherList, getVoucherListResult] =
    useLazyGetDailyVoucherListQuery();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  useEffect(() => {
    getVoucherList({
      type: "Payment Voucher",
      date: props.date.toISOString(),
      locationId: props.location,
      pageIndex: pageIndex,
      pageSize: pageSize
    });
  }, [props.date, props.location, pageSize, pageIndex]);

  let tableData: {}[] = [];

  if (getVoucherListResult.data) {
    tableData = getVoucherListResult.data.vouchersWithDetails.map(
      (voucherWithDetails, index) => {
        return {
          "Sl.": pageIndex * pageSize + index + 1,
          Date: dayjs(voucherWithDetails.voucher.createdAt).format(
            "hh:mm A, MMM DD, YYYY"
          ),
          "Transaction ID": voucherWithDetails.voucher.id,
          "Total Amount": `৳ ${voucherWithDetails.voucherRecords
            .reduce(
              (total, voucherRecord) =>
                total + voucherRecord.quantity * voucherRecord.basePrice,
              0
            )
            .toFixed(2)}`,
          "Paid Amount": `৳ ${voucherWithDetails.voucher.paidAmount.toFixed(
            2
          )}`,
          "Discount Amount": `৳ ${(
            voucherWithDetails.voucherRecords.reduce(
              (total, voucherRecord) =>
                total + voucherRecord.quantity * voucherRecord.basePrice,
              0
            ) - voucherWithDetails.voucher.paidAmount
          ).toFixed(2)}`,
          "Patient Name": voucherWithDetails.person.name,
          Action: (
            <Button
              size={"sm"}
              colorScheme="blue"
              onClick={() =>
                navigate(
                  `/${
                    window.localStorage.getItem("locationCategory") as string
                  }/voucher?voucherId=${voucherWithDetails.voucher.id}`
                )
              }
            >
              Details
            </Button>
          )
        };
      }
    );
  }

  return (
    <Stack spacing={6}>
      {getVoucherListResult.data ? (
        <Stack spacing={6}>
          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            {Object.entries(getVoucherListResult.data.statistics).map(
              ([key, value]) => (
                <PageCardComponent>
                  <Stat>
                    <StatLabel>{key}</StatLabel>
                    <StatNumber>
                      {key.toLowerCase().includes("amount") ? "৳ " : ""}
                      {value}
                    </StatNumber>
                  </Stat>
                </PageCardComponent>
              )
            )}
          </Stack>
          <TableGenerator
            data={tableData}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPagination={setPagination}
            pageCount={getVoucherListResult.data.pagination.pageCount}
          />
        </Stack>
      ) : (
        <Center width="100%" height={"50vh"}>
          <Spinner />
        </Center>
      )}
    </Stack>
  );
};
