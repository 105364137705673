import React from "react";
import { ShiftWiseIncomeComponent } from "../components/shift-wise-income.component";

interface BillingCounterDashboardPageProps {}

export const BillingCounterDashboardPage: React.FC<
  BillingCounterDashboardPageProps
> = (props: BillingCounterDashboardPageProps) => {
  return (
    <>
      <ShiftWiseIncomeComponent />
    </>
  );
};
