import React from "react";
import { Stack } from "@chakra-ui/react";
import { MonthlyQuantityReportComponent } from "../components/monthly-quantity-report.component";

interface MonthlyQuantityReportPageProps {}

export const MonthlyQuantityReportPage: React.FC<
  MonthlyQuantityReportPageProps
> = (props: MonthlyQuantityReportPageProps) => {
  return (
    <Stack>
      <MonthlyQuantityReportComponent />
    </Stack>
  );
};
