import React from "react";
import { Stack } from "@chakra-ui/react";
import { CashbookComponent } from "../components/cashbook.component";

interface CashbookPageProps {}

export const CashbookPage: React.FC<CashbookPageProps> = (
  props: CashbookPageProps
) => {
  return (
    <Stack>
      <CashbookComponent />
    </Stack>
  );
};
