import React from "react";
import { Stack } from "@chakra-ui/react";
import { MonthlyIncomeReportComponent } from "../components/monthly-income-report.component";

interface Props {}

export const MonthlyIncomeReportPage: React.FC<Props> = (props: Props) => {
  return (
    <Stack>
      <MonthlyIncomeReportComponent />
    </Stack>
  );
};
