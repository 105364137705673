import { Button, useDisclosure } from "@chakra-ui/react";
import dayjs from "dayjs";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";
import React from "react";
import { GetDailyCashCollectionReportResponse } from "../api/type";
import { PrintDailyCashCollectionComponent } from "./print-daily-cash-collection.component";

interface PrintDailyCashCollectionReportComponentProps {
  cashCollectionReport: GetDailyCashCollectionReportResponse;
  date: Date;
}

export const PrintDailyCashCollectionReportComponent: React.FC<
  PrintDailyCashCollectionReportComponentProps
> = (props: PrintDailyCashCollectionReportComponentProps) => {
  const cashCollectionReport = `Daily-Cash-Collection-Report-${dayjs(
    props.date
  ).format("DD-MMM-YYYY")}.pdf`;

  const Document = (
    <PrintDailyCashCollectionComponent
      cashCollection={props.cashCollectionReport}
      date={props.date}
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={cashCollectionReport}
      />
    </>
  );
};
