import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import { env } from "app/config";
import dayjs from "dayjs";
import React from "react";
import { GetDailyCashCollectionReportResponse } from "../api/type";

interface PrintDailyCashCollectionComponentProps {
  cashCollection: GetDailyCashCollectionReportResponse;
  date: Date;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24,
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    textAlign: "center"
  },
  title: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 4,
    textAlign: "center"
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 4,
    textAlign: "center"
  },
  table: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#dfdfdf",
    border: "0.5px solid #dfdfdf",
    fontWeight: "bold"
  },
  tableFooter: {
    borderBottom: "1px solid #dfdfdf",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    fontSize: 9
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    border: "0.5px solid #dfdfdf"
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    border: "0.5px solid #efefef"
  },
  startShift: {
    width: "17%",
    padding: "1px 5px"
  },
  endShift: {
    width: "17%",
    padding: "1px"
  },
  voucher: {
    width: "40%",
    padding: "1px"
  },
  quantity: {
    width: "15%",
    padding: "1px"
  },
  income: {
    width: "11%",
    padding: "1px"
  }
});

export const PrintDailyCashCollectionComponent: React.FC<
  PrintDailyCashCollectionComponentProps
> = (props: PrintDailyCashCollectionComponentProps) => {
  const dailyTotalCashCollection = props.cashCollection.report;

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <Image style={styles.logo} src={env.governmentLogo} />
              <View style={{ paddingHorizontal: "25px" }}>
                <View>
                  <Text style={{ fontSize: 11, alignSelf: "center" }}>
                    {env.voucherTitle}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 9, alignSelf: "center" }}>
                    {env.hospitalName}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.hospitalLogo} />
            </View>
            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>Daily Cash Collection Report</Text>
              <Text style={styles.subTitle}>
                {dayjs(props.date).format("DD MMM, YYYY")}
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              ></View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.startShift}>Shift Start</Text>
                <Text style={styles.endShift}>Shift End</Text>
                <Text style={styles.voucher}>Vouchers</Text>
                <Text style={styles.quantity}>Quantity</Text>
                <Text style={styles.income}>Income</Text>
              </View>
              {props.cashCollection.report.map((reportEntry, index) => (
                <View style={styles.itemRow} key={index} wrap={false}>
                  <Text style={styles.startShift}>
                    {dayjs(reportEntry.shiftStart).format("h:mm A")}
                  </Text>
                  <Text style={styles.endShift}>
                    {dayjs(reportEntry.shiftEnd).format("h:mm A")}
                  </Text>
                  <Text style={styles.voucher}>
                    {reportEntry.voucherStart} - {reportEntry.voucherEnd}
                  </Text>
                  <Text style={styles.quantity}>
                    {reportEntry.voucherQuantity}
                  </Text>
                  <Text style={styles.income}>৳ {reportEntry.income}</Text>
                </View>
              ))}
            </View>
            <View style={[styles.tableFooter]} fixed>
              <Text style={styles.startShift}>Total :</Text>
              <Text style={styles.endShift}></Text>
              <Text style={styles.voucher}></Text>
              <Text style={styles.quantity}>
                {dailyTotalCashCollection.reduce(
                  (totalQuantity, reportEntry) =>
                    totalQuantity + reportEntry.voucherQuantity,
                  0
                )}
              </Text>
              <Text style={styles.income}>
                ৳{" "}
                {dailyTotalCashCollection.reduce(
                  (totalPrice, reportEntry) => totalPrice + reportEntry.income,
                  0
                )}
              </Text>
            </View>
          </View>
          <View>
            <View
              style={{
                borderTop: "1px solid #000"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Text style={{ fontSize: 8, color: "#666666" }}>
                  Powered By: BinduLogic Limited
                </Text>
                <Text style={{ fontSize: 8, color: "#666666" }}>
                  Printed By:{" "}
                  {window.localStorage.getItem("locationName") || "Unknown"},{" "}
                  {dayjs().format("DD MMM YYYY, hh:mm A")}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
