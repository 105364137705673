import {
  Center,
  FormControl,
  FormLabel,
  Spacer,
  Spinner,
  Stack,
  TableContainer
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Select } from "chakra-react-select";
import { PageCardComponent } from "modules/core/components/page-card.component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useGetCategorizedSublocationsQuery } from "../api";
import { PrintPaymentVoucherHistoryComponent } from "../print/components/print-payment-voucher-history.component";
import "../styles/chakra-react-datepicker.css";
import { PaymentVoucherTableComponent } from "./payment-voucher-table.component";

interface Props {
  type: string;
}

export const DailyVoucherListComponent: React.FC<Props> = (props: Props) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const getCategorizedSublocationsResult = useGetCategorizedSublocationsQuery({
    locationCategoryName: "Counter"
  });
  const [categorizedSublocations, setCategorizedSublocations] = useState<
    { label: string; value: string }[]
  >([]);

  const [categorizedSublocation, setCategorizedSublocation] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (getCategorizedSublocationsResult.data) {
      const sublocations = [
        { label: "All", value: "all" },
        ...getCategorizedSublocationsResult.data.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
      ];
      setCategorizedSublocations(sublocations);
      setCategorizedSublocation(sublocations[0]);
    }
  }, [getCategorizedSublocationsResult.data]);

  return (
    <Stack spacing="6">
      <PageCardComponent>
        <Stack direction="row">
          <FormControl id="date" width="100%">
            <FormLabel>Date</FormLabel>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => date && setSelectedDate(date)}
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
            />
          </FormControl>

          <FormControl id="location" width="100%">
            <FormLabel>Location</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={categorizedSublocations}
              value={categorizedSublocation}
              onChange={(event) => {
                if (event) {
                  setCategorizedSublocation({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>
      </PageCardComponent>

      <PageCardComponent>
        {categorizedSublocation ? (
          <TableContainer>
            <Stack
              direction={{ base: "column", xl: "row" }}
              minWidth="max-content"
            >
              <Spacer />

              {props.type === "Payment Voucher" && (
                <PrintPaymentVoucherHistoryComponent
                  date={selectedDate}
                  location={categorizedSublocation.value}
                />
              )}
            </Stack>
            {props.type === "Payment Voucher" && (
              <PaymentVoucherTableComponent
                location={categorizedSublocation.value}
                date={selectedDate}
              />
            )}
          </TableContainer>
        ) : (
          <Center width="100%" height="50vh">
            <Spinner />
          </Center>
        )}
      </PageCardComponent>
    </Stack>
  );
};
