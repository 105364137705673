import React from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { VoucherComponent } from "../components/voucher.component";
import { useSearchParams } from "react-router-dom";

interface VoucherDetailsPageProps {}

export const VoucherDetailsPage: React.FC<VoucherDetailsPageProps> = (
  props: VoucherDetailsPageProps
) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const voucherId = searchParams.get("voucherId");

  if (voucherId) {
    return <VoucherComponent voucherId={voucherId} />;
  } else {
    return (
      <Center width="100%" height="10vh">
        <Text>Invalid Voucher ID</Text>
      </Center>
    );
  }
};
