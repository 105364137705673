import React from "react";

import {
  Expense,
  Investigation,
  Patient,
  Person,
  User,
  Voucher,
  VoucherRecord
} from "app/api/type";

import { PrintBankExpenseVoucherComponent } from "./print-bank-expense-voucher.component";
import { PrintExpenseVoucherComponent } from "./print-expense-voucher.component";
import { PrintPaymentVoucherComponent } from "./print-payment-voucher.component";
import { PrintTransferVoucherComponent } from "./print-transfer-voucher.component";

interface PrintVoucherComponentProps {
  voucherDetails: {
    patient: Patient | null;
    person: Person | null;
    voucher: Voucher;
    entries: {
      voucherRecord: VoucherRecord;
      investigation: Investigation | null;
      expense: Expense | null;
    }[];
  };
  printerDetails: {
    user: Omit<User, "password">;
    person: Person;
  };
}

export const PrintVoucherComponent: React.FC<PrintVoucherComponentProps> = (
  props: PrintVoucherComponentProps
) => {
  if (props.voucherDetails.voucher.voucherTypeName === "Payment Voucher")
    return (
      <PrintPaymentVoucherComponent
        voucherDetails={props.voucherDetails}
        printerDetails={props.printerDetails}
      />
    );
  else if (props.voucherDetails.voucher.voucherTypeName === "Coupon Expense")
    return (
      <PrintExpenseVoucherComponent
        voucherDetails={props.voucherDetails}
        printerDetails={props.printerDetails}
      />
    );
  else if (props.voucherDetails.voucher.voucherTypeName === "Bank Expense")
    return (
      <PrintBankExpenseVoucherComponent
        voucherDetails={props.voucherDetails}
        printerDetails={props.printerDetails}
      />
    );
  else
    return (
      <PrintTransferVoucherComponent
        voucherDetails={props.voucherDetails}
        printerDetails={props.printerDetails}
      />
    );
};
