import { api } from "app/api";
import * as ApiTypes from "./type";

const counterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPatient: builder.mutation<
      ApiTypes.CreatePatientResponse,
      ApiTypes.CreatePatientRequest
    >({
      query: ({ data }) => ({
        url: `/patient/create`,
        method: `POST`,
        body: { data }
      })
    }),

    searchPatient: builder.query<
      ApiTypes.SearchPatientResponse,
      ApiTypes.SearchPatientRequest
    >({
      query: ({ contactNumber }) => ({
        url: `/patient/search?contactNumber=${contactNumber}`,
        method: `GET`
      })
    }),

    getWardTypes: builder.query<
      ApiTypes.GetWardTypesResponse,
      ApiTypes.GetWardTypesRequest
    >({
      query: () => ({
        url: `/ward-type/list`,
        method: `GET`
      })
    }),

    createVoucher: builder.mutation<
      ApiTypes.CreatePaymentVoucherResponse,
      ApiTypes.CreatePaymentVoucherRequest
    >({
      query: ({ data }) => ({
        url: `/voucher/payment/create`,
        method: `POST`,
        body: { data }
      })
    }),

    createExpenseVoucher: builder.mutation<
      ApiTypes.CreateExpenseVoucherResponse,
      ApiTypes.CreateExpenseVoucherRequest
    >({
      query: ({ data }) => ({
        url: `/voucher/expense/create`,
        method: `POST`,
        body: { data }
      }),
      invalidatesTags: ["Account"]
    }),

    createTransferVoucher: builder.mutation<
      ApiTypes.CreateTransferVoucherResponse,
      ApiTypes.CreateTransferVoucherRequest
    >({
      query: ({ data }) => ({
        url: `/voucher/transfer/create`,
        method: `POST`,
        body: { data }
      }),
      invalidatesTags: ["Account"]
    }),

    getInvestigations: builder.query<
      ApiTypes.GetInvestigationsResponse,
      ApiTypes.GetInvestigationsRequest
    >({
      query: () => ({
        url: `/investigation/list`,
        method: `GET`
      })
    }),

    getVoucherDetails: builder.query<
      ApiTypes.GetVoucherDetailsResponse,
      ApiTypes.GetVoucherDetailsRequest
    >({
      query: ({ voucherId }) => ({
        url: `/voucher/get`,
        method: `GET`,
        params: { voucherId }
      })
    }),

    updateVoucherPayment: builder.mutation<
      ApiTypes.UpdateVoucherPaymentResponse,
      ApiTypes.UpdateVoucherPaymentRequest
    >({
      query: ({ data }) => ({
        url: `/voucher/payment/update`,
        method: `POST`,
        body: { data }
      })
    }),

    getMonthlyVoucherList: builder.query<
      ApiTypes.GetDailyVoucherListResponse,
      ApiTypes.GetMonthlyVoucherListRequest
    >({
      query: ({ type, month, year, pageSize, pageIndex, locationId }) => ({
        url: `/voucher/list/monthly/get`,
        method: `GET`,
        params: { type, month, year, pageSize, pageIndex, locationId }
      })
    }),

    getDailyVoucherList: builder.query<
      ApiTypes.GetDailyVoucherListResponse,
      ApiTypes.GetDailyVoucherListRequest
    >({
      query: ({ type, date, pageSize, pageIndex, locationId }) => ({
        url: `/voucher/list/daily/get`,
        method: `GET`,
        params: { type, date, pageSize, pageIndex, locationId }
      })
    }),

    getTransactionHistory: builder.query<
      ApiTypes.GetTransactionHistoryResponse,
      ApiTypes.GetTransactionHistoryRequest
    >({
      query: () => ({
        url: `/transaction/history/get`,
        method: `GET`
      })
    }),

    getMonthlyQuantityHistory: builder.query<
      ApiTypes.GetQuantityReportResponse,
      ApiTypes.GetQuantityReportRequest
    >({
      query: ({ month, year, locationId }) => ({
        url: `/voucher/quantity/day/report/get`,
        method: `GET`,
        params: { month, year, locationId }
      })
    }),

    getShiftQuantityHistory: builder.query<
      ApiTypes.GetShiftBasedQuantityReportResponse,
      ApiTypes.GetShiftBasedQuantityReportRequest
    >({
      query: ({ date, locationId }) => ({
        url: `/voucher/quantity/shift/report/get`,
        method: `GET`,
        params: { date, locationId }
      })
    }),
    getDailyCashCollectionReport: builder.query<
      ApiTypes.GetDailyCashCollectionReportResponse,
      ApiTypes.GetDailyCashCollectionReportRequest
    >({
      query: ({ date, locationId }) => ({
        url: `/voucher/cash/daily/report/get`,
        method: `GET`,
        params: { date, locationId }
      })
    }),

    getExpenses: builder.query<
      ApiTypes.GetExpensesResponse,
      ApiTypes.GetExpensesRequest
    >({
      query: ({ sourceAccountName }) => ({
        url: `/expenses/get`,
        method: `GET`,
        params: { sourceAccountName }
      })
    }),

    getUser: builder.query<ApiTypes.GetUserResponse, ApiTypes.GetUserRequest>({
      query: () => ({
        url: `/user/get`,
        method: `GET`
      })
    }),

    getAccount: builder.query<
      ApiTypes.GetAccountResponse,
      ApiTypes.GetAccountRequest
    >({
      query: ({ name }) => ({
        url: `/account/get`,
        method: `GET`,
        params: { name }
      }),
      providesTags: (result, error, arg) =>
        result ? [{ type: "Account", name: arg?.name }] : ["Account"]
    }),

    getVoucherRangeReport: builder.query<
      ApiTypes.GetVoucherRangeReportResponse,
      ApiTypes.GetVoucherRangeReportRequest
    >({
      query: ({ month, year }) => ({
        url: `/voucher/range/report/get`,
        method: `GET`,
        params: { month, year }
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              {
                type: "Voucher Range Report",
                month: arg?.month,
                year: arg?.year
              }
            ]
          : ["Voucher Range Report"]
    }),

    getMonthlyIncomeReport: builder.query<
      ApiTypes.GetMonthlyIncomeReportResponse,
      ApiTypes.GetMonthlyIncomeReportRequest
    >({
      query: ({ month, year, locationId }) => ({
        url: `/voucher/monthly-income-report/get`,
        method: `GET`,
        params: { month, year, locationId }
      })
    }),

    getIncomeExpenseReport: builder.query<
      ApiTypes.GetIncomeExpenseReportResponse,
      ApiTypes.GetIncomeExpenseReportRequest
    >({
      query: () => ({
        url: `/voucher/income-expense-insight/get`,
        method: `GET`
      })
    }),

    getCashbook: builder.query<
      ApiTypes.GetCashbookResponse,
      ApiTypes.GetCashbookRequest
    >({
      query: (params) => ({
        url: `/cashbook/get`,
        method: `GET`,
        params
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              {
                type: "Cashbook",
                month: arg?.month,
                year: arg?.year
              }
            ]
          : ["Cashbook"]
    }),

    getLedgerBook: builder.query<
      ApiTypes.GetLedgerBookResponse,
      ApiTypes.GetLedgerBookRequest
    >({
      query: (params) => ({
        url: `/voucher/monthly-ledger-book/get`,
        method: `GET`,
        params
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              {
                type: "Ledger Book",
                month: arg?.month,
                year: arg?.year
              }
            ]
          : ["Ledger Book"]
    }),

    getCategorizedSublocations: builder.query<
      ApiTypes.GetCategorizedSublocationsResponse,
      ApiTypes.GetCategorizedSublocationsRequest
    >({
      query: ({ locationCategoryName }) => ({
        url: `/location/sublocations/categorized/get?locationCategoryName=${locationCategoryName}`,
        method: "GET"
      })
    }),

    getDoctors: builder.query<
      ApiTypes.GetDoctorsResponse,
      ApiTypes.GetDoctorsRequest
    >({
      query: () => ({
        url: `/doctor/get`,
        method: `GET`
      })
    })
  }),

  overrideExisting: false
});

export const {
  useLazySearchPatientQuery,
  useGetWardTypesQuery,
  useCreatePatientMutation,
  useCreateVoucherMutation,
  useGetInvestigationsQuery,
  useGetVoucherDetailsQuery,
  useUpdateVoucherPaymentMutation,
  useLazyGetMonthlyVoucherListQuery,
  useLazyGetDailyVoucherListQuery,
  useGetTransactionHistoryQuery,
  useLazyGetMonthlyQuantityHistoryQuery,
  useLazyGetIncomeExpenseReportQuery,
  useLazyGetShiftQuantityHistoryQuery,
  useGetExpensesQuery,
  useCreateExpenseVoucherMutation,
  useGetUserQuery,
  useLazyGetDailyCashCollectionReportQuery,
  useGetAccountQuery,
  useCreateTransferVoucherMutation,
  useLazyGetVoucherRangeReportQuery,
  useLazyGetCashbookQuery,
  useLazyGetMonthlyIncomeReportQuery,
  useLazyGetLedgerBookQuery,
  useGetDoctorsQuery,
  useGetCategorizedSublocationsQuery
} = counterApi;
