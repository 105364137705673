import { Stack } from "@chakra-ui/react";
import React from "react";
import { DailyVoucherListComponent } from "../components/daily-voucher-list.component";

interface PaymentVoucherHistoryPageProps {}

export const PaymentVoucherHistoryPage: React.FC<
  PaymentVoucherHistoryPageProps
> = (props: PaymentVoucherHistoryPageProps) => {
  return (
    <Stack>
      <DailyVoucherListComponent type="Payment Voucher" />
    </Stack>
  );
};
