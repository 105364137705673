import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import {
  Expense,
  Investigation,
  Patient,
  Person,
  User,
  Voucher,
  VoucherRecord
} from "app/api/type";
import { env } from "app/config";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import JsBarcode from "jsbarcode";
import React, { useEffect, useState } from "react";
import { toWords } from "utils/currency-to-words";
import { getAge } from "utils/date/get-age.utils";

interface PrintPaymentVoucherComponentProps {
  voucherDetails: {
    patient: Patient | null;
    person: Person | null;
    voucher: Voucher;
    entries: {
      voucherRecord: VoucherRecord;
      investigation: Investigation | null;
      expense: Expense | null;
    }[];
  };
  printerDetails: {
    user: Omit<User, "password">;
    person: Person;
  };
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "48px",
    objectFit: "contain",
    textAlign: "center"
  },
  barcode: {
    width: "96px",
    objectFit: "contain",
    alignSelf: "center"
  },

  title: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 4,
    textAlign: "center"
  },
  subTitle: {
    fontSize: 11,
    marginTop: 50,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center"
  },
  header: {
    fontSize: 10,
    top: 20,
    left: 50,
    right: 50,
    display: "flex",
    flexDirection: "row"
  },
  headerLeft: {
    width: "50%",
    left: 0,
    paddingTop: 5,
    paddingBottom: 5
  },
  headerRight: {
    right: 0,
    width: "50%",
    paddingTop: 5,
    paddingBottom: 5
  },
  rightSide: {
    marginLeft: "auto"
  },
  details: {
    fontSize: 8
  },
  table: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#dfdfdf",
    border: "0.5px solid #dfdfdf",
    fontWeight: "bold"
  },
  tableFooter: {
    borderBottom: "1px solid #dfdfdf",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    fontSize: 8
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 8,
    border: "0.5px solid #dfdfdf"
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 8,
    border: "0.5px solid #efefef"
  },
  serial: {
    width: "5%",
    textAlign: "left",
    padding: "1px"
  },
  itemName: {
    width: env.discountOptions ? "40%" : "50%",
    textAlign: "center",
    padding: "1px"
  },
  price: {
    width: env.discountOptions ? "10%" : "15%",
    textAlign: "right",
    padding: "1px"
  },
  discountPercentage: {
    width: "10%",
    textAlign: "right",
    padding: "1px"
  },
  discountAmount: {
    width: "15%",
    textAlign: "right",
    padding: "1px"
  },
  quantity: {
    width: env.discountOptions ? "10%" : "15%",
    textAlign: "center",
    padding: "1px"
  },
  totalPrice: {
    width: env.discountOptions ? "10%" : "15%",
    textAlign: "right",
    padding: "1px"
  }
});

export const PrintPaymentVoucherComponent: React.FC<
  PrintPaymentVoucherComponentProps
> = (props: PrintPaymentVoucherComponentProps) => {
  const [barcode, setBarcode] = useState<string>();

  useEffect(() => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, props.voucherDetails.voucher.id);
    setBarcode(canvas.toDataURL());
  }, []);

  return (
    <Document>
      <Page size="A5" style={styles.body}>
        <View
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Image style={styles.logo} src={env.governmentLogo} />
              <View>
                <View>
                  <Text style={{ fontSize: 11, alignSelf: "center" }}>
                    {env.voucherTitle}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 9, alignSelf: "center" }}>
                    {env.hospitalName}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.hospitalLogo} />
            </View>
            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>
                {props.voucherDetails.voucher.voucherTypeName.toUpperCase()}
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              >
                <View>
                  <Text style={styles.details}>
                    Voucher ID: {props.voucherDetails.voucher.id}
                  </Text>

                  <Text style={styles.details}>
                    Date:{" "}
                    {dayjs(props.voucherDetails.voucher.createdAt).format(
                      "DD MMM YYYY"
                    )}
                  </Text>

                  <Text style={styles.details}>
                    Ward Type:{" "}
                    {props.voucherDetails.voucher.wardTypeName || "Unknown"}
                  </Text>

                  {env.deploymentType === "lab-billing" && (
                    <Text style={styles.details}>
                      Ward Number:{" "}
                      {props.voucherDetails.voucher.wardNumber || "Unknown"}
                    </Text>
                  )}
                  {env.deploymentType === "lab-billing" && (
                    <Text style={styles.details}>
                      Bed Number:{" "}
                      {props.voucherDetails.voucher.bedNumber || "Unknown"}
                    </Text>
                  )}
                  {env.deploymentType === "lab-billing" && (
                    <Text style={styles.details}>
                      Patient Reg. No:{" "}
                      {props.voucherDetails.patient
                        ?.patientRegistrationNumber || "Unknown"}
                    </Text>
                  )}
                </View>

                <View>
                  {props.voucherDetails.voucher.voucherTypeName ===
                    "Payment Voucher" && (
                    <View>
                      <Text style={styles.details}>
                        Patient ID: {props.voucherDetails.voucher.patientId}
                      </Text>
                      <Text style={styles.details}>
                        Name:{" "}
                        {props.voucherDetails.person?.name?.toUpperCase() ||
                          "Unknown"}
                      </Text>
                      <Text style={styles.details}>
                        Contact:{" "}
                        {props.voucherDetails.person?.contactNumber ||
                          "Unknown"}
                      </Text>
                      <Text style={styles.details}>
                        Age:{" "}
                        {props.voucherDetails.person &&
                        props.voucherDetails.person.dateOfBirth
                          ? getAge(props.voucherDetails.person.dateOfBirth)
                          : "Unknown"}
                      </Text>{" "}
                      <Text style={styles.details}>
                        Gender:{" "}
                        {props.voucherDetails.person?.gender?.toUpperCase() ||
                          "Unknown"}
                      </Text>
                    </View>
                  )}
                </View>
                <Image style={styles.barcode} src={barcode} />
              </View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.serial}>Sl.</Text>
                <Text style={styles.itemName}>Item</Text>
                <Text style={styles.price}>Price</Text>
                <Text style={styles.quantity}>Qty.</Text>
                {env.discountOptions && (
                  <Text style={styles.discountPercentage}>Disc. %</Text>
                )}
                {env.discountOptions && (
                  <Text style={styles.discountAmount}>Disc.</Text>
                )}
                <Text style={styles.totalPrice}>Total</Text>
              </View>
              {props.voucherDetails.entries.map((voucherEntry, index) => (
                <View
                  key={voucherEntry.voucherRecord.id}
                  style={styles.itemRow}
                  wrap={false}
                >
                  <Text style={styles.serial}>{index + 1}</Text>
                  <Text style={styles.itemName}>
                    {voucherEntry.investigation?.name}
                  </Text>
                  <Text style={styles.price}>
                    ৳ {voucherEntry.voucherRecord.basePrice}
                  </Text>
                  <Text style={styles.quantity}>
                    {voucherEntry.voucherRecord.quantity}
                  </Text>
                  {env.discountOptions && (
                    <Text style={styles.discountPercentage}>
                      {voucherEntry.voucherRecord.discountPercentage} %
                    </Text>
                  )}
                  {env.discountOptions && (
                    <Text style={styles.discountAmount}>
                      ৳{" "}
                      {new Decimal(voucherEntry.voucherRecord.basePrice)
                        .mul(voucherEntry.voucherRecord.quantity)
                        .mul(
                          new Decimal(
                            voucherEntry.voucherRecord.discountPercentage
                          ).div(100)
                        )
                        .toNumber()}
                    </Text>
                  )}
                  <Text style={styles.totalPrice}>
                    ৳ {voucherEntry.voucherRecord.totalPrice}
                  </Text>
                </View>
              ))}
            </View>
            <View style={[styles.tableFooter, { borderBottom: "none" }]} fixed>
              <Text style={styles.serial}></Text>
              <Text style={styles.itemName}>Grand Total</Text>
              <Text style={styles.price}></Text>
              <Text style={styles.quantity}></Text>
              {env.discountOptions && (
                <Text style={styles.discountPercentage}></Text>
              )}
              {env.discountOptions && (
                <Text style={styles.discountAmount}>
                  ৳{" "}
                  {props.voucherDetails.entries.reduce(
                    (totalDiscount, voucherEntry) =>
                      totalDiscount +
                      new Decimal(voucherEntry.voucherRecord.basePrice)
                        .mul(voucherEntry.voucherRecord.quantity)
                        .mul(
                          new Decimal(
                            voucherEntry.voucherRecord.discountPercentage
                          ).div(100)
                        )
                        .toNumber(),
                    0
                  )}
                </Text>
              )}
              <Text style={styles.totalPrice}>
                ৳{" "}
                {props.voucherDetails.entries.reduce(
                  (totalPrice, voucherEntry) =>
                    totalPrice + voucherEntry.voucherRecord.totalPrice,
                  0
                )}
              </Text>
            </View>
            <View style={[styles.tableFooter]} fixed>
              <Text style={styles.serial}></Text>
              <Text style={styles.itemName}>Paid</Text>
              <Text style={styles.price}></Text>
              <Text style={styles.quantity}></Text>
              {env.discountOptions && (
                <Text style={styles.discountPercentage}></Text>
              )}
              {env.discountOptions && (
                <Text style={styles.discountAmount}></Text>
              )}
              <Text style={styles.totalPrice}>
                ৳ {props.voucherDetails.voucher.paidAmount}
              </Text>
            </View>{" "}
            <Text style={{ fontSize: 8, paddingVertical: 8 }}>
              In words,{" "}
              {toWords.convert(props.voucherDetails.voucher.paidAmount)}.
            </Text>
          </View>
          <View>
            {props.voucherDetails.voucher.note ? (
              <View style={{ paddingBottom: 8 }}>
                <Text
                  style={{
                    fontSize: 8,
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  Notes:
                </Text>
                <Text style={{ fontSize: 8 }}>
                  {props.voucherDetails.voucher.note}
                </Text>
              </View>
            ) : (
              <></>
            )}
            <View style={{ borderTop: "1px solid #000" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Text style={{ fontSize: 8, color: "#666666" }}>
                  Powered By: BinduLogic Limited
                </Text>
                <Text style={{ fontSize: 8, color: "#666666" }}>
                  Printed By: {props.printerDetails.person.name} (
                  {dayjs().format("DD MMM YYYY, hh:mm A")})
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
