import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";

export const getAge = (date: Date) => {
  dayjs.extend(duration);
  const ageInYears = dayjs().diff(dayjs(date), "years", true);

  const age = dayjs.duration(ageInYears, "years").format("YY[Y] MM[M] DD[D]");

  return age;
};
