import {
  Center,
  FormControl,
  FormLabel,
  Spacer,
  Spinner,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect, useState } from "react";
import { yearList, monthList } from "utils/date";
import {
  useGetCategorizedSublocationsQuery,
  useLazyGetMonthlyIncomeReportQuery
} from "../api";
import { PrintMonthlyIncomeReportComponent } from "../print/components/print-monthly-income-report.component";

interface MonthlyIncomeReportComponentProps {}

export const MonthlyIncomeReportComponent: React.FC<
  MonthlyIncomeReportComponentProps
> = (props: MonthlyIncomeReportComponentProps) => {
  const [getMonthlyIncomeReport, getMonthlyIncomeReportResult] =
    useLazyGetMonthlyIncomeReportQuery({});

  const getCategorizedSublocationsResult = useGetCategorizedSublocationsQuery({
    locationCategoryName: "Counter"
  });
  const [categorizedSublocations, setCategorizedSublocations] = useState<
    { label: string; value: string }[]
  >([]);
  const [categorizedSublocation, setCategorizedSublocation] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (getCategorizedSublocationsResult.data) {
      const sublocations = [
        { label: "All", value: "all" },
        ...getCategorizedSublocationsResult.data.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
      ];
      setCategorizedSublocations(sublocations);
      setCategorizedSublocation(sublocations[0]);
    }
  }, [getCategorizedSublocationsResult.data]);

  const [selectedYear, setSelectedYear] = useState<{
    label: string;
    value: number;
  }>({
    label: dayjs().year().toString(),
    value: dayjs().year()
  });

  const [selectedMonth, setSelectedMonth] = useState<{
    label: string;
    value: number;
  }>(monthList[dayjs().month()]);

  useEffect(() => {
    if (categorizedSublocation) {
      getMonthlyIncomeReport({
        month: selectedMonth.value,
        year: selectedYear.value,
        locationId: categorizedSublocation.value
      });
    }
  }, [selectedMonth, selectedYear, categorizedSublocation]);

  return (
    <Stack spacing="2">
      <PageCardComponent>
        <Stack direction="row">
          <FormControl id="year">
            <FormLabel>Year</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={yearList}
              value={selectedYear}
              onChange={(event) => {
                if (event) {
                  setSelectedYear({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>

          <FormControl id="month">
            <FormLabel>Month</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={monthList}
              value={selectedMonth}
              onChange={(event) => {
                if (event) {
                  setSelectedMonth({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>

          <FormControl id="location">
            <FormLabel>Location</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={categorizedSublocations}
              value={categorizedSublocation}
              onChange={(event) => {
                if (event) {
                  setCategorizedSublocation({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>
      </PageCardComponent>

      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        my="2"
      >
        <PageCardComponent>
          <Stat>
            <StatLabel>Starting Voucher ID</StatLabel>
            <StatNumber>
              {
                getMonthlyIncomeReportResult.data?.report.monthlyData
                  .voucherStart
              }
            </StatNumber>
          </Stat>
        </PageCardComponent>
        <PageCardComponent>
          <Stat>
            <StatLabel>Ending Voucher ID</StatLabel>
            <StatNumber>
              {getMonthlyIncomeReportResult.data?.report.monthlyData.voucherEnd}
            </StatNumber>
          </Stat>
        </PageCardComponent>
        <PageCardComponent>
          <Stat>
            <StatLabel>Voucher Quantity</StatLabel>
            <StatNumber>
              {
                getMonthlyIncomeReportResult.data?.report.monthlyData
                  .voucherQuantity
              }
            </StatNumber>
          </Stat>
        </PageCardComponent>
        <PageCardComponent>
          <Stat>
            <StatLabel>Total Monthly Income</StatLabel>
            <StatNumber>
              ৳ {getMonthlyIncomeReportResult.data?.report.monthlyData.income}
            </StatNumber>
          </Stat>
        </PageCardComponent>
      </Stack>

      <Stack direction={{ base: "column", xl: "row" }} width="100%" mt="2">
        <Spacer />
        {getMonthlyIncomeReportResult.data && (
          <PrintMonthlyIncomeReportComponent
            monthlyIncomeReport={getMonthlyIncomeReportResult.data}
            month={selectedMonth.label}
            year={selectedYear.label}
          />
        )}
      </Stack>

      {getMonthlyIncomeReportResult.data ? (
        <Stack>
          <PageCardComponent>
            <TableContainer>
              <Table size="sm" variant={"striped"}>
                <TableCaption placement="top" pb="6" fontSize={"lg"}>
                  Monthly Income Report ({selectedMonth.label},{" "}
                  {selectedYear.label})
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Shift Start</Th>
                    <Th>Shift End</Th>
                    <Th>Voucher Start</Th>
                    <Th>Voucher End</Th>
                    <Th>Total Quantity</Th>
                    <Th>Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getMonthlyIncomeReportResult.data?.report.dailyData.map(
                    (row) => {
                      return (
                        <>
                          {row.shifts.map((shift, index) => {
                            return (
                              <Tr
                                borderBottom={
                                  index === row.shifts.length - 1
                                    ? "2px solid grey"
                                    : "none"
                                }
                              >
                                <Td verticalAlign={"center"}>
                                  {dayjs(row.date).format("DD MMM, YYYY")}
                                </Td>
                                <Td>
                                  {dayjs(shift.shiftStart).format("hh:mm A")}
                                </Td>
                                <Td>
                                  {dayjs(shift.shiftEnd).format("hh:mm A")}
                                </Td>
                                <Td>{shift.voucherStart}</Td>
                                <Td>{shift.voucherEnd}</Td>
                                <Td>{shift.voucherQuantity}</Td>
                                <Td>৳ {shift.income}</Td>
                              </Tr>
                            );
                          })}
                        </>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </PageCardComponent>
        </Stack>
      ) : (
        <Center>
          <Spinner />
        </Center>
      )}
    </Stack>
  );
};
