import {
  FormControl,
  FormLabel,
  useToast,
  FormHelperText,
  Stack,
  Input,
  Button
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { useCreateDoctorDesignationMutation } from "../api";

export const CreateDoctorDesignationPage: React.FC = () => {
  const [input, setInput] = useState("");
  const [createNewDesignation, createNewDesignationResult] =
    useCreateDoctorDesignationMutation();

  const toast = useToast();

  useEffect(() => {
    if (createNewDesignationResult.isSuccess) {
      toast({
        title: "Designation created successfully",
        position: "top",
        status: "success",
        duration: 4000
      });
    } else if (createNewDesignationResult.isError) {
      toast({
        title: "Designation could not be created",
        position: "top",
        status: "error",
        duration: 4000
      });
    }
  }, [createNewDesignationResult, toast]);

  const handleSubmit = async () => {
    if (!input) return;
    await createNewDesignation({
      designation: {
        name: input,
        isDesignationForDoctor: true
      }
    });
    setInput("");
  };

  return (
    <Stack>
      <PageCardComponent>
        <Stack spacing={4}>
          <FormControl isInvalid={!input} isRequired>
            <FormLabel>Designation Name</FormLabel>
            <Input
              type="text"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInput(event.target.value);
              }}
              value={input}
            />
            <FormHelperText>
              Enter the name of the new designation
            </FormHelperText>
          </FormControl>
          <Button mt={4} type="submit" onClick={handleSubmit}>
            Create
          </Button>
        </Stack>
      </PageCardComponent>
    </Stack>
  );
};
