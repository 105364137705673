import React from "react";
import { Button, Link, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { FaFileExcel } from "react-icons/fa";

interface UserFeePageProps {}

export const UserFeePage: React.FC<UserFeePageProps> = (
  props: UserFeePageProps
) => {
  return (
    <Stack spacing="4">
      <Text>
        Download the following excel file as a template of your user fee
        distribution model.
      </Text>

      <Button
        maxWidth={"256px"}
        as={Link}
        colorScheme="blue"
        color="white"
        leftIcon={<FaFileExcel />}
        href="/files/user-fee-distribution.xlsx"
        download={`User-Fee-Distribution-${dayjs().format("DD-MMM-YYYY")}.xlsx`}
      >
        Download
      </Button>
    </Stack>
  );
};
