import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import {
  Patient,
  Person,
  Transaction,
  Voucher,
  VoucherRecord
} from "app/api/type";
import { env } from "app/config";
import dayjs from "dayjs";
import React from "react";

interface PrintPaymentVoucherHistoryPageProps {
  vouchersWithDetails: {
    voucher: Voucher;
    person: Person;
    patient: Patient;
    transactions: Transaction[];
    voucherRecords: VoucherRecord[];
  }[];
  date: Date;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Bold.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24,
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    textAlign: "center"
  },
  title: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 4,
    textAlign: "center"
  },
  subTitle: {
    fontSize: 10,
    marginBottom: 4,
    textAlign: "center"
  },
  table: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #EDF2F6"
  },
  tableHeader: {
    fontSize: 8,
    backgroundColor: "#CBD5E0",
    borderTop: "0.1px solid #EDF2F6",
    borderBottom: "0.1px solid #EDF2F6",
    fontWeight: "bold"
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    fontWeight: "bold",
    borderBottom: "1px solid #4A5568",
    backgroundColor: "#CBD5E0"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 8
  },
  serial: {
    width: "5%",
    padding: "1px"
  },
  date: {
    width: "20%",
    padding: "1px"
  },
  transactionId: {
    width: "15%",
    padding: "1px"
  },
  total: {
    width: "10%",
    padding: "1px",
    textAlign: "right"
  },
  paid: {
    width: "10%",
    padding: "1px",
    textAlign: "right"
  },
  discount: {
    width: "10%",
    padding: "1px",
    textAlign: "right"
  },
  patient: {
    width: "30%",
    padding: "1px"
  }
});

export const PrintPaymentVoucherHistoryPage: React.FC<
  PrintPaymentVoucherHistoryPageProps
> = (props: PrintPaymentVoucherHistoryPageProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Image style={styles.logo} src={env.governmentLogo} />
              <View style={{ paddingHorizontal: "25px" }}>
                <View>
                  <Text style={{ fontSize: 11, alignSelf: "center" }}>
                    {env.voucherTitle}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 9, alignSelf: "center" }}>
                    {env.hospitalName}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.hospitalLogo} />
            </View>
            <View
              style={{
                borderTop: "1px solid #4A5568",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>Payment Voucher History Report</Text>
              <Text style={styles.subTitle}>
                {dayjs(props.date).format("DD MMM YYYY")}
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #4A5568"
                }}
              ></View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.serial}>SL.</Text>
                <Text style={styles.date}>DATE</Text>
                <Text style={styles.transactionId}>TRANSACTION ID</Text>{" "}
                <Text style={styles.patient}>PATIENT</Text>
                <Text style={styles.total}>TOTAL</Text>
                <Text style={styles.paid}>PAID</Text>
                <Text style={styles.discount}>DISCOUNT</Text>
              </View>
              {props.vouchersWithDetails.map((voucherDetails, index) => (
                <View
                  style={{
                    ...styles.row,
                    backgroundColor: index % 2 ? "#EDF2F6" : "white",
                    borderBottom: "0.1px solid #EDF2F6"
                  }}
                  key={index}
                  wrap={false}
                >
                  <Text style={styles.serial}>{index + 1}</Text>
                  <Text style={styles.date}>
                    {dayjs(voucherDetails.voucher.createdAt).format(
                      "hh:mm A, DD MMM, YYYY"
                    )}
                  </Text>
                  <Text style={styles.transactionId}>
                    {voucherDetails.voucher.id}
                  </Text>{" "}
                  <Text style={styles.patient}>
                    {voucherDetails.person.name}
                  </Text>
                  <Text style={styles.total}>
                    ৳{" "}
                    {voucherDetails.voucherRecords
                      .reduce(
                        (subtotal, voucherRecord) =>
                          subtotal +
                          voucherRecord.quantity * voucherRecord.basePrice,
                        0
                      )
                      .toFixed(2)}
                  </Text>
                  <Text style={styles.paid}>
                    ৳ {voucherDetails.voucher.paidAmount.toFixed(2)}
                  </Text>
                  <Text style={styles.discount}>
                    ৳{" "}
                    {(
                      voucherDetails.voucherRecords.reduce(
                        (subtotal, voucherRecord) =>
                          subtotal +
                          voucherRecord.quantity * voucherRecord.basePrice,
                        0
                      ) - voucherDetails.voucher.paidAmount
                    ).toFixed(2)}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          <View>
            <View
              style={{
                borderTop: "1px solid #4A5568"
              }}
            >
              <View style={[styles.tableFooter]} fixed>
                <View style={[styles.row, styles.tableHeader]} fixed>
                  <Text style={styles.serial}></Text>
                  <Text style={styles.date}>Total</Text>
                  <Text style={styles.transactionId}>
                    {props.vouchersWithDetails.length}
                  </Text>{" "}
                  <Text style={styles.patient}></Text>
                  <Text style={styles.total}>
                    ৳{" "}
                    {props.vouchersWithDetails
                      .reduce((total, voucherWithDetails) => {
                        return (
                          total +
                          voucherWithDetails.voucherRecords.reduce(
                            (subtotal, voucherRecord) =>
                              subtotal +
                              voucherRecord.quantity * voucherRecord.basePrice,
                            0
                          )
                        );
                      }, 0)
                      .toFixed(2)}
                  </Text>
                  <Text style={styles.paid}>
                    ৳{" "}
                    {props.vouchersWithDetails
                      .reduce((total, voucherWithDetails) => {
                        return total + voucherWithDetails.voucher.paidAmount;
                      }, 0)
                      .toFixed(2)}
                  </Text>
                  <Text style={styles.discount}>
                    ৳{" "}
                    {props.vouchersWithDetails
                      .reduce((total, voucherWithDetails) => {
                        return (
                          total +
                          voucherWithDetails.voucherRecords.reduce(
                            (subtotal, voucherRecord) =>
                              subtotal +
                              voucherRecord.quantity * voucherRecord.basePrice,
                            0
                          ) -
                          voucherWithDetails.voucher.paidAmount
                        );
                      }, 0)
                      .toFixed(2)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            width: "100%",
            bottom: 12,
            position: "absolute",
            borderTop: "1px solid #4A5568",
            alignSelf: "center"
          }}
          fixed
        >
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Powered By: BinduLogic Limited
            </Text>
            <Text
              style={{ fontSize: 8, color: "#666666" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Printed At:{" "}
              {window.localStorage.getItem("locationName") || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
