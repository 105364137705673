import React, { Dispatch, SetStateAction } from "react";
import { Button, Stack, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Transaction, Voucher, VoucherRecord } from "app/api/type";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { RankingInfo } from "@tanstack/match-sorter-utils";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { TableGenerator } from "modules/core/components/table-generator.component";

interface ExpenseVoucherTableComponentProps {
  vouchersWithDetails: {
    voucher: Voucher;
    transactions: Transaction[];
    voucherRecords: VoucherRecord[];
  }[];
  month: string;
  year: string;
  pagination: {
    pageSize: number;
    pageIndex: number;
    pageCount: number;
  };
  setPagination: Dispatch<
    SetStateAction<{ pageIndex: number; pageSize: number }>
  >;
  statistics: { [key: string]: number };
}

export const ExpenseVoucherTableComponent: React.FC<
  ExpenseVoucherTableComponentProps
> = (props: ExpenseVoucherTableComponentProps) => {
  const navigate = useNavigate();

  let tableData: {}[] = [];

  if (props.vouchersWithDetails.length > 0) {
    tableData = props.vouchersWithDetails.map((voucherWithDetails, index) => {
      const paidAmount: number = voucherWithDetails.voucher.paidAmount;
      const baseAmount: number = voucherWithDetails.voucherRecords.reduce(
        (total, record) => {
          return total + record.basePrice * record.quantity;
        },
        0
      );

      return {
        "Sl.":
          props.pagination.pageIndex * props.pagination.pageSize + index + 1,
        Date: dayjs(voucherWithDetails.voucher.createdAt).format(
          "hh:mm A, MMM DD, YYYY"
        ),
        "Transaction ID": voucherWithDetails.voucher.id,
        "Base Cost": `৳ ${baseAmount.toFixed(2)}`,
        VAT: `৳ ${(paidAmount - baseAmount).toFixed(2)} `,
        "Total Amount": `৳ ${paidAmount.toFixed(2)}`,

        Action: (
          <Button
            size={"sm"}
            colorScheme="blue"
            onClick={() =>
              navigate(
                `/${
                  window.localStorage.getItem("locationCategory") as string
                }/voucher?voucherId=${voucherWithDetails.voucher.id}`
              )
            }
          >
            Details
          </Button>
        )
      };
    });
  }

  return (
    <Stack spacing="2">
      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        my="2"
      >
        {/* statistics: {
            "Total Vouchers": number,
            "Total Amount": string,
            "Paid Amount": string
        } */}
        <PageCardComponent>
          <Stat>
            <StatLabel>Transaction Count</StatLabel>
            <StatNumber>{props.statistics["Total Vouchers"]}</StatNumber>
          </Stat>
        </PageCardComponent>

        <PageCardComponent>
          <Stat>
            <StatLabel>Base Expense</StatLabel>
            <StatNumber>
              ৳ {Number(props.statistics["Total Amount"]).toFixed(2)}
            </StatNumber>
          </Stat>
        </PageCardComponent>

        <PageCardComponent>
          <Stat>
            <StatLabel>Total VAT</StatLabel>
            <StatNumber>
              ৳{" "}
              {(
                Number(props.statistics["Paid Amount"]) -
                Number(props.statistics["Total Amount"])
              ).toFixed(2)}
            </StatNumber>
          </Stat>
        </PageCardComponent>

        <PageCardComponent>
          <Stat>
            <StatLabel>Total Expense</StatLabel>
            <StatNumber>
              ৳ {Number(props.statistics["Paid Amount"]).toFixed(2)}
            </StatNumber>
          </Stat>
        </PageCardComponent>
      </Stack>

      <Stack>
        <TableGenerator
          data={tableData}
          pageIndex={props.pagination.pageIndex}
          pageSize={props.pagination.pageSize}
          setPagination={props.setPagination}
          pageCount={props.pagination.pageCount}
        />
      </Stack>
    </Stack>
  );
};
