import {
  Center,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect, useState } from "react";
import {
  useGetCategorizedSublocationsQuery,
  useGetTransactionHistoryQuery
} from "../api";

interface TransactionHistoryComponentProps {}

export const TransactionHistoryComponent: React.FC<
  TransactionHistoryComponentProps
> = (props: TransactionHistoryComponentProps) => {
  const getTransactionHistoryResult = useGetTransactionHistoryQuery({
    locationId: ""
  });

  const getCategorizedSublocationsResult = useGetCategorizedSublocationsQuery({
    locationCategoryName: "Counter"
  });
  const [categorizedSublocations, setCategorizedSublocations] = useState<
    { label: string; value: string }[]
  >([]);
  const [categorizedSublocation, setCategorizedSublocation] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (getCategorizedSublocationsResult.data) {
      const sublocations = [
        { label: "All", value: "all" },
        ...getCategorizedSublocationsResult.data.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
      ];
      setCategorizedSublocations(sublocations);
      setCategorizedSublocation(sublocations[0]);
    }
  }, [getCategorizedSublocationsResult.data]);

  return (
    <Stack spacing="6">
      <PageCardComponent>
        {getTransactionHistoryResult.data ? (
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Sl.</Th>
                  <Th>Time</Th>
                  <Th>Amount</Th>
                  <Th>Source Account</Th>
                  <Th>Source Balance</Th>
                  <Th>Destination Account</Th>
                  <Th>Destination Balance</Th>
                  <Th>Recorder</Th>
                </Tr>
              </Thead>

              <Tbody>
                {getTransactionHistoryResult.data.transactionsWithDetails.map(
                  (transactionWithDetails, index) => {
                    return (
                      <Tr>
                        <Td>{index + 1}</Td>
                        <Td>
                          {dayjs(
                            transactionWithDetails.transaction.createdAt
                          ).format("hh:MM A, DD MMM, YYYY")}
                        </Td>
                        <Td>৳ {transactionWithDetails.transaction.amount}</Td>
                        <Td>
                          {transactionWithDetails.transaction.sourceAccountName}
                        </Td>{" "}
                        <Td>
                          {transactionWithDetails.transaction
                            .sourceAccountCurrentBalance >= 0
                            ? `৳ ${transactionWithDetails.transaction.sourceAccountCurrentBalance}`
                            : `-`}
                        </Td>
                        <Td>
                          {
                            transactionWithDetails.transaction
                              .destinationAccountName
                          }
                        </Td>{" "}
                        <Td>
                          {transactionWithDetails.transaction
                            .destinationAccountCurrentBalance >= 0
                            ? `৳ ${transactionWithDetails.transaction.destinationAccountCurrentBalance}`
                            : `-`}
                        </Td>
                        <Td>{transactionWithDetails.person.name}</Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Center width="100%" height="10vh">
            <Spinner />
          </Center>
        )}
      </PageCardComponent>
    </Stack>
  );
};
