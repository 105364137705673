export const env = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || "http://localhost:1337",
  appName:
    process.env.REACT_APP_APP_NAME || "Bindulogic Enterprise Application",
  voucherTitle: process.env.REACT_APP_VOUCHER_TITLE || "Bindulogic",
  hospitalName: process.env.REACT_APP_HOSPITAL_NAME || "Bindulogic Hospital",
  hospitalLogo:
    process.env.REACT_APP_HOSPITAL_LOGO ||
    "https://i.ibb.co/vw4rzqH/bindulogic.png",
  hospitalAddress:
    process.env.REACT_APP_HOSPITAL_ADDRESS || "Mirpur, Dhaka, Bangladesh",
  hospitalContact:
    process.env.REACT_APP_HOSPITAL_CONTACT || "+880 1711 111 111",
  discountOptions: process.env.REACT_APP_DISCOUNT_OPTIONS === "TRUE",
  doctorReference: process.env.REACT_APP_DOCTOR_REFERENCE === "TRUE",
  governmentLogo:
    process.env.REACT_APP_GOVERNMENT_LOGO ||
    "https://i.ibb.co/1RhrXSn/government-logo.png",
  deploymentType: process.env.REACT_APP_DEPLOYMENT_TYPE as
    | "lab-billing"
    | "diagnostic-billing"
};

export const customPagination = {
  pageIndex: 0,
  pageSize: 10
};

export const displayAllTablePage: number[] = [10, 20, 50, 100];
