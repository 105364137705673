import {
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  Input,
  InputGroup,
  SimpleGrid,
  Stack
} from "@chakra-ui/react";
import { Select, SingleValue } from "chakra-react-select";
import { useGetDoctorsQuery } from "../api";

import { DoctorWithDetails } from "app/api/type";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { useEffect, useState } from "react";

interface Props {
  setBmdcNumber(bmdcNumber: string): void;
}

type DoctorOption = {
  label: string;
  value: DoctorWithDetails;
};

export const DoctorReferenceComponent = (props: Props) => {
  const getDoctorsResult = useGetDoctorsQuery({});
  const [options, setOptions] = useState<DoctorOption[]>();

  const [selectedOption, setSelectedOption] = useState<DoctorOption>();

  const handleSelection = (event: SingleValue<DoctorOption>) => {
    if (event) {
      setSelectedOption({
        label: event.label,
        value: event.value
      });
      props.setBmdcNumber(event.value.doctor.bmdcNumber);
    }
  };

  const fetchOptions = () => {
    if (getDoctorsResult.data) {
      setOptions(
        getDoctorsResult.data.doctorsWithDetails.map(
          (doctorWithDetails: DoctorWithDetails) => {
            return {
              label: `${doctorWithDetails.designation.name} ${doctorWithDetails.person.name} (BMDC: ${doctorWithDetails.doctor.bmdcNumber})`,
              value: doctorWithDetails
            };
          }
        )
      );
    }
  };

  useEffect(() => {
    if (getDoctorsResult.isSuccess) {
      fetchOptions();
    }
  }, [getDoctorsResult]);

  return (
    <PageCardComponent>
      <Stack spacing="5">
        <Heading fontSize="md">Referred By</Heading>
        <SimpleGrid
          columns={{ base: 10 }}
          rowGap={4}
          columnGap={4}
          alignItems="end"
        >
          <GridItem colSpan={10}>
            <FormControl width="100%">
              <Select
                options={options}
                value={selectedOption}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                onChange={handleSelection}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={3}>
            <FormControl width="100%">
              <FormLabel>BMDC No.</FormLabel>
              <Input
                value={selectedOption?.value?.doctor.bmdcNumber || ""}
                readOnly
              />
            </FormControl>
          </GridItem>

          <GridItem colSpan={7}>
            <FormControl width="100%">
              <FormLabel>Name</FormLabel>
              <InputGroup>
                <Input
                  value={selectedOption?.value?.person.name || ""}
                  readOnly
                />
              </InputGroup>
            </FormControl>
          </GridItem>

          <GridItem colSpan={5}>
            <FormControl width="100%">
              <FormLabel>Designation</FormLabel>

              <InputGroup>
                <Input
                  value={selectedOption?.value?.designation.name || ""}
                  readOnly
                />
              </InputGroup>
            </FormControl>
          </GridItem>

          <GridItem colSpan={5}>
            <FormControl width="100%">
              <FormLabel>Contact No.</FormLabel>
              <InputGroup>
                <Input
                  value={selectedOption?.value?.person.contactNumber || ""}
                  readOnly
                />
              </InputGroup>
            </FormControl>
          </GridItem>
        </SimpleGrid>
      </Stack>
    </PageCardComponent>
  );
};
