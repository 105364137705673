import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { GetCashbookResponse } from "modules/counter/api/type";
import { PrintCashbookReportPage } from "../pages/print-cashbook-report.page";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";
import { Button, useDisclosure } from "@chakra-ui/react";

interface PrintCashbookReportComponentProps {
  cashbookReport: GetCashbookResponse;
  month: string;
  year: string;
}

export const PrintCashbookReportComponent: React.FC<
  PrintCashbookReportComponentProps
> = (props: PrintCashbookReportComponentProps) => {
  const fileName = `Cashbook-History-Report-${props.month}-${props.year}.pdf`;

  const Document = (
    <PrintCashbookReportPage
      cashbookHistory={props.cashbookReport}
      month={props.month}
      year={props.year}
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={fileName}
      />
    </>
  );
};
