import { Button, useDisclosure } from "@chakra-ui/react";
import dayjs from "dayjs";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";
import { useLazyGetDailyVoucherListQuery } from "modules/counter/api";
import React, { useEffect } from "react";
import { PrintPaymentVoucherHistoryPage } from "../pages/print-payment-voucher-history.page";

interface PrintPaymentVoucherHistoryComponentProps {
  location: string;
  date: Date;
}

export const PrintPaymentVoucherHistoryComponent: React.FC<
  PrintPaymentVoucherHistoryComponentProps
> = (props: PrintPaymentVoucherHistoryComponentProps) => {
  const fileName = `Payment-Voucher-Report-${dayjs(props.date).format(
    "DD-MMM-YYYY"
  )}.pdf`;

  const [getVoucherList, getVoucherListResult] =
    useLazyGetDailyVoucherListQuery();

  useEffect(() => {
    getVoucherList({
      type: "Payment Voucher",
      date: props.date.toISOString(),
      locationId: props.location
    });
  }, [props.date, props.location]);

  const Document = (
    <PrintPaymentVoucherHistoryPage
      vouchersWithDetails={
        getVoucherListResult.data
          ? getVoucherListResult.data.vouchersWithDetails
          : []
      }
      date={props.date}
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="blue"
        isLoading={getVoucherListResult.isFetching}
      >
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={fileName}
      />
    </>
  );
};
