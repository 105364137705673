import {
  Button,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  SimpleGrid,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import { Gender, Investigation, InvestigationPrice } from "app/api/type";
import { env } from "app/config";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import { useTypedDispatch } from "hooks/use-typed-dispatch.hook";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  useCreateVoucherMutation,
  useGetInvestigationsQuery,
  useGetWardTypesQuery
} from "../api";
import { genderList } from "../constants/gender.constant";
import {
  addVoucherRecord,
  deleteVoucherRecord,
  resetCounterState
} from "../state/payment-voucher.slice";
import "../styles/chakra-react-datepicker.css";
import { DoctorReferenceComponent } from "./doctor-reference.component";

interface CreatePaymentVoucherComponentProps {}

export const CreatePaymentVoucherComponent: React.FC<
  CreatePaymentVoucherComponentProps
> = (props: CreatePaymentVoucherComponentProps) => {
  const dispatch = useTypedDispatch();

  const toast = useToast();

  const navigate = useNavigate();

  const [createVoucher, createVoucherResult] = useCreateVoucherMutation();

  const getWardTypesResult = useGetWardTypesQuery({});
  const getInvestigationsResult = useGetInvestigationsQuery({});

  const voucherEntries = useTypedSelector(
    (state) => state.paymentVoucher.voucherEntries
  );

  useEffect(() => {
    dispatch(resetCounterState());
  }, []);

  const [name, setName] = useState<string>();

  const [phoneNumber, setPhoneNumber] = useState<string>();

  const [wardNumber, setWardNumber] = useState<string>();
  const [bedNumber, setBedNumber] = useState<string>();
  const [patientRegistrationNumber, setPatientRegistrationNumber] =
    useState<string>();

  const [gender, setGender] = useState<{
    label: string;
    value: string;
  }>();

  const [note, setNote] = useState<string>();

  const [wardType, setWardType] = useState<{
    label: string;
    value: string;
  }>();

  const [wardTypes, setWardTypes] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (getWardTypesResult.isSuccess) {
      setWardTypes(
        getWardTypesResult.data.wardTypes.map((wardType) => {
          return {
            label: wardType.name,
            value: wardType.name
          };
        })
      );
    }
  }, [getWardTypesResult]);

  const [selectedInvestigation, setSelectedInvestigation] = useState<{
    label: string;
    value: {
      investigation: Investigation;
      investigationPrices: InvestigationPrice[];
    };
  }>();

  const [investigations, setInvestigations] = useState<
    {
      label: string;
      value: {
        investigation: Investigation;
        investigationPrices: InvestigationPrice[];
      };
    }[]
  >([]);

  useEffect(() => {
    if (getInvestigationsResult.isSuccess) {
      setInvestigations(
        getInvestigationsResult.data.investigationWithPrices.map(
          (investigationWithPrice) => {
            return {
              label: investigationWithPrice.investigation.name,
              value: investigationWithPrice
            };
          }
        )
      );
    }
  }, [getInvestigationsResult]);

  const [quantity, setQuantity] = useState(new Decimal(1));
  const [discount, setDiscount] = useState(new Decimal(0));
  const [price, setPrice] = useState(new Decimal(0));

  useEffect(() => {
    if (selectedInvestigation && wardType) {
      setQuantity(new Decimal(1));
      setDiscount(new Decimal(0));
      setPrice(
        new Decimal(
          selectedInvestigation.value.investigationPrices.filter(
            (currentPrice) => currentPrice.wardTypeName === wardType.value
          )[0].price || 0
        )
      );
    }
  }, [selectedInvestigation, wardType]);

  useEffect(() => {
    if (selectedInvestigation && wardType) {
      setPrice(
        new Decimal(
          selectedInvestigation.value.investigationPrices.filter(
            (currentPrice) => currentPrice.wardTypeName === wardType.value
          )[0].price
        )
          .mul(quantity)
          .mul(new Decimal(1).minus(discount.div(100)) || new Decimal(0))
      );
    }
  }, [quantity, discount]);

  const [isFree, setIsFree] = useState<boolean>(false);
  const [nationalIdNumber, setNationalIdNumber] = useState<string>();

  const [dateOfBirth, setDateOfBirth] = useState<Date>();

  const [addressLine, setAddressLine] = useState<string>();
  const [division, setDivision] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [district, setDistrict] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [subdistrict, setSubdistrict] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [union, setUnion] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [isDateOfBirthEstimated, setIsDateOfBirthEstimated] =
    useState<boolean>(true);

  const [age, setAge] = useState<number>();
  const [ageInYears, setAgeInYears] = useState<number>();
  const [ageInMonths, setAgeInMonths] = useState<number>(0);
  const [ageInDays, setAgeInDays] = useState<number>(0);

  useEffect(() => {
    setDateOfBirth(
      dayjs()
        .subtract(ageInYears ? ageInYears : 0, "year")
        .subtract(ageInMonths ? ageInMonths : 0, "month")
        .subtract(ageInDays ? ageInDays : 0, "days")
        .toDate()
    );
  }, [ageInDays, ageInMonths, ageInYears]);

  const [referrerBmdcNumber, setReferrerBmdcNumber] = useState<string>();

  const handleAddInvestigation = () => {
    if (
      selectedInvestigation &&
      quantity &&
      wardType &&
      voucherEntries.filter(
        (entry) =>
          selectedInvestigation.value.investigation.id ===
          entry.voucherRecord.investigationId
      ).length === 0
    ) {
      dispatch(
        addVoucherRecord({
          voucherRecord: {
            quantity: quantity.toNumber(),
            details: null,
            basePrice:
              selectedInvestigation.value.investigationPrices.filter(
                (currentPrice) => currentPrice.wardTypeName === wardType.value
              )[0].price || 0,
            totalPrice: price.toNumber(),
            investigationId: selectedInvestigation.value.investigation.id,
            discountPercentage: discount.toNumber()
          },
          investigation: selectedInvestigation.value.investigation
        })
      );
    }
  };

  const handleCreateVoucher = () => {
    const paidAmount: number = isFree
      ? 0
      : voucherEntries.reduce(
          (totalPrice, voucherEntry) =>
            totalPrice + voucherEntry.voucherRecord.totalPrice,
          0
        );

    createVoucher({
      data: {
        voucher: {
          wardTypeName: wardType?.value || "General Bed",
          voucherTypeName: "Payment Voucher",
          note: note || null,
          paidAmount,
          wardNumber: wardNumber || null,
          bedNumber: bedNumber || null,
          dueAmount: 0,
          sourceAccountName: "Income",
          destinationAccountName: "Cash"
        },
        voucherRecords: voucherEntries.map((entry) => {
          return {
            ...entry.voucherRecord,
            discountPercentage: isFree
              ? 100
              : entry.voucherRecord.discountPercentage,
            totalPrice: isFree ? 0 : entry.voucherRecord.totalPrice,
            investigationId: entry.investigation.id,
            expenseId: null,
            taxPercentage: 0
          };
        }),
        voucherFor: {
          person: {
            name: name || null,
            contactNumber: phoneNumber ? phoneNumber : "",
            dateOfBirth: dateOfBirth ? dateOfBirth : null,
            isDateOfBirthEstimated,
            gender: gender ? (gender.value as Gender) : null,
            nationalIdNumber: nationalIdNumber ? nationalIdNumber : null,
            passportNumber: null,
            dateOfDeath: null,
            isDateOfDeathEstimated: null
          },
          patient: {
            patientRegistrationNumber: patientRegistrationNumber || null,
            patientCategoryName: null
          },
          address: {
            addressLine1: addressLine ? addressLine : null,
            addressLine2: null,
            postalCode: null,
            divisionCode: division ? division.code : null,
            districtCode: district ? district.code : null,
            subdistrictCode: subdistrict ? subdistrict.code : null,
            unionCode: union ? union.code : null
          }
        },
        referrerBmdcNumber: referrerBmdcNumber || null
      }
    });
  };

  useEffect(() => {
    if (createVoucherResult.isSuccess) {
      toast({
        title: "Success",
        description: "Voucher has been created",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });

      dispatch(resetCounterState());

      const locationCategory = window.localStorage.getItem(
        "locationCategory"
      ) as string;

      navigate(
        `/${locationCategory.toLowerCase()}/voucher?voucherId=${
          createVoucherResult.data.voucher.id
        }`
      );
    }
  }, [createVoucherResult]);

  return (
    <>
      <Stack spacing="4">
        <PageCardComponent>
          <Stack spacing="4">
            <Heading fontSize="md">Patient Personal Details</Heading>
            <SimpleGrid columns={{ base: 1, lg: 3 }} rowGap={3} columnGap={2}>
              <FormControl width="100%">
                <FormLabel>Phone Number</FormLabel>
                <InputGroup>
                  <InputLeftAddon children="+88" />
                  <Input
                    value={phoneNumber}
                    type="number"
                    onChange={(event) => {
                      setPhoneNumber(event.target.value);
                    }}
                    placeholder="01XXXXXXXXX"
                  />
                </InputGroup>
              </FormControl>

              <FormControl width="100%">
                <FormLabel>Name</FormLabel>
                <Input
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value.toUpperCase());
                  }}
                  placeholder="Enter Name"
                />
              </FormControl>

              <FormControl width="100%">
                <FormLabel>Gender</FormLabel>
                <Select
                  selectedOptionStyle="check"
                  options={genderList}
                  value={gender}
                  onChange={(event) => {
                    if (event) {
                      setGender({
                        label: event.label,
                        value: event.value
                      });
                    }
                  }}
                  placeholder="Male / Female / Other"
                />
              </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, lg: 2 }} rowGap={3} columnGap={2}>
              <FormControl width="100%">
                <FormLabel>
                  <Stack direction="row" spacing="2" alignItems={"center"}>
                    <Text>Age</Text>
                  </Stack>
                </FormLabel>

                <Stack direction="row">
                  <InputGroup>
                    <Input
                      id="ageInYears"
                      value={ageInYears}
                      placeholder="Years"
                      type="number"
                      min="0"
                      step="1"
                      onChange={(event) => {
                        setAgeInYears(event.target.valueAsNumber);
                      }}
                    />
                    <InputRightAddon children="Y" />
                  </InputGroup>

                  <InputGroup>
                    <Input
                      id="ageInMonths"
                      value={ageInMonths}
                      placeholder="Months"
                      type="number"
                      min="0"
                      step="1"
                      max="11"
                      onChange={(event) => {
                        setAgeInMonths(event.target.valueAsNumber);
                      }}
                    />
                    <InputRightAddon children="M" />
                  </InputGroup>

                  <InputGroup>
                    <Input
                      id="ageInDays"
                      value={ageInDays}
                      placeholder="Days"
                      type="number"
                      min="0"
                      step="1"
                      max="30"
                      onChange={(event) => {
                        setAgeInDays(event.target.valueAsNumber);
                      }}
                    />
                    <InputRightAddon children="D" />
                  </InputGroup>
                </Stack>
              </FormControl>

              <FormControl width="100%">
                <FormLabel>National ID Number</FormLabel>
                <Input
                  value={nationalIdNumber}
                  onChange={(event) => {
                    setNationalIdNumber(event.target.value);
                  }}
                  placeholder="Enter National ID Number (Optional)"
                />
              </FormControl>
            </SimpleGrid>
          </Stack>

          <Stack>
            <Heading fontSize="md">Patient Admission Details</Heading>
            <SimpleGrid columns={{ base: 2, lg: 4 }} rowGap={3} columnGap={2}>
              <FormControl width="100%" isRequired>
                <FormLabel>Ward Type</FormLabel>
                <Select
                  selectedOptionStyle="check"
                  options={wardTypes}
                  value={wardType}
                  isDisabled={voucherEntries.length > 0}
                  onChange={(event) => {
                    if (event) {
                      setWardType({
                        label: event.label,
                        value: event.value
                      });
                    }
                  }}
                  placeholder="Select Ward Type"
                />
              </FormControl>

              <FormControl width="100%">
                <FormLabel>Ward Number</FormLabel>
                <Input
                  value={wardNumber}
                  onChange={(event) => {
                    setWardNumber(event.target.value);
                  }}
                  placeholder="Enter Ward Type"
                />
              </FormControl>

              <FormControl width="100%">
                <FormLabel>Bed Number</FormLabel>
                <Input
                  value={bedNumber}
                  onChange={(event) => {
                    setBedNumber(event.target.value);
                  }}
                  placeholder="Enter Bed Number"
                />
              </FormControl>

              <FormControl width="100%">
                <FormLabel>Patient Registration No.</FormLabel>
                <Input
                  value={patientRegistrationNumber}
                  onChange={(event) => {
                    setPatientRegistrationNumber(event.target.value);
                  }}
                  placeholder="Enter Patient Registration Number"
                />
              </FormControl>
            </SimpleGrid>{" "}
          </Stack>
        </PageCardComponent>

        <PageCardComponent>
          <Stack spacing="4">
            <Heading fontSize="md">
              Investigations / Blood Components / Procedures
            </Heading>
            <SimpleGrid
              columns={{ base: 12 }}
              rowGap={4}
              columnGap={4}
              alignItems="end"
            >
              <GridItem colSpan={5}>
                <FormControl width="100%" isRequired>
                  <FormLabel>Item Name</FormLabel>
                  <Select
                    selectedOptionStyle="check"
                    options={investigations}
                    value={selectedInvestigation}
                    onChange={(event) => {
                      if (event) {
                        setSelectedInvestigation({
                          label: event.label,
                          value: event.value
                        });
                      }
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={env.discountOptions ? 2 : 3}>
                <FormControl width="100%" isRequired>
                  <FormLabel>Quantity</FormLabel>
                  <Input
                    value={quantity.toNumber()}
                    type="number"
                    onChange={(event) => {
                      setQuantity(new Decimal(event.target.valueAsNumber));
                    }}
                  />
                </FormControl>
              </GridItem>
              {env.discountOptions ? (
                <GridItem colSpan={2}>
                  <FormControl width="100%">
                    <FormLabel>Discount</FormLabel>

                    <InputGroup>
                      <Input
                        value={discount.toNumber()}
                        type="number"
                        onChange={(event) => {
                          setDiscount(new Decimal(event.target.valueAsNumber));
                        }}
                      />
                      <InputRightAddon children={"%"} />
                    </InputGroup>
                  </FormControl>
                </GridItem>
              ) : (
                <></>
              )}
              <GridItem colSpan={env.discountOptions ? 2 : 3}>
                <FormControl width="100%">
                  <FormLabel>Price</FormLabel>{" "}
                  <InputGroup>
                    <InputLeftAddon children="৳" />
                    <Input value={price.toNumber()} />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <Button
                  width="100%"
                  leftIcon={<FaPlus />}
                  colorScheme="blue"
                  isDisabled={
                    !wardType ||
                    !selectedInvestigation ||
                    !quantity ||
                    !discount
                  }
                  onClick={handleAddInvestigation}
                >
                  Add
                </Button>
              </GridItem>
            </SimpleGrid>
          </Stack>
        </PageCardComponent>

        <PageCardComponent>
          <Stack spacing="4">
            <Heading fontSize="md">Payment Voucher</Heading>

            <TableContainer>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th width="10%">Sl.</Th>
                    <Th width="50%">Item Name</Th>
                    <Th width="10%">Base Price</Th>
                    <Th width="10%">Quantity</Th>
                    {env.discountOptions && <Th>Discount</Th>}
                    <Th width="10%">Sub-Total</Th>
                    <Th width="10%"></Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {voucherEntries.map((voucherEntry, index) => {
                    return (
                      <Tr>
                        <Td>{index + 1}</Td>
                        <Td>{voucherEntry.investigation.name}</Td>
                        <Td>৳ {voucherEntry.voucherRecord.basePrice}</Td>
                        <Td>{voucherEntry.voucherRecord.quantity}</Td>
                        {env.discountOptions && (
                          <Td>
                            {isFree
                              ? 100
                              : voucherEntry.voucherRecord
                                  .discountPercentage}{" "}
                            %
                          </Td>
                        )}

                        <Td>৳ {voucherEntry.voucherRecord.totalPrice}</Td>

                        <Td>
                          <IconButton
                            aria-label="Delete this entry"
                            icon={<FaTimes />}
                            colorScheme="red"
                            onClick={() => {
                              dispatch(
                                deleteVoucherRecord({
                                  index
                                })
                              );
                            }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}

                  <Tr bgColor="gray.600" color="white" fontWeight={"bold"}>
                    <Td></Td>
                    <Td>Grand Total </Td>

                    <Td></Td>
                    <Td>=</Td>
                    {env.discountOptions && <Td></Td>}
                    <Td>
                      ৳{" "}
                      {isFree
                        ? 0
                        : voucherEntries
                            .reduce(
                              (totalPrice, voucherEntry) =>
                                new Decimal(totalPrice).plus(
                                  new Decimal(
                                    voucherEntry.voucherRecord.totalPrice
                                  )
                                ),
                              new Decimal(0)
                            )
                            .toNumber()}
                    </Td>
                    <Td></Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            {env.deploymentType === "lab-billing" ? (
              <Switch
                checked={isFree}
                onChange={() => {
                  setIsFree(!isFree);
                }}
              >
                Make Voucher Free
              </Switch>
            ) : (
              <></>
            )}
          </Stack>
        </PageCardComponent>

        {env.doctorReference && (
          <DoctorReferenceComponent setBmdcNumber={setReferrerBmdcNumber} />
        )}

        <PageCardComponent>
          <FormControl id="note">
            <FormLabel>Note</FormLabel>
            <Textarea
              value={note}
              onChange={(event) => setNote(event.target.value)}
              placeholder="Additional information"
            />
          </FormControl>
        </PageCardComponent>

        <Button
          onClick={handleCreateVoucher}
          colorScheme={"blue"}
          isDisabled={voucherEntries.length === 0}
          isLoading={createVoucherResult.isLoading}
        >
          Create Payment Voucher
        </Button>
      </Stack>
    </>
  );
};
