import { Stack } from "@chakra-ui/react";
import React from "react";
import { EditCommissionRatesComponent } from "../components/edit-commission-rates.component";

export const EditCommissionRatesPage: React.FC = () => {
  return (
    <Stack>
      <EditCommissionRatesComponent />
    </Stack>
  );
};
