import {
  Stack,
  useToast,
  Text,
  Button,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Badge,
  useDisclosure
} from "@chakra-ui/react";
import {
  useClearCommissionDuesMutation,
  useGetDoctorsWithCommissionsQuery as useGetDoctorsQuery
} from "../api";
import { DoctorWithCommissionDetails } from "../api/type";
import React, { useEffect, useState } from "react";
import { PageCardComponent } from "../../core/components/page-card.component";

export const ViewDoctorsCommissionComponent: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getDoctorsListResult = useGetDoctorsQuery({});
  const toast = useToast();
  const [clearDues, clearDuesResult] = useClearCommissionDuesMutation();

  const [clearDuesInfo, setClearDuesInfo] = useState<{
    name: string;
    bmdcNumber: string;
    amount: number;
  }>();

  const [doctorList, setDoctorList] = useState<DoctorWithCommissionDetails[]>(
    []
  );

  useEffect(() => {
    if (getDoctorsListResult.isSuccess) {
      setDoctorList(getDoctorsListResult.data.doctorsWithDetails);
    }
  }, [getDoctorsListResult]);

  useEffect(() => {
    if (clearDuesResult.isSuccess) {
      toast({
        title: "Dues Cleared",
        position: "top",
        status: "warning",
        duration: 4000
      });
    }

    if (clearDuesResult.isError) {
      toast({
        title: "There was a problem clearing this amount",
        position: "top",
        status: "error",
        duration: 4000
      });
    }
  }, [clearDuesResult, toast]);

  useEffect(() => {
    getDoctorsListResult.refetch();
  }, []);

  const openModal = (name: string, bmdcNumber: string, amount: number) => {
    setClearDuesInfo({
      name,
      bmdcNumber,
      amount
    });
    onOpen();
  };

  return (
    <Stack spacing={4}>
      <PageCardComponent>
        <Stack spacing={4}>
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th textAlign="center">BMDC No.</Th>
                  <Th textAlign="center">Designation</Th>
                  <Th textAlign="center">Name</Th>
                  <Th textAlign="center">Contact No.</Th>
                  <Th textAlign="center">Email</Th>
                  <Th textAlign="center">Payment Due</Th>
                  <Th textAlign="center">Payment Status</Th>
                  <Th textAlign="center"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {doctorList.map((currentDoctor) => {
                  const totalDue: number = currentDoctor.commissions.reduce(
                    (totalAmount, commission) => {
                      return (
                        totalAmount +
                        (commission.isPaid ? 0 : commission.amount)
                      );
                    },
                    0
                  );
                  return (
                    <Tr key={currentDoctor.doctor.bmdcNumber}>
                      <Td textAlign="center">
                        {currentDoctor.doctor.bmdcNumber}
                      </Td>
                      <Td textAlign="center">
                        {currentDoctor.designation.name}
                      </Td>
                      <Td textAlign="center">{currentDoctor.person.name}</Td>
                      <Td textAlign="center">
                        {currentDoctor.person.contactNumber}
                      </Td>
                      <Td textAlign="center">{currentDoctor.user.email}</Td>
                      <Td textAlign="center">৳ {totalDue}</Td>
                      <Td textAlign="center">
                        {totalDue > 0 ? (
                          <Badge colorScheme="red" variant="solid">
                            Pending
                          </Badge>
                        ) : (
                          <Badge colorScheme="green" variant="solid">
                            Cleared
                          </Badge>
                        )}
                      </Td>
                      <Td textAlign="center">
                        <Button
                          isDisabled={totalDue > 0 ? false : true}
                          colorScheme="red"
                          size="xs"
                          onClick={() => {
                            openModal(
                              currentDoctor.person.name || "this doctor",
                              currentDoctor.doctor.bmdcNumber,
                              totalDue
                            );
                          }}
                        >
                          Clear Amount
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </PageCardComponent>

      {/* The modal component to confirm clear dues */}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to clear {clearDuesInfo?.name}'s due amount
              of ৳{clearDuesInfo?.amount} ?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              aria-label="Confirm"
              margin="2%"
              fontSize="18px"
              onClick={async () => {
                if (!clearDuesInfo) {
                  onClose();
                  toast({
                    title: "There was a problem clearing this amount",
                    position: "top",
                    status: "error",
                    duration: 9000
                  });
                  return;
                }
                await clearDues({
                  bmdcNumber: clearDuesInfo.bmdcNumber,
                  amount: clearDuesInfo.amount
                });
                onClose();
              }}
            >
              Yes
            </Button>
            <Button
              aria-label="Decline"
              margin="2%"
              fontSize="18px"
              colorScheme="red"
              onClick={onClose}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};
