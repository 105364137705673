import { FaCheck } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import {
  GridItem,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  InputRightElement,
  IconButton,
  Tooltip
} from "@chakra-ui/react";
import { InvestigationPrice } from "app/api/type";
import React, { useEffect, useState } from "react";
import { useEditInvestigationPriceMutation } from "../api";

interface InvestigationPriceEditingCompnentProps {
  investigationPrice: InvestigationPrice;
}

export const InvestigationPriceEditingCompnent: React.FC<
  InvestigationPriceEditingCompnentProps
> = (props: InvestigationPriceEditingCompnentProps) => {
  const toast = useToast();
  const [price, setPrice] = useState(props.investigationPrice.price);

  const [editInvestigationPrice, editInvestigationPriceResult] =
    useEditInvestigationPriceMutation();

  useEffect(() => {
    if (editInvestigationPriceResult.isSuccess) {
      toast({
        title: "Update Successful",
        description: "The Investigation price has been updated",
        position: "top",
        status: "success",
        duration: 4000
      });
    } else if (editInvestigationPriceResult.isError) {
      toast({
        title: "Error",
        description: "The server could not update the investigation",
        position: "top",
        status: "error",
        duration: 4000
      });
    }
  }, [editInvestigationPriceResult, toast]);

  const handlePriceChange = () => {
    editInvestigationPrice({
      investigationId: props.investigationPrice.investigationId,
      wardType: props.investigationPrice.wardTypeName,
      price
    });
  };

  return (
    <Stack padding={2}>
      <SimpleGrid
        columns={{ base: 10 }}
        rowGap={4}
        alignItems="center"
        columnGap={1}
      >
        <GridItem colSpan={3}>
          <Text>{props.investigationPrice.wardTypeName}</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <InputGroup size={"md"}>
            <InputLeftAddon>BDT</InputLeftAddon>
            <NumberInput
              value={price}
              step={10}
              onChange={(valueString) => {
                setPrice(Number(valueString.replace(/^$/, "")));
              }}
              min={1}
            >
              <NumberInputField />

              <InputRightElement>
                <Tooltip
                  label="Restore"
                  isDisabled={props.investigationPrice.price === price}
                >
                  <IconButton
                    margin={1}
                    variant="plain"
                    colorScheme="blackAlpha"
                    aria-label="reset"
                    fontSize="lg"
                    icon={<GrPowerReset />}
                    isDisabled={props.investigationPrice.price === price}
                    onClick={() => {
                      setPrice(props.investigationPrice.price);
                    }}
                  />
                </Tooltip>
              </InputRightElement>
            </NumberInput>
            <Tooltip
              label="Save"
              isDisabled={props.investigationPrice.price === price}
            >
              <IconButton
                size="sm"
                marginX={4}
                marginY={1}
                isRound={true}
                variant="solid"
                colorScheme="teal"
                aria-label="Done"
                fontSize="20px"
                icon={<FaCheck />}
                isDisabled={props.investigationPrice.price === price}
                onClick={handlePriceChange}
              />
            </Tooltip>
          </InputGroup>
        </GridItem>
      </SimpleGrid>
    </Stack>
  );
};
