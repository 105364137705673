import { Stack } from "@chakra-ui/react";
import React from "react";
import { ViewDoctorsCommissionComponent } from "../components/view-doctors-commission.component";

export const ViewDoctorsCommissionPage: React.FC = (props) => {
  return (
    <Stack>
      <ViewDoctorsCommissionComponent />
    </Stack>
  );
};
