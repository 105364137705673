import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { GetLedgerBookResponse } from "modules/counter/api/type";
import { PrintMonthlyLedgerBookPage } from "../pages/print-monthly-ledger-book.page";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";
import { Button, useDisclosure } from "@chakra-ui/react";

interface PrintMonthlyLedgerBookComponentProps {
  monthlyLedgerReport: GetLedgerBookResponse;
  month: string;
  year: string;
}

export const PrintMonthlyLedgerBookComponent: React.FC<
  PrintMonthlyLedgerBookComponentProps
> = (props: PrintMonthlyLedgerBookComponentProps) => {
  const monthlyLedgerBookReport = `Monthly-Ledger-Book-Report-${props.month}-${props.year}.pdf`;

  const Document = (
    <PrintMonthlyLedgerBookPage
      monthlyLedgerReport={props.monthlyLedgerReport}
      month={props.month}
      year={props.year}
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={monthlyLedgerBookReport}
      />
    </>
  );
};
