import { LogoComponent } from "app/components/logo.component";
import { IDashboard } from "app/types";
import { BillingCounterDashboardPage } from "modules/counter/pages/shift-wise-income.page";
import { FaHistory, FaReceipt } from "react-icons/fa";
import { CreatePaymentVoucherPage } from "../pages/create-payment-voucher.page";
import { DailyCashCollectionReportPage } from "../pages/daily-cash-collection.page";
import { PaymentVoucherHistoryPage } from "../pages/payment-voucher-history.page";
import { ShiftQuantityReportPage } from "../pages/shift-wise-quantity.page";
import { TransactionHistoryPage } from "../pages/transaction-history.page";
import { VoucherDetailsPage } from "../pages/voucher-details.page";

export const counterDashboard: IDashboard = {
  dashboardName: "counter",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Dashboard",
      link: "/counter/dashboard",
      content: <BillingCounterDashboardPage />
    },
    {
      name: "Create Payment Voucher",
      link: "/counter/create-payment-voucher",
      content: <CreatePaymentVoucherPage />
    },
    {
      name: "Voucher Details",
      link: "/counter/voucher",
      content: <VoucherDetailsPage />
    },
    {
      name: "Transaction History",
      link: "/counter/transaction-history",
      content: <TransactionHistoryPage />
    },
    {
      name: "Daily Cash Collection Report",
      link: "/counter/daily-cash-collection-report",
      content: <DailyCashCollectionReportPage />
    },
    {
      name: "Payment Voucher History",
      link: "/counter/voucher/payment/history",
      content: <PaymentVoucherHistoryPage />
    },

    {
      name: "Shift Quantity Report",
      link: "/counter/shift-quantity-report",
      content: <ShiftQuantityReportPage />
    }
  ],

  sidebar: [
    {
      name: "Dashboard",
      link: "/counter/dashboard",
      content: <BillingCounterDashboardPage />
    },
    {
      header: "Voucher",
      icon: FaReceipt,
      pages: [
        {
          name: "Create Payment Voucher",
          link: "/counter/create-payment-voucher",
          content: <CreatePaymentVoucherPage />
        }
      ]
    },
    {
      header: "Reports",
      icon: FaHistory,
      pages: [
        {
          name: "Shift Quantity Report",
          link: "/counter/shift-quantity-report",
          content: <ShiftQuantityReportPage />
        },
        {
          name: "Daily Cash Collection Report",
          link: "/counter/daily-cash-collection-report",
          content: <DailyCashCollectionReportPage />
        },
        {
          name: "Payment Voucher History",
          link: "/counter/voucher/payment/history",
          content: <PaymentVoucherHistoryPage />
        }
      ]
    }
  ]
};
