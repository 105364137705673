import { IDashboard, IPage } from "app/types";
import { authRoutes as authModuleRoutes } from "modules/auth/routes";
import { counterDashboard } from "modules/counter/routes";
import { adminDashboard } from "modules/admin/routes";
import { departmentDashboard } from "modules/department/routes";

// Routes that are publicly available (only if not authenticated)
export const authRoutes: IPage[] = [...authModuleRoutes];

// Routes that are privately available (only if authenticated)
export const dashboardList: IDashboard[] = [
  counterDashboard,
  adminDashboard,
  departmentDashboard
];

// Routes that are always available (both authenticated or not authenticated)
export const publicRoutes: IPage[] = [];

// Starting route
export const rootRoute = "/login";
