import React from "react";
import { Stack } from "@chakra-ui/react";
import { DailyCashCollectionReportComponent } from "../components/daily-cash-collection.component";

interface DailyCashCollectionReportPageProps {}

export const DailyCashCollectionReportPage: React.FC<
  DailyCashCollectionReportPageProps
> = (props: DailyCashCollectionReportPageProps) => {
  return (
    <Stack>
      <DailyCashCollectionReportComponent />
    </Stack>
  );
};
