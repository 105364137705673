import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { api } from "app/api";
import { invalidateAuth } from "app/api/invalidate-auth.middleware";
import { rtkqLogger } from "app/api/logger.middleware";
import { rtkqToast } from "app/api/toast.middleware";
import { expenseVoucherSlice } from "modules/counter/state/expense-voucher.slice";
import { paymentVoucherSlice } from "modules/counter/state/payment-voucher.slice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    paymentVoucher: paymentVoucherSlice.reducer,
    expenseVoucher: expenseVoucherSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      rtkqToast,
      rtkqLogger,
      invalidateAuth
    )
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
