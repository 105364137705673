import { LogoComponent } from "app/components/logo.component";
import { env } from "app/config";
import { IDashboard } from "app/types";
import { BankTransferPage } from "modules/counter/pages/bank-transfer.page";
import { CashbookPage } from "modules/counter/pages/cashbook.page";
import { CreateBankExpenseVoucherPage } from "modules/counter/pages/create-bank-expense-voucher.page";
import { CreateExpenseVoucherPage } from "modules/counter/pages/create-expense-voucher.page";
import { ExpenseVoucherHistoryPage } from "modules/counter/pages/expense-voucher-history.page";
import { MonthlyIncomeReportPage } from "modules/counter/pages/monthly-income-report.page";
import { MonthlyLedgerBookPage } from "modules/counter/pages/monthly-ledger-book.page";
import { MonthlyQuantityReportPage } from "modules/counter/pages/monthly-quantity-report.page";
import { PaymentVoucherHistoryPage } from "modules/counter/pages/payment-voucher-history.page";
import { TransferVoucherHistoryPage } from "modules/counter/pages/transfer-voucher-history.page";
import { UserFeePage } from "modules/counter/pages/user-fee.page";
import { VoucherDetailsPage } from "modules/counter/pages/voucher-details.page";
import { IncomeExpenseReportPage } from "modules/department/pages/income-expense-report.page";
import { FaHistory, FaReceipt, FaSyringe } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { IncomeExpenseReportComponent } from "../components/income-expense-report.component";
import { CreateDoctorDesignationPage } from "../pages/create-doctor-designation.page";
import { CreateDoctorPage } from "../pages/create-doctor.page";
import { EditCommissionRatesPage } from "../pages/edit-commission-rates.page";
import { EditInvestigationsPage } from "../pages/edit-investigations.page";
import { InvestigationQuantityInsightPage } from "../pages/investigation-quantity-insight.page";
import { ViewDoctorsCommissionPage } from "../pages/view-doctors-commission.page";

export const departmentDashboard: IDashboard = {
  dashboardName: "department",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Dashboard",
      link: "/department/dashboard",
      content: <IncomeExpenseReportComponent />
    },
    {
      name: "Create Expense Voucher",
      link: "/department/create-expense-voucher",
      content: <CreateExpenseVoucherPage />
    },
    {
      name: "Create Bank Expense Voucher",
      link: "/department/create-bank-expense-voucher",
      content: <CreateBankExpenseVoucherPage />
    },
    {
      name: "Bank Transfer",
      link: "/department/bank-transfer",
      content: <BankTransferPage />
    },
    {
      name: "Voucher Details",
      link: "/department/voucher",
      content: <VoucherDetailsPage />
    },
    {
      name: "Payment Voucher History",
      link: "/department/voucher/payment/history",
      content: <PaymentVoucherHistoryPage />
    },
    {
      name: "Expense History",
      link: "/department/voucher/expense/history",
      content: <ExpenseVoucherHistoryPage />
    },
    {
      name: "Bank Transfer History",
      link: "/department/voucher/bank-transfer/history",
      content: <TransferVoucherHistoryPage />
    },

    {
      name: "Monthly Quantity Report",
      link: "/department/monthly-quantity-report",
      content: <MonthlyQuantityReportPage />
    },
    {
      name: "Monthly Income Report",
      link: "/department/monthly-income-report",
      content: <MonthlyIncomeReportPage />
    },
    {
      name: "Monthly Cashbook",
      link: "/department/cashbook",
      content: <CashbookPage />
    },
    {
      name: "Monthly Ledger Book",
      link: "/department/monthly-ledger-book",
      content: <MonthlyLedgerBookPage />
    },
    {
      name: "User Fee Distribution",
      link: "/department/user-fee-distribution",
      content: <UserFeePage />
    },
    {
      name: "Income-Expense Insight",
      link: "/department/income-expense-insight",
      content: <IncomeExpenseReportPage />
    },
    {
      name: "Quantity Insight",
      link: "/department/quantity-insight",
      content: <InvestigationQuantityInsightPage />
    },
    {
      name: "Edit Investigations",
      link: "department/investigations/edit",
      content: <EditInvestigationsPage />
    },

    ...(env.doctorReference
      ? [
          {
            name: "Doctor Commissions",
            link: "department/doctor/commission/list",
            content: <ViewDoctorsCommissionPage />
          },
          {
            name: "Commission Rates",
            link: "/department/doctor/commission/rates",
            content: <EditCommissionRatesPage />
          },
          {
            name: "Income-Expense Insight",
            link: "/department/income-expense-insight",
            content: <IncomeExpenseReportComponent />
          },
          {
            name: "Create Designation",
            link: "department/doctor/designation/new",
            content: <CreateDoctorDesignationPage />
          },
          {
            name: "Create Doctor",
            link: "department/doctor/new",
            content: <CreateDoctorPage />
          }
        ]
      : [])
  ],

  sidebar: [
    {
      name: "Dashboard",
      link: "/department/dashboard",
      content: <PaymentVoucherHistoryPage />
    },
    ...(env.deploymentType === "lab-billing"
      ? [
          {
            header: "Voucher",
            icon: FaReceipt,
            pages: [
              {
                name: "Create Expense Voucher",
                link: "/department/create-expense-voucher",
                content: <CreateExpenseVoucherPage />
              },
              {
                name: "Create Bank Expense Voucher",
                link: "/department/create-bank-expense-voucher",
                content: <CreateBankExpenseVoucherPage />
              },
              {
                name: "Bank Transfer",
                link: "/department/bank-transfer",
                content: <BankTransferPage />
              }
            ]
          }
        ]
      : []),
    ...(env.doctorReference
      ? [
          {
            header: "Doctors",
            icon: FaUserDoctor,
            pages: [
              {
                name: "Create Designation",
                link: "department/doctor/designation/new",
                content: <CreateDoctorDesignationPage />
              },
              {
                name: "Create Doctor",
                link: "department/doctor/new",
                content: <CreateDoctorPage />
              },
              {
                name: "View Commissions",
                link: "/department/doctor/commission/list",
                content: <ViewDoctorsCommissionPage />
              },
              {
                name: "Commission Rates",
                link: "/department/doctor/commission/rates",
                content: <EditCommissionRatesPage />
              }
            ]
          }
        ]
      : []),
    {
      header: "Investigations",
      icon: FaSyringe,
      pages: [
        {
          name: "Edit Investigations",
          link: "department/investigations/edit",
          content: <EditInvestigationsPage />
        }
      ]
    },
    {
      header: "Reports",
      icon: FaHistory,
      pages: [
        ...(env.deploymentType === "lab-billing"
          ? [
              {
                name: "Expense History",
                link: "/department/voucher/expense/history",
                content: <ExpenseVoucherHistoryPage />
              },
              {
                name: "Bank Transfer History",
                link: "/department/voucher/bank-transfer/history",
                content: <TransferVoucherHistoryPage />
              }
            ]
          : []),

        {
          name: "Payment Voucher History",
          link: "/department/voucher/payment/history",
          content: <PaymentVoucherHistoryPage />
        },
        {
          name: "Monthly Income Report",
          link: "/department/monthly-income-report",
          content: <MonthlyIncomeReportPage />
        },
        ...(env.deploymentType === "lab-billing"
          ? [
              {
                name: "Monthly Cashbook",
                link: "/department/cashbook",
                content: <CashbookPage />
              },
              {
                name: "Monthly Ledger Book",
                link: "/department/monthly-ledger-book",
                content: <MonthlyLedgerBookPage />
              }
            ]
          : []),

        {
          name: "Monthly Quantity Report",
          link: "/department/monthly-quantity-report",
          content: <MonthlyQuantityReportPage />
        },
        {
          name: "Income-Expense Insight",
          link: "/department/income-expense-insight",
          content: <IncomeExpenseReportPage />
        },

        ...(env.deploymentType === "lab-billing"
          ? [
              {
                name: "User Fee Distribution",
                link: "/department/user-fee-distribution",
                content: <UserFeePage />
              }
            ]
          : []),

        {
          name: "Quantity Insight",
          link: "/department/quantity-insight",
          content: <InvestigationQuantityInsightPage />
        }
      ]
    }
  ]
};
