import React from "react";
import { Stack } from "@chakra-ui/react";
import { TransactionHistoryComponent } from "../components/transaction-history.component";

interface TransactionHistoryPageProps {}

export const TransactionHistoryPage: React.FC<TransactionHistoryPageProps> = (
  props: TransactionHistoryPageProps
) => {
  return (
    <Stack>
      <TransactionHistoryComponent />
    </Stack>
  );
};
