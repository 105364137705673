import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Expense, VoucherRecord } from "app/api/type";

interface ExpenseVoucherState {
  voucherEntries: {
    voucherRecord: Omit<
      VoucherRecord,
      "id" | "voucherId" | "investigationId" | "discountPercentage"
    >;
    expense: Expense;
  }[];
}

const initialState = {
  voucherEntries: []
} as ExpenseVoucherState;

export const expenseVoucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    addVoucherRecord: (
      state,
      action: PayloadAction<{
        voucherRecord: Omit<
          VoucherRecord,
          "id" | "voucherId" | "investigationId" | "discountPercentage"
        >;
        expense: Expense;
      }>
    ) => {
      state.voucherEntries.push(action.payload);
      return state;
    },

    deleteVoucherRecord: (state, action: PayloadAction<{ index: number }>) => {
      state.voucherEntries.splice(action.payload.index, 1);
      return state;
    },

    resetCounterState: () => {
      return initialState;
    }
  }
});

export const { addVoucherRecord, deleteVoucherRecord, resetCounterState } =
  expenseVoucherSlice.actions;
