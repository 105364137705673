import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spacer,
  Stack,
  Text
} from "@chakra-ui/react";
import { FormEvent } from "react";
import {
  useGetDoctorsDesignationsQuery,
  useEditCommissionRatesForInvestigationsMutation
} from "../api";

interface Props {
  selectedInvestigations: string[];
  onClose: () => void;
}

export const EditableDesignationRatesCardComponent = (props: Props) => {
  const getDesignationsResults = useGetDoctorsDesignationsQuery({});

  const [
    editCommissionRatesForInvestigations,
    editCommissionRatesForInvestigationsResult
  ] = useEditCommissionRatesForInvestigationsMutation();

  const handleSave = async (event: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget);

    const commissionRates: {
      designationName: string;
      percentage: number | null;
    }[] = [];
    for (let [key, value] of formData.entries()) {
      commissionRates.push({
        designationName: key,
        percentage: value ? Number(value) : null
      });
    }

    await editCommissionRatesForInvestigations({
      investigationIds: props.selectedInvestigations,
      commissionRates
    });

    props.onClose();
  };

  return (
    <Stack>
      <ModalHeader>Commission Rates</ModalHeader>
      <ModalBody>
        <Stack>
          <form onSubmit={handleSave}>
            {getDesignationsResults.isSuccess &&
              getDesignationsResults.data.designations.map((designation) => {
                return (
                  <Flex key={designation.name} marginBottom={6}>
                    <Text flex={2}>{designation.name} </Text>

                    <InputGroup flex={2}>
                      <Input
                        type="number"
                        step=".01"
                        max="100"
                        min="0"
                        name={designation.name}
                        placeholder="Unchanged"
                      />
                      <InputRightAddon children="%" />
                    </InputGroup>
                  </Flex>
                );
              })}
            <ModalFooter paddingX={0}>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={editCommissionRatesForInvestigationsResult.isLoading}
                size="md"
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </Stack>
      </ModalBody>
    </Stack>
  );
};
