import React from "react";
import dayjs from "dayjs";
import { env } from "app/config";
import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image
} from "@react-pdf/renderer";
import { GetVoucherListResponse } from "modules/counter/api/type";

interface PrintExpenseHistoryPageProps {
  expenseHistory: GetVoucherListResponse;
  month: string;
  year: string;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24,
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    textAlign: "center"
  },
  title: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 4,
    textAlign: "center"
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 4,
    textAlign: "center"
  },
  table: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#dfdfdf",
    border: "0.5px solid #dfdfdf",
    fontWeight: "bold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    border: "0.5px solid #dfdfdf"
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    border: "0.5px solid #efefef"
  },
  serial: {
    width: "10%",
    padding: "1px 5px"
  },
  date: {
    width: "25%",
    padding: "1px"
  },
  transactionId: {
    width: "25%",
    padding: "1px"
  },
  baseCost: {
    width: "15%",
    padding: "1px"
  },
  vat: {
    width: "15%",
    padding: "1px"
  },
  total: {
    width: "10%",
    padding: "1px"
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    fontSize: 9,
    fontWeight: "bold",
    borderBottom: "1px solid #dfdfdf"
  }
});

export const PrintExpenseHistoryPage: React.FC<PrintExpenseHistoryPageProps> = (
  props: PrintExpenseHistoryPageProps
) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <Image style={styles.logo} src={env.governmentLogo} />
              <View style={{ paddingHorizontal: "25px" }}>
                <View>
                  <Text style={{ fontSize: 11, alignSelf: "center" }}>
                    {env.voucherTitle}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 9, alignSelf: "center" }}>
                    {env.hospitalName}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.hospitalLogo} />
            </View>
            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>Expense History Report</Text>
              <Text style={styles.subTitle}>
                {props.month} {props.year}
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              ></View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.serial}>Serial</Text>
                <Text style={styles.date}>Date</Text>
                <Text style={styles.transactionId}>Transaction ID</Text>
                <Text style={styles.baseCost}>Base Cost</Text>
                <Text style={styles.vat}>VAT</Text>
                <Text style={styles.total}>Total</Text>
              </View>
              {props.expenseHistory.vouchersWithDetails.map(
                (voucherDetails, index) => (
                  <View style={styles.itemRow} key={index} wrap={false}>
                    <Text style={styles.serial}>{index + 1}</Text>
                    <Text style={styles.date}>
                      {dayjs(voucherDetails.voucher.createdAt).format(
                        "DD MMM, YYYY"
                      )}
                    </Text>
                    <Text style={styles.transactionId}>
                      {voucherDetails.voucher.id}
                    </Text>
                    <Text style={styles.baseCost}>
                      ৳{" "}
                      {voucherDetails.voucherRecords
                        .reduce(
                          (totalBasePrice, currentVoucherRecord) =>
                            totalBasePrice + currentVoucherRecord.basePrice,
                          0
                        )
                        .toFixed(2)}
                    </Text>
                    <Text style={styles.vat}>
                      ৳{" "}
                      {voucherDetails.voucherRecords
                        .reduce(
                          (totalVat, currentVoucherRecord) =>
                            totalVat +
                            currentVoucherRecord.totalPrice -
                            currentVoucherRecord.basePrice,
                          0
                        )
                        .toFixed(2)}
                    </Text>
                    <Text style={styles.total}>
                      ৳ {voucherDetails.voucher.paidAmount}
                    </Text>
                  </View>
                )
              )}
            </View>
          </View>

          <View>
            <View
              style={{
                borderTop: "1px solid #000"
              }}
            >
              <View style={[styles.tableFooter]} fixed>
                <View style={[styles.row, styles.tableHeader]} fixed>
                  <Text style={styles.serial}>Total :</Text>
                  <Text style={styles.date}></Text>
                  <Text style={styles.transactionId}></Text>
                  <Text style={styles.baseCost}>
                    ৳{" "}
                    {props.expenseHistory.vouchersWithDetails
                      .reduce(
                        (totalBasePrice, currentVoucher) =>
                          totalBasePrice +
                          currentVoucher.voucherRecords.reduce(
                            (voucherRecordBasePrice, currentVoucherRecord) =>
                              voucherRecordBasePrice +
                              currentVoucherRecord.basePrice,
                            0
                          ),
                        0
                      )
                      .toFixed(2)}
                  </Text>
                  <Text style={styles.vat}>
                    ৳{" "}
                    {props.expenseHistory.vouchersWithDetails
                      .reduce(
                        (totalVat, currentVoucher) =>
                          totalVat +
                          currentVoucher.voucherRecords.reduce(
                            (voucherRecordVat, currentVoucherRecord) =>
                              voucherRecordVat +
                              currentVoucherRecord.totalPrice -
                              currentVoucherRecord.basePrice,
                            0
                          ),
                        0
                      )
                      .toFixed(2)}
                  </Text>
                  <Text style={styles.total}>
                    ৳{" "}
                    {props.expenseHistory.vouchersWithDetails
                      .reduce(
                        (totalExpense, currentVoucher) =>
                          totalExpense +
                          currentVoucher.voucherRecords.reduce(
                            (voucherRecordtotal, currentVoucherRecord) =>
                              voucherRecordtotal +
                              currentVoucherRecord.totalPrice,
                            0
                          ),
                        0
                      )
                      .toFixed(2)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View fixed>
          <View
            style={{
              borderTop: "1px solid #000"
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <Text style={{ fontSize: 8, color: "#666666" }}>
                Powered By: BinduLogic Limited
              </Text>
              <Text
                style={{ fontSize: 8, color: "#666666" }}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
                fixed
              />
              <Text style={{ fontSize: 8, color: "#666666" }}>
                Printed By:{" "}
                {window.localStorage.getItem("locationName") || "Unknown"},{" "}
                {dayjs().format("DD MMM YYYY, hh:mm A")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
