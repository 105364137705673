import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Button,
  SimpleGrid,
  GridItem,
  InputLeftAddon,
  InputGroup,
  useToast
} from "@chakra-ui/react";
import { Designation } from "app/api/type";
import { SingleValue, Select } from "chakra-react-select";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  useCreateDoctorMutation,
  useGetDoctorsDesignationsQuery
} from "../api";

interface DesignationOption {
  label: string;
  value: Designation;
}

export const CreateDoctorComponent: FC = () => {
  const toast = useToast();

  const doctorsDesignationResult = useGetDoctorsDesignationsQuery({});

  const [createDoctor, createDoctorResult] = useCreateDoctorMutation();

  const [formInfo, setFormInfo] = useState<{
    bmdcNumber: string;
    name: string;
    contactNumber: string;
    email: string;
    designation: Designation;
    password: string;
    confirmPassword: string;
  }>({
    bmdcNumber: "",
    name: "",
    contactNumber: "",
    email: "",
    designation: {
      name: "",
      isDesignationForDoctor: true
    },
    password: "123456789",
    confirmPassword: "123456789"
  });

  const [designationOptions, setDesignationOptions] =
    useState<DesignationOption[]>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormInfo({
      ...formInfo,
      [event.target.name]: event.target.value
    });
  };

  const [selectedDesignation, setSelectedDesignation] =
    useState<DesignationOption>();

  const handleDesignationSelection = (
    event: SingleValue<DesignationOption>
  ) => {
    if (event) {
      setSelectedDesignation({
        label: event.label,
        value: event.value
      });
      setFormInfo({
        ...formInfo,
        designation: event.value
      });
    }
  };

  const validateEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = async () => {
    if (
      !validateEmail(formInfo.email) ||
      !formInfo.name ||
      !formInfo.bmdcNumber ||
      formInfo.contactNumber.length !== 11 ||
      !formInfo.designation.name
    ) {
      toast({
        title: "Please enter the proper information",
        position: "top",
        status: "error",
        duration: 4000
      });
      return;
    }

    if (formInfo.password !== formInfo.confirmPassword) {
      // Passwords do not match issue
      toast({
        title: "Passwords do not match",
        position: "top",
        status: "error",
        duration: 4000
      });
      return;
    }

    await createDoctor({
      doctor: {
        bmdcNumber: formInfo.bmdcNumber
      },
      user: {
        email: formInfo.email,
        password: formInfo.password,
        designationName: formInfo.designation.name
      },
      person: {
        name: formInfo.name,
        contactNumber: formInfo.contactNumber
      }
    });

    setFormInfo({
      bmdcNumber: "",
      name: "",
      contactNumber: "",
      email: "",
      designation: {
        name: "",
        isDesignationForDoctor: true
      },
      password: "123456",
      confirmPassword: "123456"
    });
    setSelectedDesignation({
      label: "",
      value: {
        name: "",
        isDesignationForDoctor: true
      }
    });
  };

  useEffect(() => {
    if (doctorsDesignationResult.isSuccess) {
      setDesignationOptions(
        doctorsDesignationResult.data.designations.map(
          (designation: Designation) => {
            return {
              label: designation.name,
              value: designation
            };
          }
        )
      );
    }
  }, [doctorsDesignationResult]);

  useEffect(() => {
    if (createDoctorResult.isSuccess) {
      toast({
        title: "Doctor Created Successfully",
        position: "top",
        status: "success",
        duration: 4000
      });
    }
    if (createDoctorResult.isError) {
      toast({
        title: "There was an error creating the doctor",
        position: "top",
        status: "error",
        duration: 4000
      });
    }
  }, [createDoctorResult, toast]);

  return (
    <Stack>
      <PageCardComponent>
        <Stack spacing={4}>
          <SimpleGrid
            columns={{ base: 10 }}
            rowGap={4}
            columnGap={4}
            alignItems="center"
          >
            <GridItem colSpan={10}>
              <FormControl isInvalid={!formInfo.name} isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event);
                  }}
                  value={formInfo.name}
                />
                <FormErrorMessage>Enter a valid name</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={5}>
              <FormControl isInvalid={!formInfo.bmdcNumber} isRequired>
                <FormLabel>BMDC Number</FormLabel>
                <Input
                  type="text"
                  name="bmdcNumber"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event);
                  }}
                  value={formInfo.bmdcNumber}
                />
                <FormErrorMessage>Enter a valid BMDC Number</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={5}>
              <FormControl isInvalid={!formInfo.designation.name} isRequired>
                <FormLabel>Designation</FormLabel>
                <Select
                  value={selectedDesignation}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                  options={designationOptions}
                  onChange={handleDesignationSelection}
                />
                <FormErrorMessage>Select a valid designation</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={5}>
              <FormControl
                isInvalid={formInfo.contactNumber.length !== 11}
                isRequired
              >
                <FormLabel>Contact Number</FormLabel>
                <InputGroup>
                  <InputLeftAddon children="+88" />
                  <Input
                    type="number"
                    name="contactNumber"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(event);
                    }}
                    value={formInfo.contactNumber}
                  />
                </InputGroup>
                <FormErrorMessage>Enter a valid Phone Number</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={5}>
              <FormControl
                isInvalid={!validateEmail(formInfo.email)}
                isRequired
              >
                <FormLabel>Email</FormLabel>
                <Input
                  type="text"
                  name="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event);
                  }}
                  value={formInfo.email}
                />
                <FormErrorMessage>Enter a valid Email</FormErrorMessage>
              </FormControl>
            </GridItem>
          </SimpleGrid>
          <Button mt={4} type="submit" onClick={handleSubmit}>
            Create
          </Button>
        </Stack>
      </PageCardComponent>
    </Stack>
  );
};
