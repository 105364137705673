import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Investigation, VoucherRecord } from "app/api/type";

interface PaymentVoucherState {
  voucherEntries: {
    voucherRecord: Omit<
      VoucherRecord,
      "id" | "voucherId" | "expenseId" | "taxPercentage"
    >;
    investigation: Investigation;
  }[];
}

const initialState = {
  voucherEntries: []
} as PaymentVoucherState;

export const paymentVoucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    addVoucherRecord: (
      state,
      action: PayloadAction<{
        voucherRecord: Omit<
          VoucherRecord,
          "id" | "voucherId" | "expenseId" | "taxPercentage"
        >;
        investigation: Investigation;
      }>
    ) => {
      state.voucherEntries.push(action.payload);
      return state;
    },

    deleteVoucherRecord: (state, action: PayloadAction<{ index: number }>) => {
      state.voucherEntries.splice(action.payload.index, 1);
      return state;
    },

    resetCounterState: () => {
      return initialState;
    }
  }
});

export const { addVoucherRecord, deleteVoucherRecord, resetCounterState } =
  paymentVoucherSlice.actions;
