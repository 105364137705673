import { LogoComponent } from "app/components/logo.component";
import { IDashboard } from "app/types";
import { PlaceholderPage } from "modules/core/pages/placeholder.page";

export const adminDashboard: IDashboard = {
  dashboardName: "admin",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Dashboard",
      link: "/admin/dashboard",
      content: <PlaceholderPage />
    }
  ],
  sidebar: [
    {
      name: "Dashboard",
      link: "/admin/dashboard",
      content: <PlaceholderPage />
    }
  ]
};
