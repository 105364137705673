import React from "react";
import { Stack } from "@chakra-ui/react";
import { BankTransferComponent } from "../components/bank-transfer.component";

interface BankTransferPageProps {}

export const BankTransferPage: React.FC<BankTransferPageProps> = (
  props: BankTransferPageProps
) => {
  return (
    <Stack>
      <BankTransferComponent />
    </Stack>
  );
};
