import React from "react";
import dayjs from "dayjs";
import { env } from "app/config";
import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image
} from "@react-pdf/renderer";
import { GetLedgerBookResponse } from "modules/counter/api/type";

interface PrintMonthlyLedgerBookPageProps {
  monthlyLedgerReport: GetLedgerBookResponse;
  month: string;
  year: string;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24,
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    textAlign: "center"
  },
  title: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 4,
    textAlign: "center"
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 4,
    textAlign: "center"
  },
  table: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#dfdfdf",
    border: "0.5px solid #dfdfdf",
    fontWeight: "bold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    border: "0.5px solid #dfdfdf"
  },
  source: {
    width: "25%",
    padding: "1px"
  },
  expenditure: {
    width: "25%",
    padding: "1px"
  },
  cash: {
    width: "25%",
    padding: "1px"
  },
  bank: {
    width: "25%",
    padding: "1px"
  },
  total: {
    width: "25%",
    padding: "1px"
  },
  label: {
    width: "50%",
    padding: "1px"
  },
  amount: {
    width: "50%",
    padding: "1px"
  }
});

export const PrintMonthlyLedgerBookPage: React.FC<
  PrintMonthlyLedgerBookPageProps
> = (props: PrintMonthlyLedgerBookPageProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <Image style={styles.logo} src={env.governmentLogo} />
              <View style={{ paddingHorizontal: "25px" }}>
                <View>
                  <Text style={{ fontSize: 11, alignSelf: "center" }}>
                    {env.voucherTitle}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 9, alignSelf: "center" }}>
                    {env.hospitalName}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.hospitalLogo} />
            </View>

            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>Monthly Ledger Book Report</Text>
              <Text style={styles.subTitle}>
                {props.month} {props.year}
              </Text>
            </View>

            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>
                Income & Bank Deposit ({props.month} {props.year})
              </Text>
            </View>

            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              ></View>
            </View>

            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.source}>Source</Text>
                <Text style={styles.cash}>Cash</Text>
                <Text style={styles.bank}>Bank</Text>
                <Text style={styles.total}>Total</Text>
              </View>
              {props.monthlyLedgerReport.report["Income & Bank Deposit"].map(
                (row, index) => (
                  <View style={styles.row} key={index} wrap={false}>
                    <Text style={styles.source}>{row.Source}</Text>
                    <Text style={styles.cash}>৳ {row.Cash}</Text>
                    <Text style={styles.bank}>৳ {row.Bank}</Text>
                    <Text style={styles.total}>৳ {row.Total}</Text>
                  </View>
                )
              )}
            </View>

            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>
                Bank Deposit & Expenditure ({props.month} {props.year})
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              ></View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.expenditure}>Expenditure</Text>
                <Text style={styles.cash}>Cash</Text>
                <Text style={styles.bank}>Bank</Text>
                <Text style={styles.total}>Total</Text>
              </View>
              {props.monthlyLedgerReport.report[
                "Bank Deposit & Expenditure"
              ].map((row, index) => (
                <View style={styles.row} key={index} wrap={false}>
                  <Text style={styles.expenditure}>{row.Expenditure}</Text>
                  <Text style={styles.cash}>৳ {row.Cash}</Text>
                  <Text style={styles.bank}>৳ {row.Bank}</Text>
                  <Text style={styles.total}>৳ {row.Total}</Text>
                </View>
              ))}
            </View>

            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>
                Cash Ledger ({props.month} {props.year})
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              ></View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.label}>Label</Text>
                <Text style={styles.amount}>Amount</Text>
              </View>
              {props.monthlyLedgerReport.report["Cash Ledger"].map(
                (row, index) => (
                  <View style={styles.row} key={index} wrap={false}>
                    <Text style={styles.label}>{row.Source}</Text>
                    <Text style={styles.amount}>৳ {row.Amount}</Text>
                  </View>
                )
              )}
            </View>

            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>
                Bank Ledger ({props.month} {props.year})
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              ></View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.label}>Label</Text>
                <Text style={styles.amount}>Amount</Text>
              </View>
              {props.monthlyLedgerReport.report["Bank Ledger"].map(
                (row, index) => (
                  <View style={styles.row} key={index} wrap={false}>
                    <Text style={styles.label}>{row.Source}</Text>
                    <Text style={styles.amount}>৳ {row.Amount}</Text>
                  </View>
                )
              )}
            </View>
          </View>
        </View>

        <View fixed>
          <View
            style={{
              borderTop: "1px solid #000"
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <Text style={{ fontSize: 8, color: "#666666" }}>
                Powered By: BinduLogic Limited
              </Text>
              <Text
                style={{ fontSize: 8, color: "#666666" }}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
                fixed
              />
              <Text style={{ fontSize: 8, color: "#666666" }}>
                Printed By:{" "}
                {window.localStorage.getItem("locationName") || "Unknown"},{" "}
                {dayjs().format("DD MMM YYYY, hh:mm A")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
