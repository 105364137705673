import React from "react";
import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image
} from "@react-pdf/renderer";
import { GetCashbookResponse } from "modules/counter/api/type";
import { env } from "app/config";
import dayjs from "dayjs";

interface PrintCashbookReportPageProps {
  cashbookHistory: GetCashbookResponse;
  month: string;
  year: string;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24,
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    textAlign: "center"
  },
  title: {
    fontSize: 16,
    marginTop: 4,
    marginBottom: 8,
    textAlign: "center"
  },
  subTitle: {
    fontSize: 12,
    marginTop: 4,
    marginBottom: 4,
    textAlign: "center"
  },
  table: {
    marginTop: "8px",
    width: "100%",
    border: "0.5px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#dfdfdf",
    border: "0.5px solid #dfdfdf",
    fontWeight: "bold"
  },
  tableFooter: {
    borderBottom: "1px solid #dfdfdf",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    fontSize: 9,
    alignItems: "center",
    backgroundColor: "#dfdfdf"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    fontSize: 9,
    border: "0.5px solid #dfdfdf"
  },
  date: {
    width: "15%",
    padding: "1px 5px"
  },
  openingBalance: {
    width: "12.5%",
    padding: "1px"
  },
  income: {
    width: "12.5%",
    padding: "1px"
  },
  totalIncome: {
    width: "12.5%",
    padding: "1px"
  },
  expense: {
    width: "12.5%",
    padding: "1px"
  },
  bankTransfer: {
    width: "12.5%",
    padding: "1px"
  },
  totalExpense: {
    width: "12.5%",
    padding: "1px"
  },
  closingBalance: {
    width: "12.5%",
    padding: "1px"
  }
});

export const PrintCashbookReportPage: React.FC<PrintCashbookReportPageProps> = (
  props: PrintCashbookReportPageProps
) => {
  const cashbookCalculation = Object.entries(props.cashbookHistory.report);

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.body}>
        <View
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <Image style={styles.logo} src={env.governmentLogo} />
              <View style={{ paddingHorizontal: "25px" }}>
                <View>
                  <Text style={{ fontSize: 11, alignSelf: "center" }}>
                    {env.voucherTitle}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 9, alignSelf: "center" }}>
                    {env.hospitalName}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.hospitalLogo} />
            </View>
            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>Monthly Cashbook Report</Text>
              <Text style={styles.subTitle}>
                {props.month}, {props.year}
              </Text>
            </View>
            <View>
              <View
                style={{
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              ></View>
            </View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]} fixed>
                <Text style={styles.date}>Date</Text>
                <Text style={styles.openingBalance}>Opening Balance</Text>
                <Text style={styles.income}>Income</Text>
                <Text style={styles.totalIncome}>Total Income</Text>
                <Text style={styles.expense}>Expense</Text>
                <Text style={styles.bankTransfer}>Bank Transfer</Text>
                <Text style={styles.totalExpense}>Total Expense</Text>
                <Text style={styles.closingBalance}>Closing Balance</Text>
              </View>
              {Object.entries(props.cashbookHistory.report).map(
                ([key, row]) => {
                  return (
                    <View style={[styles.row]}>
                      <Text style={styles.date}>
                        {dayjs(key).format("DD MMM, YYYY")}
                      </Text>
                      <Text style={styles.openingBalance}>
                        ৳ {row.openingBalance}
                      </Text>
                      <Text style={styles.income}>৳ {row.income}</Text>
                      <Text style={styles.totalIncome}>
                        ৳ {row.totalIncome}
                      </Text>
                      <Text style={styles.expense}>৳ {row.expense}</Text>
                      <Text style={styles.bankTransfer}>৳ {row.transfer}</Text>
                      <Text style={styles.totalExpense}>
                        ৳ {row.totalExpense}
                      </Text>
                      <Text style={styles.closingBalance}>
                        ৳ {row.closingBalance}
                      </Text>
                    </View>
                  );
                }
              )}
            </View>
            <View style={[styles.tableFooter]} fixed>
              <Text style={styles.date}>Total :</Text>
              <Text style={styles.openingBalance}></Text>
              <Text style={styles.income}>
                ৳{" "}
                {cashbookCalculation.reduce(
                  (income, reportEntry) => income + reportEntry[1].income,
                  0
                )}
              </Text>
              <Text style={styles.totalIncome}>
                ৳{" "}
                {cashbookCalculation.reduce(
                  (totalIncome, reportEntry) =>
                    totalIncome + reportEntry[1].totalIncome,
                  0
                )}
              </Text>
              <Text style={styles.expense}>
                ৳{" "}
                {cashbookCalculation.reduce(
                  (expense, reportEntry) => expense + reportEntry[1].expense,
                  0
                )}
              </Text>
              <Text style={styles.bankTransfer}>
                ৳{" "}
                {cashbookCalculation.reduce(
                  (transfer, reportEntry) => transfer + reportEntry[1].transfer,
                  0
                )}
              </Text>
              <Text style={styles.totalExpense}>
                ৳{" "}
                {cashbookCalculation.reduce(
                  (totalExpense, reportEntry) =>
                    totalExpense + reportEntry[1].totalExpense,
                  0
                )}
              </Text>
              <Text style={styles.closingBalance}> </Text>
            </View>
          </View>
          <View>
            <View
              style={{
                borderTop: "1px solid #000"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Text style={{ fontSize: 8, color: "#666666" }}>
                  Powered By: BinduLogic Limited
                </Text>
                <Text style={{ fontSize: 8, color: "#666666" }}>
                  Printed By:{" "}
                  {window.localStorage.getItem("locationName") || "Unknown"},{" "}
                  {dayjs().format("DD MMM YYYY, hh:mm A")}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
