import {
  Center,
  FormControl,
  FormLabel,
  Spinner,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { useEffect, useState } from "react";
import { monthList, yearList } from "utils/date";
import { useLazyGetCashbookQuery } from "../api";
import { PrintCashbookReportComponent } from "../print/components/print-cashbook-report.component";

interface CashbookComponentProps {}

export const CashbookComponent: React.FC<CashbookComponentProps> = (
  props: CashbookComponentProps
) => {
  const [getCashbook, getCashbookResult] = useLazyGetCashbookQuery();

  const [selectedYear, setSelectedYear] = useState<{
    label: string;
    value: number;
  }>({
    label: dayjs().year().toString(),
    value: dayjs().year()
  });

  const [selectedMonth, setSelectedMonth] = useState<{
    label: string;
    value: number;
  }>(monthList[dayjs().month()]);

  useEffect(() => {
    getCashbook({
      month: selectedMonth.value,
      year: selectedYear.value,
      locationId: ""
    });
  }, [selectedMonth, selectedYear]);

  return (
    <Stack spacing="6">
      <PageCardComponent>
        <Stack direction="row">
          <FormControl id="year">
            <FormLabel>Year</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={yearList}
              value={selectedYear}
              onChange={(event) => {
                if (event) {
                  setSelectedYear({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>

          <FormControl id="month">
            <FormLabel>Month</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={monthList}
              value={selectedMonth}
              onChange={(event) => {
                if (event) {
                  setSelectedMonth({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>
      </PageCardComponent>

      <Stack width="100%">
        {getCashbookResult.data && (
          <Stack alignSelf={"flex-end"}>
            <PrintCashbookReportComponent
              cashbookReport={getCashbookResult.data}
              month={selectedMonth.label}
              year={selectedYear.label}
            />{" "}
          </Stack>
        )}
      </Stack>

      <PageCardComponent>
        <TableContainer>
          <Table variant="simple" size="sm">
            <TableCaption placement="top" pb="6" fontSize={"lg"}>
              Cashbook ({selectedMonth.label}, {selectedYear.label})
            </TableCaption>
            {getCashbookResult.data && !getCashbookResult.isFetching ? (
              <>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th isNumeric>Opening Balance</Th>
                    <Th isNumeric>Income</Th>
                    <Th isNumeric>Total Income</Th>
                    <Th isNumeric>Expense</Th>
                    <Th isNumeric>Bank Transfer</Th>
                    <Th isNumeric>Total Expense</Th>
                    <Th isNumeric>Closing Balance</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {Object.entries(getCashbookResult.data.report).map(
                    ([key, row]) => {
                      return (
                        <Tr>
                          <Td>{key}</Td>
                          <Td isNumeric fontWeight={"bold"}>
                            ৳ {row.openingBalance}
                          </Td>
                          <Td isNumeric>৳ {row.income}</Td>
                          <Td isNumeric fontWeight={"bold"}>
                            ৳ {row.totalIncome}
                          </Td>
                          <Td isNumeric>৳ {row.expense}</Td>
                          <Td isNumeric>৳ {row.transfer}</Td>
                          <Td isNumeric fontWeight={"bold"}>
                            ৳ {row.totalExpense}
                          </Td>
                          <Td isNumeric fontWeight={"bold"}>
                            ৳ {row.closingBalance}
                          </Td>
                        </Tr>
                      );
                    }
                  )}

                  <Tr bgColor="gray.700" color="white" fontWeight={"bold"}>
                    <Td>
                      {selectedMonth.label}, {selectedYear.label}
                    </Td>
                    <Td></Td>
                    <Td isNumeric>
                      ৳{" "}
                      {Object.entries(getCashbookResult.data.report).reduce(
                        (totalIncome, reportEntry) =>
                          totalIncome + reportEntry[1].income,
                        0
                      )}
                    </Td>
                    <Td isNumeric>
                      ৳{" "}
                      {Object.entries(getCashbookResult.data.report).reduce(
                        (totalIncome, reportEntry) =>
                          totalIncome + reportEntry[1].totalIncome,
                        0
                      )}
                    </Td>
                    <Td isNumeric>
                      ৳{" "}
                      {Object.entries(getCashbookResult.data.report).reduce(
                        (totalExpense, reportEntry) =>
                          totalExpense + reportEntry[1].expense,
                        0
                      )}
                    </Td>
                    <Td isNumeric>
                      ৳{" "}
                      {Object.entries(getCashbookResult.data.report).reduce(
                        (totalTransfer, reportEntry) =>
                          totalTransfer + reportEntry[1].transfer,
                        0
                      )}
                    </Td>

                    <Td isNumeric>
                      ৳{" "}
                      {Object.entries(getCashbookResult.data.report).reduce(
                        (totalExpense, reportEntry) =>
                          totalExpense + reportEntry[1].totalExpense,
                        0
                      )}
                    </Td>

                    <Td isNumeric></Td>
                  </Tr>
                </Tbody>
              </>
            ) : (
              <Center width="100%" height="10">
                <Spinner />
              </Center>
            )}
          </Table>
        </TableContainer>
      </PageCardComponent>
    </Stack>
  );
};
