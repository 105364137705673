import React from "react";
import { Stack } from "@chakra-ui/react";
import { InvestigationQuantityInsightComponent } from "../components/investigation-quantity-insight.component";

interface InvestigationQuantityInsightPageProps {}

export const InvestigationQuantityInsightPage: React.FC<
  InvestigationQuantityInsightPageProps
> = (props: InvestigationQuantityInsightPageProps) => {
  return (
    <Stack>
      <InvestigationQuantityInsightComponent />
    </Stack>
  );
};
