import { Center, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { GetDailyCashCollectionReportResponse } from "modules/counter/api/type";
import React from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

interface IncomeSummaryPieChartComponentProps {
  getCashCollectionReport: GetDailyCashCollectionReportResponse;
}

export const IncomeSummaryPieChartComponent: React.FC<
  IncomeSummaryPieChartComponentProps
> = (props: IncomeSummaryPieChartComponentProps) => {
  const income = props.getCashCollectionReport.report.map((item, index) => {
    const shiftStart = dayjs(item.shiftStart).format("h:mm A");
    const shiftEnd = dayjs(item.shiftEnd).format("h:mm A");
    const shiftDuration = shiftStart + " - " + shiftEnd;
    return {
      name: shiftDuration,
      value: item.income
    };
  });

  const voucherQuantity = props.getCashCollectionReport.report.map(
    (item, index) => {
      const shiftStart = dayjs(item.shiftStart).format("h:mm A");
      const shiftEnd = dayjs(item.shiftEnd).format("h:mm A");
      const shiftDuration = shiftStart + " - " + shiftEnd;
      return {
        name: shiftDuration,
        value: item.voucherQuantity
      };
    }
  );

  const colors = ["#FF6464", "#FFBD67", "#5BE7A9"];

  const radian = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * radian);
    const y = cy + radius * Math.sin(-midAngle * radian);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Stack direction={{ base: "column" }}>
      <PageCardComponent>
        <Center>
          <Text fontWeight="semibold">Cash</Text>
        </Center>
        <Center>
          <PieChart width={730} height={300}>
            <Pie
              data={income}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={120}
              fill="#8884d8"
              dataKey="value"
            >
              {income.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Legend />
          </PieChart>{" "}
        </Center>
      </PageCardComponent>

      <PageCardComponent>
        <Center>
          <Text fontWeight="semibold">Voucher Quantity</Text>
        </Center>
        <Center>
          {" "}
          <PieChart width={730} height={300}>
            <Pie
              data={voucherQuantity}
              color="#000000"
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={120}
              fill="#8884d8"
            >
              {voucherQuantity.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>{" "}
        </Center>
      </PageCardComponent>
    </Stack>
  );
};
