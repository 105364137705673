import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "app/config";

const baseQuery = fetchBaseQuery({
  baseUrl: env.apiEndpoint,
  credentials: "include",
  prepareHeaders: (headers, { getState }: any) => {
    const accessToken = window.localStorage.getItem("jwt");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    const locationId = window.localStorage.getItem("locationId");
    if (locationId) {
      headers.set("Request-Location-ID", `${locationId}`);
    }

    return headers;
  }
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: [
    "Account",
    "Cashbook",
    "Voucher Range Report",
    "Ledger Book",
    "Commission",
    "Commission Rate",
    "Doctor Designation",
    "Investigation"
  ],
  endpoints: (builder) => ({})
});
