import React, { useEffect, useState } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  Stack,
  Heading,
  SimpleGrid,
  Divider,
  Button,
  GridItem,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  IconButton,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Decimal } from "decimal.js";
import { useCreateExpenseVoucherMutation, useGetExpensesQuery } from "../api";
import { useTypedDispatch } from "hooks/use-typed-dispatch.hook";

import { Select } from "chakra-react-select";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { Expense } from "app/api/type";
import {
  addVoucherRecord,
  deleteVoucherRecord,
  resetCounterState
} from "../state/expense-voucher.slice";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";

interface Props {}

export const CreateBankExpenseVoucherComponent: React.FC<Props> = (
  props: Props
) => {
  Decimal.set({ precision: 20, rounding: 8 });

  const dispatch = useTypedDispatch();

  const toast = useToast();

  const navigate = useNavigate();

  const [createVoucher, createVoucherResult] =
    useCreateExpenseVoucherMutation();

  const getExpensesResult = useGetExpensesQuery({ sourceAccountName: "Bank" });

  const voucherEntries = useTypedSelector(
    (state) => state.expenseVoucher.voucherEntries
  );

  useEffect(() => {
    dispatch(resetCounterState());
  }, []);

  const [note, setNote] = useState<string>();
  const [details, setDetails] = useState<string>();
  const [expenseDate, setExpenseDate] = useState<Date>(new Date());

  const [selectedExpense, setSelectedExpense] = useState<{
    label: string;
    value: Expense;
  }>();

  const [expenses, setExpenses] = useState<
    {
      label: string;
      value: Expense;
    }[]
  >([]);

  useEffect(() => {
    if (getExpensesResult.isSuccess) {
      setExpenses(
        getExpensesResult.data.expenses.map((expense) => {
          return {
            label: expense.name,
            value: expense
          };
        })
      );
    }
  }, [getExpensesResult]);

  const [taxPercentage, setTaxPercentage] = useState(new Decimal(0));
  const [totalCost, setTotalCost] = useState(new Decimal(0));

  useEffect(() => {
    if (selectedExpense) {
      setTotalCost(new Decimal(0));
    }
  }, [selectedExpense]);

  const handleAddExpense = () => {
    if (
      selectedExpense &&
      totalCost.gt(0) && // cost should be greater than 0
      taxPercentage.gte(0) && // taxPercentage should be greater than 0
      taxPercentage.lte(100) // taxPercentage should be less than 100
    ) {
      const basePrice = getBasePriceFromTotal(totalCost, taxPercentage);
      const totalPrice = totalCost;
      dispatch(
        addVoucherRecord({
          voucherRecord: {
            details: details || null,
            quantity: 1,
            basePrice: basePrice.toNumber(),
            expenseId: selectedExpense.value.id,
            taxPercentage: taxPercentage.toNumber(),
            totalPrice: totalPrice.toNumber()
          },
          expense: selectedExpense.value
        })
      );

      setSelectedExpense(undefined);
      setTotalCost(new Decimal(0));
      setTaxPercentage(new Decimal(0));
      setDetails("");
    }
  };

  const handleCreateVoucher = () => {
    createVoucher({
      data: {
        voucher: {
          createdAt: expenseDate,
          wardTypeName: null,
          voucherTypeName: "Bank Expense",
          note: note || null,
          paidAmount: voucherEntries.reduce(
            (totalPrice, voucherEntry) =>
              totalPrice + voucherEntry.voucherRecord.totalPrice,
            0
          ),
          wardNumber: null,
          bedNumber: null,
          dueAmount: 0,
          sourceAccountName: "Bank",
          destinationAccountName: "Expense"
        },
        voucherRecords: voucherEntries.map((entry) => {
          return {
            ...entry.voucherRecord,
            expenseId: entry.expense.id,
            discountPercentage: 0,
            investigationId: null
          };
        })
      }
    });
  };

  useEffect(() => {
    if (createVoucherResult.isSuccess) {
      toast({
        title: "Success",
        description: "Voucher has been created",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });

      dispatch(resetCounterState());

      const locationCategory = window.localStorage.getItem(
        "locationCategory"
      ) as string;

      navigate(
        `/${locationCategory.toLowerCase()}/voucher?voucherId=${
          createVoucherResult.data.voucher.id
        }`
      );
    }
  }, [createVoucherResult]);

  const getBasePriceFromTotal = (
    totalPrice: Decimal,
    taxPercentage: Decimal
  ) => {
    // This calculation does not make sense but this is what they wanted
    // Base Price = Total Price - (Total Price * Tax Percentage)
    return totalPrice
      .mul(new Decimal(1).minus(taxPercentage.div(100)))
      .toNearest(0.01);
  };

  const getVatFromTotal = (totalPrice: Decimal, taxPercentage: Decimal) => {
    // Tax = Total Price - Base Price
    return totalPrice
      .minus(getBasePriceFromTotal(totalPrice, taxPercentage))
      .toNearest(0.01);
  };

  return (
    <>
      <Stack spacing="4">
        <PageCardComponent>
          <Stack spacing="4">
            <Heading fontSize="md">Expense Date</Heading>
            <FormControl width="100%" isRequired>
              <DatePicker
                selected={expenseDate}
                onChange={(date) => date && setExpenseDate(date)}
                dateFormat="dd-MM-yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={new Date()}
              />
            </FormControl>
            <Divider />
            <Heading fontSize="md">Bank Expenses</Heading>
            <SimpleGrid
              columns={{ base: 2, xl: 6 }}
              rowGap={4}
              columnGap={1}
              alignItems="end"
            >
              <GridItem colSpan={2}>
                <FormControl width="100%" isRequired>
                  <FormLabel>Expense Type</FormLabel>
                  <Select
                    selectedOptionStyle="check"
                    options={expenses}
                    value={selectedExpense}
                    onChange={(event) => {
                      if (event) {
                        setSelectedExpense({
                          label: event.label,
                          value: event.value
                        });
                      }
                    }}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={2}>
                <FormControl width="100%" isRequired>
                  <FormLabel>Total Cost</FormLabel>
                  <Input
                    value={totalCost.toNumber()}
                    type="number"
                    onChange={(event) => {
                      setTotalCost(new Decimal(event.target.valueAsNumber));
                    }}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={2}>
                <Button
                  width="100%"
                  leftIcon={<FaPlus />}
                  colorScheme="blue"
                  isDisabled={
                    !selectedExpense ||
                    totalCost.eq(0) ||
                    totalCost.eq(NaN) ||
                    taxPercentage.eq(NaN)
                  }
                  onClick={handleAddExpense}
                >
                  Add
                </Button>
              </GridItem>
            </SimpleGrid>
          </Stack>
        </PageCardComponent>

        <PageCardComponent>
          <Stack spacing="4">
            <Heading fontSize="md">Bank Expense Voucher</Heading>

            <TableContainer>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Sl.</Th>
                    <Th>Expense Type</Th>
                    <Th>Sub-Total</Th>
                    <Th></Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {voucherEntries.map((voucherEntry, index) => {
                    return (
                      <Tr>
                        <Td>{index + 1}</Td>{" "}
                        <Td>{voucherEntry.expense.name}</Td>
                        <Td>৳ {voucherEntry.voucherRecord.totalPrice}</Td>
                        <Td>
                          <IconButton
                            aria-label="Delete this entry"
                            icon={<FaTimes />}
                            colorScheme="red"
                            onClick={() => {
                              dispatch(
                                deleteVoucherRecord({
                                  index
                                })
                              );
                            }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}

                  <Tr bgColor="gray.600" color="white" fontWeight={"bold"}>
                    {" "}
                    <Td></Td>
                    <Td>Grand Total</Td>
                    <Td>
                      ৳{" "}
                      {voucherEntries
                        .reduce(
                          (totalPrice, voucherEntry) =>
                            totalPrice.plus(
                              voucherEntry.voucherRecord.totalPrice
                            ),
                          new Decimal(0)
                        )
                        .toNumber()}
                    </Td>
                    <Td></Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            <FormControl id="note">
              <FormLabel>Note</FormLabel>
              <Textarea
                value={note}
                onChange={(event) => setNote(event.target.value)}
                placeholder="Additional information"
              />
            </FormControl>

            <Button
              onClick={handleCreateVoucher}
              colorScheme={"blue"}
              isDisabled={voucherEntries.length === 0}
              isLoading={createVoucherResult.isLoading}
            >
              Create Expense Voucher
            </Button>
          </Stack>
        </PageCardComponent>
      </Stack>
    </>
  );
};
