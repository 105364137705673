import React from "react";
import dayjs from "dayjs";
import { env } from "app/config";
import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image
} from "@react-pdf/renderer";
import { GetMonthlyIncomeReportResponse } from "modules/counter/api/type";

interface PrintMonthlyIncomeReportPageProps {
  monthlyIncomeReport: GetMonthlyIncomeReportResponse;
  month: string;
  year: string;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24,
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    textAlign: "center"
  },
  title: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 4,
    textAlign: "center"
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 4,
    textAlign: "center"
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  headingText: {
    fontSize: 10
  },
  table: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#dfdfdf",
    border: "0.5px solid #dfdfdf",
    fontWeight: "bold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 9,
    border: "0.5px solid #dfdfdf"
  },
  dateTitle: {
    width: "10%",
    padding: "1px"
  },
  dateValue: {
    width: "90%",
    padding: "1px"
  },
  expandRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 10,
    marginBottom: 5,
    borderBottom: "1px solid #dfdfdf"
  },
  shiftStart: {
    width: "15%"
  },
  shiftEnd: {
    width: "15%"
  },
  voucherStart: {
    width: "20%"
  },
  voucherEnd: {
    width: "20%"
  },
  quantity: {
    width: "15%"
  },
  income: {
    width: "15%"
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    fontSize: 9,
    fontWeight: "bold",
    textAlign: "center"
  }
});

export const PrintMonthlyIncomeReportPage: React.FC<
  PrintMonthlyIncomeReportPageProps
> = (props: PrintMonthlyIncomeReportPageProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <Image style={styles.logo} src={env.governmentLogo} />
              <View style={{ paddingHorizontal: "25px" }}>
                <View>
                  <Text style={{ fontSize: 11, alignSelf: "center" }}>
                    {env.voucherTitle}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 9, alignSelf: "center" }}>
                    {env.hospitalName}
                  </Text>
                </View>
              </View>
              <Image style={styles.logo} src={env.hospitalLogo} />
            </View>
            <View
              style={{
                borderTop: "1px solid #000",
                paddingTop: "8px",
                marginTop: "8px"
              }}
            >
              <Text style={styles.title}>Monthly Income Report</Text>
              <Text style={styles.subTitle}>
                {props.month} {props.year}
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              ></View>
              <View
                style={{
                  marginTop: "8px",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #000"
                }}
              >
                <View style={styles.heading}>
                  <View style={{ width: "50%" }}>
                    <Text style={styles.headingText}>
                      Starting payment voucher ID :{" "}
                      {
                        props.monthlyIncomeReport.report.monthlyData
                          .voucherStart
                      }
                    </Text>
                    <Text style={styles.headingText}>
                      Voucher Quantity :{" "}
                      {
                        props.monthlyIncomeReport.report.monthlyData
                          .voucherQuantity
                      }
                    </Text>
                  </View>
                  <View style={{ width: "50%" }}>
                    <Text style={styles.headingText}>
                      Ending payment voucher ID :{" "}
                      {props.monthlyIncomeReport.report.monthlyData.voucherEnd}
                    </Text>

                    <Text style={styles.headingText}>
                      Total Monthly Income : ৳{" "}
                      {props.monthlyIncomeReport.report.monthlyData.income}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.table}>
              {props.monthlyIncomeReport.report.dailyData.map(
                (operationDetails, i) => (
                  <>
                    <View style={[styles.row, styles.tableHeader]}>
                      <Text style={styles.dateTitle}>Date</Text>
                      <Text style={styles.dateValue}>
                        {dayjs(operationDetails.date).format("DD-MMM-YYYY")}
                      </Text>
                    </View>
                    <View>
                      <View style={styles.expandRow}>
                        <Text style={styles.shiftStart}>Shift Start</Text>
                        <Text style={styles.shiftEnd}>Shift End</Text>
                        <Text style={styles.voucherStart}>Voucher Start</Text>
                        <Text style={styles.voucherEnd}>Voucher End</Text>
                        <Text style={styles.quantity}>Total Quantity</Text>
                        <Text style={styles.income}>Total Amount</Text>
                      </View>
                      {operationDetails.shifts.map((operation, id) => (
                        <View style={styles.expandRow} key={id} wrap={false}>
                          <Text style={styles.shiftStart}>
                            {dayjs(operation.shiftStart).format("h:mm A")}
                          </Text>
                          <Text style={styles.shiftEnd}>
                            {dayjs(operation.shiftEnd).format("h:mm A")}
                          </Text>
                          <Text style={styles.voucherStart}>
                            {operation.voucherStart}
                          </Text>
                          <Text style={styles.voucherEnd}>
                            {operation.voucherEnd}
                          </Text>
                          <Text style={styles.quantity}>
                            {operation.voucherQuantity}
                          </Text>
                          <Text style={styles.income}>
                            ৳ {operation.income}
                          </Text>
                        </View>
                      ))}
                    </View>
                  </>
                )
              )}
            </View>
          </View>
        </View>

        <View fixed>
          <View
            style={{
              borderTop: "1px solid #000"
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <Text style={{ fontSize: 8, color: "#666666" }}>
                Powered By: BinduLogic Limited
              </Text>
              <Text
                style={{ fontSize: 8, color: "#666666" }}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
                fixed
              />
              <Text style={{ fontSize: 8, color: "#666666" }}>
                Printed By:{" "}
                {window.localStorage.getItem("locationName") || "Unknown"},{" "}
                {dayjs().format("DD MMM YYYY, hh:mm A")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
