import React from "react";
import {
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {
  Expense,
  Investigation,
  Patient,
  Person,
  Voucher,
  VoucherRecord
} from "app/api/type";
import { PageCardComponent } from "modules/core/components/page-card.component";

interface BankExpenseVoucherComponentProps {
  voucherDetails: {
    patient: Patient | null;
    person: Person | null;
    voucher: Voucher;
    entries: {
      voucherRecord: VoucherRecord;
      investigation: Investigation | null;
      expense: Expense | null;
    }[];
  };
}

export const BankExpenseVoucherComponent: React.FC<
  BankExpenseVoucherComponentProps
> = (props: BankExpenseVoucherComponentProps) => {
  return (
    <Stack>
      <Stack spacing="6">
        <PageCardComponent>
          <TableContainer>
            <Table variant="simple" size="sm">
              <TableCaption placement="top">
                Transaction {props.voucherDetails.voucher.id}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Sl.</Th>
                  <Th>Expense Type</Th>
                  <Th isNumeric>Total</Th>
                </Tr>
              </Thead>

              <Tbody>
                {props.voucherDetails.entries.map((voucherEntry, index) => {
                  return (
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td>
                        {voucherEntry.expense
                          ? voucherEntry.expense.name
                          : "Unspecified"}
                      </Td>
                      <Td isNumeric>
                        ৳ {voucherEntry.voucherRecord.totalPrice}
                      </Td>
                    </Tr>
                  );
                })}
                <Tr bgColor="gray.600" color="white" fontWeight={"bold"}>
                  {" "}
                  <Td></Td>
                  <Td>Grand Total </Td>
                  <Td isNumeric>
                    = ৳{" "}
                    {props.voucherDetails.entries.reduce(
                      (totalPrice, voucherEntry) =>
                        totalPrice + voucherEntry.voucherRecord.totalPrice,
                      0
                    )}
                  </Td>
                </Tr>
                <Tr bgColor="gray.100" fontWeight={"bold"}>
                  {" "}
                  <Td></Td>
                  <Td>Paid </Td>
                  <Td isNumeric>
                    = ৳ {props.voucherDetails.voucher.paidAmount}
                  </Td>
                </Tr>{" "}
              </Tbody>
            </Table>
          </TableContainer>
        </PageCardComponent>
      </Stack>
    </Stack>
  );
};
