import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  SimpleGrid,
  Stack,
  useToast
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { InvestigationType } from "app/api/type";
import { PageCardComponent } from "modules/core/components/page-card.component";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  useGetInvestigationDetailsQuery,
  useGetInvestigationTypesQuery,
  useUpdateInvestigationMutation
} from "../api";
import { InvestigationWithDetails } from "../api/type";
import { InvestigationPriceEditingCompnent } from "./investigation-price-editor.component";

interface Props {
  investigationId: string;
}

interface InvestigationTypeOption {
  label: string;
  value: InvestigationType;
}

export const EditInvestigationsComponent: React.FC<Props> = (props: Props) => {
  const toast = useToast();
  const getInvestigationDataResult = useGetInvestigationDetailsQuery({
    investigationId: props.investigationId
  });
  const [investigationData, setInvestigationData] =
    useState<InvestigationWithDetails>();

  useEffect(() => {
    if (getInvestigationDataResult.isSuccess) {
      setInvestigationData(
        getInvestigationDataResult.data.investigationWithDetails
      );
    }
  }, [getInvestigationDataResult]);

  const handleChangeFormData = (event: ChangeEvent<HTMLInputElement>) => {
    if (investigationData && event) {
      setInvestigationData({
        ...investigationData,
        [event.target.name]: event.target.value
      });
    }
  };

  const getInvestigationTypesResult = useGetInvestigationTypesQuery({});

  const [investigationTypes, setInvestigationTypes] =
    useState<InvestigationTypeOption[]>();

  useEffect(() => {
    if (getInvestigationTypesResult.isSuccess) {
      setInvestigationTypes(
        getInvestigationTypesResult.data.investigationTypes.map(
          (investigationType) => {
            return {
              label: investigationType.name,
              value: investigationType
            };
          }
        )
      );
    }
  }, [getInvestigationTypesResult]);

  const [updateData, updateDataResult] = useUpdateInvestigationMutation();

  const handleSave = async () => {
    if (investigationData) {
      if (
        investigationData.equipmentCost < 0 ||
        investigationData.resultPublishTimeInHours < 1 ||
        !investigationData.name
      ) {
        return toast({
          title: "Error",
          description: "Please check if the information provided is valid",
          position: "top",
          status: "error",
          duration: 4000
        });
      }
      await updateData({
        body: {
          investigationName: investigationData.name,
          investigationType: investigationData.investigationTypeName,
          equipmentCost: Number(investigationData.equipmentCost),
          resultPublishTimeInHours: Number(
            investigationData.resultPublishTimeInHours
          )
        },
        params: {
          investigationId: investigationData.id
        }
      });
    }
  };

  useEffect(() => {
    if (updateDataResult.isSuccess) {
      toast({
        title: "Update Successful",
        description: "The Investigation data has been updated",
        position: "top",
        status: "success",
        duration: 4000
      });
    } else if (updateDataResult.isError) {
      toast({
        title: "Error",
        description: "The server could not update the investigation",
        position: "top",
        status: "error",
        duration: 4000
      });
    }
  }, [updateDataResult, toast]);

  return (
    <Stack>
      <PageCardComponent>
        <Stack spacing={4}>
          <SimpleGrid
            columns={{ base: 10 }}
            rowGap={4}
            columnGap={4}
            alignItems="center"
          >
            <GridItem colSpan={10}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  type="text"
                  size="md"
                  value={investigationData?.name}
                  onChange={handleChangeFormData}
                />
                <FormErrorMessage>Enter a valid name</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel>Investigation Type</FormLabel>
                <Select
                  size="md"
                  options={investigationTypes}
                  value={{
                    label: investigationData?.investigationType.name,
                    value: investigationData?.investigationType
                  }}
                  onChange={(event) => {
                    if (investigationData && event?.value) {
                      setInvestigationData({
                        ...investigationData,
                        investigationTypeName: event.value.name,
                        investigationType: event.value
                      });
                    }
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel>Equipment Cost</FormLabel>
                <InputGroup size="md">
                  <InputLeftAddon children="BDT" />
                  <Input
                    name="equipmentCost"
                    type="number"
                    value={investigationData?.equipmentCost}
                    onChange={handleChangeFormData}
                  />
                </InputGroup>
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel>Result Publishing Time</FormLabel>
                <InputGroup size="md">
                  <Input
                    name="resultPublishTimeInHours"
                    type="number"
                    value={investigationData?.resultPublishTimeInHours}
                    onChange={handleChangeFormData}
                  />
                  <InputRightAddon children="Hours" />
                </InputGroup>
                <FormErrorMessage>
                  Enter a valid Result Publishing Time
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </SimpleGrid>
          <Button
            mt={4}
            isDisabled={
              investigationData ===
              getInvestigationDataResult.data?.investigationWithDetails
            }
            isLoading={updateDataResult.isLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </PageCardComponent>
      <PageCardComponent>
        <Stack padding={2}>
          <FormLabel>Prices</FormLabel>
          {investigationData?.investigationPrices.map((investigationPrice) => (
            <InvestigationPriceEditingCompnent
              key={`${investigationPrice.wardTypeName} ${investigationPrice.price}`}
              investigationPrice={investigationPrice}
            />
          ))}
        </Stack>
      </PageCardComponent>
    </Stack>
  );
};
