import React from "react";
import { Stack } from "@chakra-ui/react";
import { CreatePaymentVoucherComponent } from "../components/create-payment-voucher.component";

interface CreatePaymentVoucherPageProps {}

export const CreatePaymentVoucherPage: React.FC<
  CreatePaymentVoucherPageProps
> = (props: CreatePaymentVoucherPageProps) => {
  return (
    <Stack>
      <CreatePaymentVoucherComponent />
    </Stack>
  );
};
