import React from "react";
import { Stack } from "@chakra-ui/react";
import { CreateExpenseVoucherComponent } from "../components/create-expense-voucher.component";

interface CreateExpenseVoucherPageProps {}

export const CreateExpenseVoucherPage: React.FC<
  CreateExpenseVoucherPageProps
> = (props: CreateExpenseVoucherPageProps) => {
  return (
    <Stack>
      <CreateExpenseVoucherComponent />
    </Stack>
  );
};
