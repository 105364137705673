import { Center, Spinner, Stack, Text } from "@chakra-ui/react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Expense,
  Investigation,
  Patient,
  Person,
  Voucher,
  VoucherRecord
} from "app/api/type";
import React, { useEffect, useState } from "react";
import { useGetUserQuery, useGetVoucherDetailsQuery } from "../api";
import { BankExpenseVoucherComponent } from "./bank-expense-voucher.component";
import { ExpenseVoucherComponent } from "./expense-voucher.component";
import { PaymentVoucherComponent } from "./payment-voucher.component";
import { PrintVoucherComponent } from "./print-voucher.component";
import { TransferVoucherComponent } from "./transfer-voucher.component";

interface VoucherComponentProps {
  voucherId: string;
}

export const VoucherComponent: React.FC<VoucherComponentProps> = (
  props: VoucherComponentProps
) => {
  const getUserResult = useGetUserQuery({});
  const getVoucherDetailsResult = useGetVoucherDetailsQuery({
    voucherId: props.voucherId
  });

  const [voucherDetails, setVoucherDetails] = useState<{
    patient: Patient | null;
    person: Person | null;
    voucher: Voucher;
    entries: {
      voucherRecord: VoucherRecord;
      investigation: Investigation | null;
      expense: Expense | null;
    }[];
  }>();

  useEffect(() => {
    if (getVoucherDetailsResult.isSuccess) {
      setVoucherDetails(getVoucherDetailsResult.data.voucherWithDetails);
    }
  }, [getVoucherDetailsResult]);

  return (
    <Stack>
      {getVoucherDetailsResult.isLoading ? (
        <Center width="100%" height="10vh">
          <Spinner />
        </Center>
      ) : voucherDetails ? (
        <Stack spacing="6">
          {voucherDetails.voucher.voucherTypeName === "Payment Voucher" ? (
            <PaymentVoucherComponent voucherDetails={voucherDetails} />
          ) : voucherDetails.voucher.voucherTypeName === "Coupon Expense" ? (
            <ExpenseVoucherComponent voucherDetails={voucherDetails} />
          ) : voucherDetails.voucher.voucherTypeName === "Bank Expense" ? (
            <BankExpenseVoucherComponent voucherDetails={voucherDetails} />
          ) : (
            <TransferVoucherComponent voucherDetails={voucherDetails} />
          )}

          <Stack direction="row">
            {/* <CollectPaymentModalComponent
              voucherId={props.voucherId}
              dueAmount={voucherDetails.voucher.dueAmount}
            /> */}
          </Stack>
          {getUserResult.data ? (
            <PDFViewer width={"100%"} height="500px">
              <PrintVoucherComponent
                voucherDetails={voucherDetails}
                printerDetails={getUserResult.data.userWithDetails}
              />
            </PDFViewer>
          ) : (
            <Spinner />
          )}
        </Stack>
      ) : (
        <Center width="100%" height="10vh">
          <Text>No Voucher Found</Text>
        </Center>
      )}
    </Stack>
  );
};
