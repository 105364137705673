import React from "react";
import { GetVoucherListResponse } from "modules/counter/api/type";
import { PrintExpenseHistoryPage } from "../pages/print-expense-history.page";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";
import { Button, useDisclosure } from "@chakra-ui/react";

interface PrintExpenseHistoryComponentProps {
  expenseHistory: GetVoucherListResponse;
  month: string;
  year: string;
}

export const PrintExpenseHistoryComponent: React.FC<
  PrintExpenseHistoryComponentProps
> = (props: PrintExpenseHistoryComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = (
    <PrintExpenseHistoryPage
      expenseHistory={props.expenseHistory}
      month={props.month}
      year={props.year}
    />
  );

  const fileName = `Expense-History-Report-${props.month}-${props.year}.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={fileName}
      />
    </>
  );
};
